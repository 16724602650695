import { useContext, useEffect } from "react";
import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";

import { debounce } from "lodash";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ENDPOINT_PATH from "constants/end-point-path";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";

import closeIcon from "assets/images/close-icon.png";
import searchIcon from "assets/images/search-icon.png";

export const AppAddServicesModal = (props, ref) => {
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [data, setData] = useState(convertPaginationTableData());
	const [visible, setVisible] = useState(false);
	const [selectedServices, setSelectedServices] = useState([]);
	const queryParams = useRef({ page: 0, value: "", sort: "" });
	const onHandleAddServices = useMemo(() => props.onHandleAddServices, [props.onHandleAddServices]);

	const onHandleSubmit = useCallback(() => {
		onHandleAddServices(selectedServices);

		setVisible(false);
	}, [onHandleAddServices, selectedServices]);

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setSelectedServices([]);

		setVisible(false);
	}, []);

	//prettier-ignore
	const onHandleSelectService = useCallback((boolean, obj) => {
		let nextId = [...selectedServices];

		const index = nextId.findIndex((o) => o.id === obj.id);

		if (!boolean && index > -1) {
			nextId = nextId.filter((o) => o.id !== obj.id);
		} else {
			nextId.push(obj);
		}

		setSelectedServices(nextId);
	}, [selectedServices]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			response = await api.get.serviceListing.serviceListings(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.value = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				filter: false,
				sort: false,
				sortThirdClickReset: true
			}
		},
		{
			name: "referenceNo",
			label: "Service ID",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "serviceAction",
			label: "Service Action",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "serviceType",
			label: "Service Type",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "serviceInput",
			label: "Input",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "add",
			label: "Add",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const service = data.content[rowIndex];
					const selected = selectedServices.findIndex((o) => o.id === service.id) > -1;

					return <AppCheckbox label="" onClick={(v) => onHandleSelectService(v, service)} value={selected} />;
				}
			}
		}
	], [data.content, selectedServices, onHandleSelectService]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.SERVICE_LISTING.SERVICE_LISTINGS);
		};
	}, [cancelRequest]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-add-services-modal" }} open={visible}>
			<div className="add-services-modal">
				<button type="button" className="add-services-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="add-services-modal__title">Add Service</h1>

				<div className="add-services-modal__container">
					<p className="add-services-modal__description">
						Select from <span>In-Use</span> Services from General Configuration Module
					</p>

					<div className="add-services-modal__search-input">
						<AppInput type="text" name="search" placeholder="Search by Service ID or Service Name" endIcon={searchIcon} iconPosition="end" defaultValue={queryParams.current.value} onChange={onHandleDebounceSearch} />
					</div>

					<AppTable data={data.content} columns={tableColumns} />

					<div className="add-services-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="button" label="Add" onClick={onHandleSubmit} />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppAddServicesModal));

AppAddServicesModal.propTypes = {
	ref: PropTypes.object
};
