import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import TRANSFER_TYPE from "constants/transfer-type";
import ENDPOINT_PATH from "constants/end-point-path";

import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppInventoryHistoryViewModal from "components/pages/inventory/app-inventory-history-view-modal";
import AppInventoryHistoryConfirmationModal from "components/pages/inventory/app-inventory-history-confirmation-modal";

import eyeOpenIcon from "assets/images/eye-open-icon.png";

const AppInventoryHistoryRequestsTable = () => {
	const dispatch = useDispatch();
	const inventoryRef = useRef();
	const confirmRef = useRef();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [data, setData] = useState(convertPaginationTableData());
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const defaultStatus = useMemo(() => [STATUS.PENDING, STATUS.ISSUED, STATUS.APPROVED, STATUS.RECEIVED, STATUS.REJECTED].join(","), []);
	const status = useMemo(() => {
		const currentStatuses = searchParams.get("status")?.split(",");
		const relevantStatus = currentStatuses?.every((e) => defaultStatus.split(",").includes(e));

		if (!relevantStatus) {
			return defaultStatus;
		} else {
			return searchParams.get("status");
		}
	}, [defaultStatus, searchParams]);
	const defaultTransferType = useMemo(() => [TRANSFER_TYPE.REQUEST, TRANSFER_TYPE.NEW_PURCHASE].join(","), []);
	const transferType = useMemo(() => {
		const currentTransferType = searchParams.get("transfer-type")?.split(",");
		const relevantTransferType = currentTransferType?.every((e) => defaultTransferType.split(",").includes(e));

		if (!relevantTransferType) {
			return defaultTransferType;
		} else {
			return searchParams.get("transfer-type");
		}
	}, [defaultTransferType, searchParams]);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", status: status, transactionTypes: transferType });

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.inventory.inventoryRequests(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	// prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");
		queryParams.current.transactionTypes = values.transferType.join(",");

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const formik = useFormik({
		initialValues: {
			status: [STATUS.PENDING, STATUS.ISSUED, STATUS.APPROVED, STATUS.RECEIVED, STATUS.REJECTED],
			transferType: [TRANSFER_TYPE.NEW_PURCHASE, TRANSFER_TYPE.REQUEST]
		},
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
		formik.setFieldValue("transferType", queryParams.current.transactionTypes.split(","));
	}, [formik]);

	//prettier-ignore
	const onHandleView = useCallback((tableMeta) => {
		inventoryRef.current.onHandleShow(data.content[tableMeta.rowIndex]);
	}, [data]);

	const onHandleApproveRejectIssue = useCallback((data, status) => {
		confirmRef.current.onHandleShow(data, status);
	}, []);

	const onHandleDismiss = useCallback((data) => {
		inventoryRef.current.onHandleShow(data);
	}, []);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Pending", name: STATUS.PENDING },
			{ label: "Issued", name: STATUS.ISSUED },
			{ label: "Approved", name: STATUS.APPROVED },
			{ label: "Received", name: STATUS.RECEIVED },
			{ label: "Rejected", name: STATUS.REJECTED }
		];

		return data;
	}, []);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let status = [...formik.values.status];

		if (status.length < 2 && !value) return;

		if (!value) {
			status = status.filter(o => o !== name);
		} else {
			status.push(name);
		}

		formik.setFieldValue("status", status);
	}, [formik]);

	const menuFilterTransferType = useMemo(() => {
		const data = [
			{ label: "Request", name: TRANSFER_TYPE.REQUEST },
			{ label: "New Purchase", name: TRANSFER_TYPE.NEW_PURCHASE }
		];

		return data;
	}, []);

	const onHandleFormatFiles = useCallback((fileList) => {
		const formData = new FormData();

		fileList.forEach((o) => {
			formData.append("files", o);
		});

		return formData;
	}, []);

	//prettier-ignore
	const onHandleUploadFiles = useCallback(async (requestId, file) => {
		let response = null;
		let filesToUpload = [file];

		if (!filesToUpload.length) return;

		try {
			const payload = {
				queryParam: { "request-id": requestId }
			};

			payload.files = onHandleFormatFiles(filesToUpload);

			await api.post.inventory.uploadDoc(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Documents were uploaded successfully!" }));

			onHandleGetList();
		}
	}, [dispatch, onHandleFormatFiles, onHandleGetList]);

	//prettier-ignore
	const onHandleSelectTransferType = useCallback((value, name) => {
		let transferType = [...formik.values.transferType];

		if (transferType.length < 2 && !value) return;

		if (!value) {
			transferType = transferType.filter(o => o !== name);
		} else {
			transferType.push(name);
		}

		formik.setFieldValue("transferType", transferType);
	}, [formik]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.status = defaultStatus;
		queryParams.current.transactionTypes = defaultTransferType;

		onHandleGetList();
	}, [defaultStatus, defaultTransferType, onHandleGetList, formik]);

	//prettier-ignore
	const onHandleApprove = useCallback(async (values) => {
		let response = null;

		try {
			const payload = { id: values.id, inventoryLocationId: values.from, transactionType: values.transactionType, status: STATUS.APPROVED };

			await api.post.inventory.updateRequest(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Item Request approved successfully" }));

			onHandleGetList();
		}
	}, [dispatch, onHandleGetList]);

	//prettier-ignore
	const onHandleReject = useCallback(async (values) => {
		let response = null;

		try {
			const payload = { id: values.id, inventoryLocationId: values.from, transactionType: values.transactionType, status: STATUS.REJECTED };

			await api.post.inventory.updateRequest(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Item Request rejected successfully" }));

			onHandleGetList();
		}
	}, [dispatch, onHandleGetList]);

	//prettier-ignore
	const onHandleIssue = useCallback(async (values, file) => {
		let response = null;

		try {
			const payload = { id: values.id, inventoryLocationId: values.from, transactionType: values.transactionType, status: STATUS.ISSUED };

			await api.post.inventory.updateRequest(payload);

			onHandleUploadFiles(values.id, file);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Item Request issued successfully" }));

			onHandleGetList();
		}
	}, [dispatch, onHandleGetList, onHandleUploadFiles]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "inventoryHistoryRequestSignature.submittedBy",
			label: "Requested By",
			options: {
				sort: false
			}
		},
		{
			name: "inventoryItem",
			label: "Inventory Item",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "createdDate",
			label: "Date & Time",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY_HH_MM_A)
			}
		},
		{
			name: "transactionType",
			label: "Transaction Type",
			options: {
				sort: false
			}
		},
		{
			name: "inventoryLocationName",
			label: "From",
			options: {
				sort: false
			}
		},
		{
			name: "workOrderId",
			label: "Work Order Id",
			options: {
				sort: false
			}
		},
		{
			name: "quantity",
			label: "Request Quantity",
			options: {
				sort: false
			}
		},
		{
			name: "available",
			label: "Available Quantity",
			options: {
				sort: false
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__view" onClick={() => onHandleView(tableMeta)}>
							<img src={eyeOpenIcon} alt="view-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleView]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess:".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		},
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.INVENTORY.INVENTORY_REQUESTS);
		};
	}, [cancelRequest]);

	return (
		<div className="app-inventory-history-requests-table">
			<div className="inventory-history-requests-table">
				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Requested By or Inventory Item" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="inventory-history-requests-table-filter">
						<div className="inventory-history-requests-table-filter__container">
							<p className="inventory-history-requests-table-filter__label">Transfer Type</p>

							<div className="inventory-history-requests-table-filter__checkbox-container">
								{menuFilterTransferType.map((o) => {
									const isActiveType = formik.values.transferType.findIndex((i) => i === o.name) > -1;

									return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectTransferType(v, o.name)} label={o.label} value={isActiveType} />;
								})}
							</div>
						</div>

						<div className="inventory-history-requests-table-filter__container">
							<p className="inventory-history-requests-table-filter__label">Status</p>

							<div className="inventory-history-requests-table-filter__checkbox-container">
								{menuFilterStatus.map((o) => {
									const isActiveStatus = formik.values.status.findIndex((i) => i === o.name) > -1;

									return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActiveStatus} />;
								})}
							</div>
						</div>
					</div>
				</AppTableFilterHeader>

				<div className="inventory-history-requests-table__body">
					<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
				</div>
			</div>

			<AppInventoryHistoryViewModal ref={inventoryRef} onHandleApproveRejectIssue={onHandleApproveRejectIssue} />

			<AppInventoryHistoryConfirmationModal ref={confirmRef} onHandleDismiss={onHandleDismiss} onHandleReject={onHandleReject} onHandleApprove={onHandleApprove} onHandleIssue={onHandleIssue} />
		</div>
	);
};

export default AppInventoryHistoryRequestsTable;
