import React, { useCallback, useMemo, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";

import api from "services/api";

import useBeforeUnload from "hooks/use-before-unload";

import serveRequestErrors from "common/serve-request-errors";

import PAGE from "constants/page";
import STATUS from "constants/status";
import SIGNATURE_TYPE from "constants/signature-type";

import AppTabs from "components/app-tabs";
import AppButton from "components/app-button";
import AppWorkOrder from "components/pages/work-inspection-listing/app-work-order";
import AppReportDetails from "components/pages/work-inspection-listing/app-report-details";
import AppFindingDetails from "components/pages/work-inspection-listing/app-finding-details";
import AppConfirmChangeStatusModal from "components/pages/work-inspection-listing/app-confirm-change-status-modal";

import exportIcon from "assets/images/export-icon.png";
import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";
import workOrderIcon from "assets/images/pages/work-inpsection-report/work-order-icon.svg";
import reportDetailsIcon from "assets/images/pages/work-inpsection-report/report-details-icon.svg";
import findingDetailsIcon from "assets/images/pages/work-inpsection-report/finding-details-icon.svg";
import workOrderActiveIcon from "assets/images/pages/work-inpsection-report/work-order-active-icon.svg";
import reportDetailsActiveIcon from "assets/images/pages/work-inpsection-report/report-details-active-icon.svg";
import findingDetailsActiveIcon from "assets/images/pages/work-inpsection-report/finding-details-active-icon.svg";

const TABS = {
	REPORT_DETAILS: "REPORT_DETAILS",
	FINDING_DETAILS: "FINDING_DETAILS",
	WORK_ORDERS: "WORK_ORDERS"
};

const PageWorkInspectionListingCreateEdit = () => {
	useBeforeUnload();
	const { id } = useParams();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const title = useMemo(() => (isCreate ? "Add Work Inspection Report" : "Edit Work Inspection Report"), [isCreate]);
	const [wirInfo, setWIRInfo] = useState({});
	const [menuAnchor, setMenuAnchor] = useState(null);
	const canReschedule = useMemo(() => wirInfo.status === STATUS.OVERDUE || wirInfo.status === STATUS.PENDING_RESCHEDULE, [wirInfo]);
	const canCancel = useMemo(() => [STATUS.OVERDUE].includes(wirInfo.status), [wirInfo]);
	const tabRef = useRef();
	const nextTab = useRef();
	const findingDetailsRef = useRef();
	const reportDetailsRef = useRef();
	const confirmChangeStatusModalRef = useRef();

	const onHandleLeaveFindingDetails = useCallback(() => {
		tabRef.current.onHandleSelectTab(nextTab.current);

		nextTab.current = null;
	}, []);

	const onHandleTabChange = useCallback((obj, currentTab) => {
		const isFindingDetailsTab = currentTab === TABS.FINDING_DETAILS;
		const onHandleCheckFormikDirty = findingDetailsRef.current?.onHandleCheckFormikDirty();

		nextTab.current = obj.name;

		if (isFindingDetailsTab && onHandleCheckFormikDirty) {
			findingDetailsRef.current?.onHandleLeavePageModal();

			return;
		}

		tabRef.current.onHandleSelectTab(nextTab.current);
	}, []);

	const onHandleSetSignature = useCallback(() => {
		confirmChangeStatusModalRef.current.onHandleShow();
	}, []);

	const onHandleSetRescheduled = () => {
		setMenuAnchor(null);

		wirInfo.signatureType = SIGNATURE_TYPE.RESCHEDULED_BY;

		confirmChangeStatusModalRef.current.onHandleShow(wirInfo);
	};

	const onHandleSetCancelled = () => {
		setMenuAnchor(false);

		wirInfo.signatureType = SIGNATURE_TYPE.CANCELLED_BY;

		confirmChangeStatusModalRef.current.onHandleShow(wirInfo);
	};

	const onHandleSetWIRInfo = useCallback((data) => {
		setWIRInfo(data);
	}, []);

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);
	}, []);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	const onHandleExportPDF = useCallback(async () => {
		let response = null;
		let fileName = "";

		try {
			const payload = { "work-inspection-id": id };

			const transformResponse = (data, headers) => {
				fileName = headers?.["content-disposition"]?.split("inline; filename=")?.[1]?.split(";")?.[0];

				if (fileName) return data;

				try {
					const jsonResponse = JSON.parse(new TextDecoder().decode(data));

					if (jsonResponse) return jsonResponse;
				} catch (error) {
					return data;
				}
			};

			response = await api.post.workInspectionReport.generateWorkInspectionReportPdf(payload, { transformResponse });
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const a = document.createElement("a");
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(new Blob([response]), { type: "application/octet-stream" });
			a.href = url;
			a.download = fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			}, 1000);
		}
	}, [id]);

	const tabs = useMemo(() => {
		let data = [{ label: "Report Details", name: "REPORT_DETAILS", icon: reportDetailsIcon, activeIcon: reportDetailsActiveIcon, component: <AppReportDetails onHandleSetWIRInfo={onHandleSetWIRInfo} ref={reportDetailsRef} />, accessible: true }];

		if (!isCreate) {
			data = [...data, { label: "Finding Details", name: "FINDING_DETAILS", icon: findingDetailsIcon, activeIcon: findingDetailsActiveIcon, component: <AppFindingDetails ref={findingDetailsRef} onHandleLeaveFindingDetails={onHandleLeaveFindingDetails} status={wirInfo?.status} />, accessible: true }];
		}

		if (wirInfo?.status === STATUS.COMPLETED) {
			data = [...data, { label: "Work Orders", name: "WORK_ORDERS", icon: workOrderIcon, activeIcon: workOrderActiveIcon, component: <AppWorkOrder status={wirInfo?.status} />, accessible: true }];
		}

		return data;
	}, [isCreate, onHandleLeaveFindingDetails, onHandleSetWIRInfo, wirInfo?.status]);

	return (
		<div className="page-work-inspection-listing-create-edit">
			<div className="work-inspection-listing-create-edit">
				<div className="work-inspection-listing-create-edit__container">
					<div className="work-inspection-listing-create-edit__header">
						<h1 className="work-inspection-listing-create-edit__title">{title}</h1>

						{!isCreate && (
							<div className="work-inspection-listing-create-edit__header work-inspection-listing-create-edit__header--column">
								<div className="work-inspection-listing-create-edit__header-buttons">
									{(canCancel || canReschedule) && <AppButton outline className="work-inspection-listing-create-edit__action-button" type="button" label="Actions" icon={chevronIcon} onClick={onToggleFilterMenu} />}

									<AppButton outline type="button" label="Export as PDF" icon={exportIcon} onClick={onHandleExportPDF} />
								</div>

								<p className="work-inspection-listing-create-edit__last-update">
									<span>Last Updated By</span> {wirInfo.lastModifiedBy}, {wirInfo.lastModifiedDate}
								</p>
							</div>
						)}
					</div>

					<div className="work-inspection-listing-create-edit__body">
						<AppTabs ref={tabRef} tabs={tabs} onChange={onHandleTabChange} />
					</div>
				</div>
			</div>

			<Menu classes={{ root: "work-inspection-listing-create-edit-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				{canReschedule && <MenuItem onClick={onHandleSetRescheduled}>Set As Rescheduled</MenuItem>}

				{canCancel && <MenuItem onClick={onHandleSetCancelled}>Set As Cancelled</MenuItem>}
			</Menu>

			<AppConfirmChangeStatusModal ref={confirmChangeStatusModalRef} onConfirm={onHandleSetSignature} onHandleGetDetails={reportDetailsRef.current?.onHandleGetDetails} />
		</div>
	);
};

export default PageWorkInspectionListingCreateEdit;
