import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import capitalizeCharacter from "common/capitalize-character";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ROLES from "constants/roles";
import INPUT_TYPE from "constants/input-type";
import ENDPOINT_PATH from "constants/end-point-path";

import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppInputCreateEditModal from "components/pages/operations/general-configuration/app-input-create-edit-modal";

import editIcon from "assets/images/edit-icon.png";

const AppInputsTable = () => {
	const isMounted = useRef(true);
	const inputCreateEditModalRef = useRef();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.INPUT_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const defaultType = useMemo(() => [INPUT_TYPE.SINGLE_SELECTION, INPUT_TYPE.MULTIPLE_SELECTION, INPUT_TYPE.TEXT, INPUT_TYPE.NUMBER, INPUT_TYPE.CHECKBOX].join(","), []);
	const queryParams = useRef({ page: 0, label: searchParams.get("label") || "", type: searchParams.get("type") || defaultType, tab: "INPUTS" });
	const [data, setData] = useState(convertPaginationTableData());

	const formik = useFormik({
		initialValues: { type: [INPUT_TYPE.SINGLE_SELECTION, INPUT_TYPE.MULTIPLE_SELECTION, INPUT_TYPE.NUMBER, INPUT_TYPE.TEXT, INPUT_TYPE.CHECKBOX] },
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.input.inputs(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	// prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.type = values.type.join(",");

		onHandleGetList();
	}, [onHandleGetList]);

	// prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.label = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.type = defaultType;

		onHandleGetList();
	}, [formik, defaultType, onHandleGetList]);

	const onHandleAddInput = useCallback(() => {
		inputCreateEditModalRef.current.onHandleShow();
	}, []);

	// prettier-ignore
	const onHandleEditInput = useCallback((obj) => {
		inputCreateEditModalRef.current.onHandleShow(data.content[obj.rowIndex]);
	}, [data.content]);

	// prettier-ignore
	const onHandleSelectType = useCallback((value, name) => {
		let values = [...formik.values.type];

		if (formik.values.type.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("type", values);
	}, [formik]);

	const menuFilterType = useMemo(() => {
		const data = [
			{ label: "Single Selection", name: INPUT_TYPE.SINGLE_SELECTION },
			{ label: "Multiple Selection", name: INPUT_TYPE.MULTIPLE_SELECTION },
			{ label: "Number", name: INPUT_TYPE.NUMBER },
			{ label: "Text", name: INPUT_TYPE.TEXT },
			{ label: "CheckBox", name: INPUT_TYPE.CHECKBOX }
		];

		return data;
	}, []);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("type", queryParams.current.type.split(","));
	}, [formik]);

	// prettier-ignore
	let tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false,
			},
		},
		{
			name: "referenceNo",
			label: "Input ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "label",
			label: "Input Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "type",
			label: "Input Type",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => capitalizeCharacter(value.split("_").join(" ")),
			},
		},
		{
			name: "options",
			label: "No. of Inputs",
			options: {
				sort: false,
				customBodyRender: (value) => value?.length
			},
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleEditInput(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [onHandleEditInput]);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		},
	}), [data, onHandleGetList]);

	useEffect(() => {
		if (isMounted.current) {
			isMounted.current = false;

			let type = searchParams.get("type");

			if (type) {
				type = type.split(",");

				memoSetFormValues({ type });
			}
		}
	}, [isMounted, memoSetFormValues, searchParams]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.INPUT.INPUTS);
		};
	}, [cancelRequest]);

	return (
		<div className="app-inputs-table">
			<div className="inputs-table">
				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Input Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} restricted={restricted} onHandleAdd={onHandleAddInput} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="inputs-table-filter">
						<div className="inputs-table-filter__container">
							<p className="inputs-table__label">Input Type</p>

							{menuFilterType.map((o) => {
								const isActive = formik.values.type ? formik.values.type.findIndex((i) => i === o.name) > -1 : false;

								return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectType(v, o.name)} label={o.label} value={isActive} />;
							})}
						</div>
					</div>
				</AppTableFilterHeader>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>

			<AppInputCreateEditModal ref={inputCreateEditModalRef} onHandleGetList={onHandleGetList} />
		</div>
	);
};

export default AppInputsTable;
