import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";
import getInventoryLocationListing from "services/get-inventory-location-listing";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import DATE_TIME from "constants/date-time";
import TRANSFER_TYPE from "constants/transfer-type";
import ENDPOINT_PATH from "constants/end-point-path";

import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppInputSelectMultiple from "components/app-input-select-multiple";
import AppInventoryHistoryMovementViewModal from "components/pages/inventory/app-inventory-history-movement-view-modal";

import eyeIcon from "assets/images/eye-open-icon.png";

const AppInventoryHistoryMovementTable = () => {
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const inventoryRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const [data, setData] = useState(convertPaginationTableData());
	const memoSearchParams = useRef(setSearchParams);
	const defaultTransferType = useMemo(() => [TRANSFER_TYPE.TRANSFER, TRANSFER_TYPE.STOCK_IN, TRANSFER_TYPE.STOCK_OUT].join(","), []);
	const transferType = useMemo(() => {
		const currentTransferType = searchParams.get("transfer-type")?.split(",");
		const relevantTransferType = currentTransferType?.every((e) => defaultTransferType.split(",").includes(e));

		if (!relevantTransferType) {
			return defaultTransferType;
		} else {
			return searchParams.get("transfer-type");
		}
	}, [defaultTransferType, searchParams]);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", locationIds: searchParams.get("locationIds") || [], transactionTypes: transferType, tab: "MOVEMENT" });

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.inventory.inventoryMovements(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.transactionTypes = values.transferType.join(",");
		queryParams.current.locationIds = values.locationIds;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const formik = useFormik({
		initialValues: {
			locationIds: [],
			transferType: [TRANSFER_TYPE.STOCK_IN, TRANSFER_TYPE.TRANSFER, TRANSFER_TYPE.STOCK_OUT]
		},
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("transferType", queryParams.current.transactionTypes.split(","));
		formik.setFieldValue("locationIds", queryParams.current.locationIds);
	}, [formik]);

	//prettier-ignore
	const onHandleView = useCallback((rowIndex) => {
		inventoryRef.current.onHandleShow(data.content[rowIndex]);
	}, [data]);

	const menuFilterTransferType = useMemo(() => {
		const data = [
			{ label: "Stock In", name: TRANSFER_TYPE.STOCK_IN },
			{ label: "Stock Out", name: TRANSFER_TYPE.STOCK_OUT },
			{ label: "Transfer", name: TRANSFER_TYPE.TRANSFER }
		];

		return data;
	}, []);

	//prettier-ignore
	const onHandleSelectTransferType = useCallback((value, name) => {
		let transfer = [...formik.values.transferType];

		if (transfer.length < 2 && !value) return;

		if (!value) {
			transfer = transfer.filter(o => o !== name);
		} else {
			transfer.push(name);
		}

		formik.setFieldValue("transferType", transfer);
	}, [formik]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.transactionTypes = defaultTransferType;

		onHandleGetList();
	}, [defaultTransferType, formik, onHandleGetList]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "employeeCreatedBy",
			label: "Employee",
			options: {
				sort: false
			}
		},
		{
			name: "inventoryItem",
			label: "Inventory Item",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "createdDate",
			label: "Date Time",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY_HH_MM_A)
			}
		},
		{
			name: "transactionType",
			label: "Transaction Type",
			options: {
				sort: false
			}
		},
		{
			name: "inventoryLocationName",
			label: "From",
			options: {
				sort: false
			}
		},
		{
			name: "inventoryLocationNameTo",
			label: "To",
			options: {
				sort: false
			}
		},
		{
			name: "quantity",
			label: "Request Quantity",
			options: {
				sort: false
			}
		},
		{
			name: "available",
			label: "Available Quantity",
			options: {
				sort: false
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;

					if (!data.content[rowIndex].remark) return null;

					return (
						<button type="button" className="table__view" onClick={() => onHandleView(rowIndex)}>
							<img src={eyeIcon} alt="view-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleView, data]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess:".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		},
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.INVENTORY.INVENTORY_MOVEMENTS);
		};
	}, [cancelRequest]);

	return (
		<div className="app-inventory-history-movement-table">
			<div className="inventory-history-movement-table">
				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Employee or Inventory Item" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="inventory-history-movement-table-filter">
						<AppInputSelectMultiple label="Location" name="locationIds" placeholder="Select..." loadOptions={getInventoryLocationListing} value={formik.values.locationIds} error={formik.errors.locationIds} touched={formik.touched.from} onChange={formik.handleChange} />

						<div className="inventory-history-movement-table-filter__container">
							<p className="inventory-history-movement-table-filter__label">Transfer Type</p>

							{menuFilterTransferType.map((o) => {
								const isActiveType = formik.values.transferType.findIndex((i) => i === o.name) > -1;

								return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectTransferType(v, o.name)} label={o.label} value={isActiveType} />;
							})}
						</div>
					</div>
				</AppTableFilterHeader>

				<div className="inventory-history-movement-table__body">
					<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
				</div>
			</div>

			<AppInventoryHistoryMovementViewModal ref={inventoryRef} />
		</div>
	);
};

export default AppInventoryHistoryMovementTable;
