import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import STATUS from "constants/status";
import SERVICE_TYPE from "constants/service-type";
import ENDPOINT_PATH from "constants/end-point-path";

import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import editIcon from "assets/images/edit-icon.png";

const AppServiceListingTable = () => {
	const isMounted = useRef(true);
	const navigate = useNavigate();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	// prettier-ignore
	const defaultStatus = useMemo(() => [STATUS.IN_USE, STATUS.RETIRED].join(","), []);
	const defaultServiceType = useMemo(() => [SERVICE_TYPE.ASSET, SERVICE_TYPE.NON_ASSET].join(","), []);
	// prettier-ignore
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.SERVICE_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", status: searchParams.get("status") || defaultStatus, "service-type": searchParams.get("service-type") || defaultServiceType, tab: "SERVICE_LISTING" });
	const [data, setData] = useState(convertPaginationTableData());

	const formik = useFormik({
		initialValues: { status: [STATUS.IN_USE, STATUS.RETIRED], serviceType: [SERVICE_TYPE.ASSET, SERVICE_TYPE.NON_ASSET] },
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.serviceListing.serviceListings(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");
		
		queryParams.current["service-type"] = values.serviceType.join(",");

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.status = defaultStatus;

		queryParams.current["service-type"] = defaultServiceType;

		onHandleGetList();
	}, [formik, defaultStatus, defaultServiceType, onHandleGetList]);

	const onHandleAddServiceListing = useCallback(() => {
		navigate(pathnames.operations.serviceListingCreateEdit + PAGE.CREATE);
	}, [navigate]);

	// prettier-ignore
	const onHandleEditServiceListing = useCallback((tableMeta) => {
		const id = data.content[tableMeta.rowIndex]?.id;

		navigate(pathnames.operations.serviceListingCreateEdit + id);
	}, [data, navigate]);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let statusValues = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			statusValues = statusValues.filter(o => o !== name);
		} else {
			statusValues.push(name);
		}

		formik.setFieldValue("status", statusValues);
	}, [formik]);

	//prettier-ignore
	const onHandleServiceType = useCallback((value, name) => {
		let serviceTypeValues = [...formik.values.serviceType];

		if (formik.values.serviceType.length < 2 && !value) return;

		if (!value) {
			serviceTypeValues = serviceTypeValues.filter((o) => o !== name);
		} else {
			serviceTypeValues.push(name);
		}

		formik.setFieldValue("serviceType", serviceTypeValues);
	}, [formik]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "In-Use", name: STATUS.IN_USE },
			{ label: "Retired", name: STATUS.RETIRED }
		];

		return data;
	}, []);

	const menuFilterServiceType = useMemo(() => {
		const data = [
			{ label: "Asset", name: SERVICE_TYPE.ASSET },
			{ label: "Non-Asset", name: SERVICE_TYPE.NON_ASSET }
		];

		return data;
	}, []);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
	}, [formik]);

	//prettier-ignore
	let tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "referenceNo",
			label: "Service ID",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "serviceAction",
			label: "Service Action",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "serviceType",
			label: "Service Type",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "input.label",
			label: "Input",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleEditServiceListing(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleEditServiceListing]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		if (isMounted.current) {
			isMounted.current = false;

			let status = searchParams.get("status");
			let serviceType = searchParams.get("service-type");

			if (status && serviceType) {
				status = status.split(",");
				serviceType = serviceType.split(",");

				memoSetFormValues({ status, serviceType });
			}
		}
	}, [isMounted, memoSetFormValues, searchParams]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.SERVICE_LISTING.SERVICE_LISTINGS);
		};
	}, [cancelRequest]);

	return (
		<div className="app-service-listing-table">
			<div className="service-listing-table">
				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Service ID or Service Action" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} restricted={restricted} onHandleAdd={onHandleAddServiceListing} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="service-listing-table-filter">
						<div className="service-listing-table-filter__container">
							<p className="service-listing-table__label">Status</p>

							{menuFilterStatus.map((o) => {
								const isActive = formik.values.status.findIndex((i) => i === o.name) > -1;

								return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
							})}
						</div>

						<div className="service-listing-table-filter__container">
							<p className="service-listing-table__label">Service Type</p>

							{menuFilterServiceType.map((o) => {
								const isActive = formik.values.serviceType.findIndex((i) => i === o.name) > -1;

								return (
									<div className="service-listing-table__checkbox" key={o.label}>
										<AppCheckbox key={o.label} onClick={(v) => onHandleServiceType(v, o.name)} label={o.label} value={isActive} />
									</div>
								);
							})}
						</div>
					</div>
				</AppTableFilterHeader>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>
		</div>
	);
};

export default AppServiceListingTable;
