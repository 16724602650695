import React, { forwardRef, memo, useCallback, useMemo, useRef, useState } from "react";

import { debounce } from "lodash";
import PropTypes from "prop-types";
import { FormControl, InputAdornment, TextField } from "@mui/material";

import classNames from "common/class-names";

import AppIcon from "components/app-icon";
import AppCloseIcon from "components/icons/app-close-icon";

import alert from "assets/images/alert-icon.png";
import eyeOpenIcon from "assets/images/components/app-input/eye-open-icon.svg";
import eyeCloseIcon from "assets/images/components/app-input/eye-close-icon.svg";

const AppInput = forwardRef((props, ref) => {
	const [passwordVisible, setPasswordVisible] = useState(false);

	const inputRef = useRef();

	const [displayClearIcon, setDisplayClearIcon] = useState(!!props.defaultValue);

	const isPasswordField = useMemo(() => props.type === "password", [props.type]);

	const isErrorField = useMemo(() => !!props.error && !!props.touched, [props.error, props.touched]);

	const errorMessage = useMemo(() => (isErrorField ? props.error : ""), [props.error, isErrorField]);

	const inputType = useMemo(() => (isPasswordField && passwordVisible ? "text" : props.type), [isPasswordField, passwordVisible, props.type]);

	const maxLength = useMemo(() => props.maxLength, [props.maxLength]);

	const className = useMemo(() => {
		return classNames({
			"app-input": true,
			"app-input--disabled": props.disabled,
			"app-input--multiline": props.multiline,
			...(props.className && {
				[props.className]: true
			})
		});
	}, [props.className, props.disabled, props.multiline]);

	const onTogglePasswordVisibility = useCallback(() => {
		setPasswordVisible((prev) => !prev);
	}, []);

	//prettier-ignore
	const onHandleKeyDown = useCallback((event) => {
		if (props.type === "number") {
			if(event.target.value.length >= maxLength && event.key !== "Backspace") {
				event.preventDefault();
			}
			
			switch (event.key) {
				case "e":
				case "-":
				case "+":
				case ".":
				case "ArrowUp":
				case "ArrowDown":
					event.preventDefault();
					break;
				default:
					break;
			}
		} 
		
		event.stopPropagation();
	}, [maxLength, props.type]);

	//prettier-ignore
	const onHandleClearIconDisplay = useCallback((event) => {
		const trimmedValue = event.target.value.trim();

		if(!props.onClear) return;

		setDisplayClearIcon(!!trimmedValue);
	}, [props.onClear]);

	//prettier-ignore
	const onHandleChange = useCallback((event) => {
		onHandleClearIconDisplay(event);
		
		switch (props.type) {
			case "number":
				event.target.value = event.target.value.replace(/[^\d]/g, "");

				props.onChange(event);
				break;
			default:
				if (props.onFormat) {
					const enrichedEvent = props.onFormat(event);

					props.onChange(enrichedEvent);
				} else {
					props.onChange(event);
				}
				break;
		}
	}, [props, onHandleClearIconDisplay]);

	//prettier-ignore
	const onHandleBlur = useCallback((event) => {
		event.target.value = event.currentTarget.value.trim();

		if (!props.onBlur) props.onChange(event);
	}, [props]);

	//prettier-ignore
	const onHandleClear = useCallback(() => {
		inputRef.current.value = "";

		setDisplayClearIcon(false);
		
		props.onClear();
	}, [props]);

	const onHandleWheel = useCallback((event) => {
		return;
	}, []);

	//prettier-ignore
	const onHandlePaste = useCallback((event) => {
		const pastedValue = event.clipboardData.getData("text");

		if (props.type === "number" && pastedValue.length > maxLength) {
			event.preventDefault();
		}
	}, [maxLength, props.type]);

	const onHandleDebounceClear = debounce(onHandleClear, 500);

	const InputProps = useMemo(() => {
		const inputProps = {};

		if (isPasswordField) {
			const endAdornment = {
				endAdornment: (
					<InputAdornment position="end">
						<button type="button" className="app-input__toggle-icon" onClick={onTogglePasswordVisibility}>
							{passwordVisible ? <AppIcon src={eyeOpenIcon} /> : <AppIcon src={eyeCloseIcon} />}
						</button>
					</InputAdornment>
				)
			};

			Object.assign(inputProps, endAdornment);
		} else if (props.onClear && displayClearIcon) {
			const endAdornment = {
				endAdornment: (
					<InputAdornment position="end">
						<button type="button" className="app-input__toggle-icon" onClick={onHandleDebounceClear}>
							<AppCloseIcon color="#666666" />
						</button>
					</InputAdornment>
				)
			};

			Object.assign(inputProps, endAdornment);
		} else if (isErrorField) {
			const endAdornment = {
				endAdornment: (
					<InputAdornment position="end">
						<AppIcon src={alert} />
					</InputAdornment>
				)
			};

			Object.assign(inputProps, endAdornment);
		}

		if (props.endIcon) {
			const adornment = {
				endAdornment: (
					<InputAdornment position="end">
						<AppIcon src={props.endIcon} />
					</InputAdornment>
				)
			};

			Object.assign(inputProps, adornment);
		}

		if (props.startIcon) {
			const adornment = {
				startAdornment: (
					<InputAdornment position="start">
						<AppIcon src={props.startIcon} />
					</InputAdornment>
				)
			};

			Object.assign(inputProps, adornment);
		}

		if (props.multiline) {
			inputProps.multiline = true;
			inputProps.rows = 5;
			inputProps.inputComponent = "textarea";
		}

		return inputProps;
	}, [isPasswordField, displayClearIcon, isErrorField, props.onClear, props.endIcon, props.startIcon, props.multiline, onTogglePasswordVisibility, passwordVisible, onHandleDebounceClear]);

	const CharLimitText = useCallback(() => {
		const notTextarea = props.type !== "textarea";

		if (notTextarea) return null;

		let text = `0/${props.maxLength || 255}`;

		if (props.value?.length) {
			text = `${props.value.length}/${props.maxLength || 255}`;
		}

		return <div className="app-input__char-limit">{text}</div>;
	}, [props]);

	return (
		<div className={className}>
			{props.icon && <AppIcon className={isErrorField ? "app-input__icon--error" : "app-input__icon"} src={props.icon} />}

			<FormControl error={isErrorField}>
				{props.label && (
					<label className="app-input__label" htmlFor={props.name}>
						{props.label}
						{props.required && <span className="app-input__required">*</span>}
					</label>
				)}

				{/*prettier-ignore*/}
				<TextField inputRef={ref ?? inputRef} autoComplete="off" value={props.value} type={inputType} name={props.name} error={isErrorField} helperText={errorMessage} disabled={props.disabled} placeholder={props.placeholder} onBlur={onHandleBlur} onPaste={onHandlePaste} onChange={onHandleChange} onKeyDown={onHandleKeyDown} onWheel={onHandleWheel} InputProps={InputProps} defaultValue={props.defaultValue} inputProps={{ maxLength: props.maxLength }} />

				<CharLimitText />
			</FormControl>
		</div>
	);
});

AppInput.propTypes = {
	error: PropTypes.string,
	label: PropTypes.string,
	icon: PropTypes.string,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	onFormat: PropTypes.func,
	className: PropTypes.string,
	maxLength: PropTypes.number,
	placeholder: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onClear: PropTypes.func,
	type: PropTypes.oneOf(["text", "textarea", "password", "number"]).isRequired
};

export default memo(AppInput);
