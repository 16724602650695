import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";
import ENDPOINT_PATH from "constants/end-point-path";

import AppTabs from "components/app-tabs";
import AppButton from "components/app-button";
import AppLeaveCalendar from "components/pages/human-resources/app-leave-calendar";
import AppHrViewLeaveHistoryTable from "components/pages/human-resources/app-hr-view-leave-history-table";
import AppHrViewPendingLeavesTable from "components/pages/human-resources/app-hr-view-pending-leaves-table";
import AppHrViewEmployeeDirectoryTable from "components/pages/human-resources/app-hr-view-employee-directory-table";

import clockIcon from "assets/images/clock-icon.svg";
import personIcon from "assets/images/person-icon.svg";
import activeClockIcon from "assets/images/clock-icon blue.svg";
import personActiveIcon from "assets/images/person-active-icon.svg";
import gearIcon from "assets/images/pages/human-resources/blue-gear-icon.svg";
import leaveApproveIcon from "assets/images/pages/human-resources/leave-approval-icon.svg";
import leaveCalendarIcon from "assets/images/pages/human-resources/leave-calendar-icon.svg";
import activeLeaveApproveIcon from "assets/images/pages/human-resources/leave-approval-active-icon.svg";
import activeLeaveCalendarIcon from "assets/images/pages/human-resources/leave-calendar-active-icon.svg";

const PageLeaveManagement = () => {
	const navigate = useNavigate();
	const profile = useSelector((state) => state.profile);
	const [isHR, setIsHR] = useState();
	const [employeeId, setEmployeeId] = useState();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;

	const onHandleGetEmployeeDetail = useCallback(async (uniqueId) => {
		let response = null;

		try {
			const params = sanitizeObject({ "user-id": uniqueId });

			response = await api.get.humanResource.employeeHR(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setIsHR(!response.supervisor && !response.reportingManager);

			setEmployeeId(response.id);
		}
	}, []);

	//prettier-ignore
	const onConfigHoliday = useCallback(() => {
		navigate(pathnames.humanResources.hrViewHolidays);
	}, [navigate]);

	//prettier-ignore
	const leaveManagementTabs = useMemo(() => [
		{ label: "Leave Calendar", icon: leaveCalendarIcon, activeIcon: activeLeaveCalendarIcon, name: "LEAVE_CALENDAR", component: <AppLeaveCalendar />, accessible: profile?.permissions?.[ROLES.LEAVE_MANAGEMENT]?.view },
		{ label: "Employee Directory", icon: personIcon, activeIcon: personActiveIcon, name: "EMPLOYEE_DIRECTORY", component: <AppHrViewEmployeeDirectoryTable />, accessible: profile?.permissions?.[ROLES.LEAVE_MANAGEMENT]?.view },
		{ label: "Leave History", icon: clockIcon, activeIcon: activeClockIcon, name: "LEAVE_HISTORY", component: <AppHrViewLeaveHistoryTable />, accessible: profile?.permissions?.[ROLES.LEAVE_MANAGEMENT]?.view },
		{ label: "Pending Leave", icon: leaveApproveIcon, activeIcon: activeLeaveApproveIcon, name: "PENDING_LEAVE", component: <AppHrViewPendingLeavesTable isHR={isHR} employeeId={employeeId} />, accessible: profile?.permissions?.[ROLES.LEAVE_MANAGEMENT]?.view }
	], [isHR, employeeId, profile?.permissions]);

	useEffect(() => {
		onHandleGetEmployeeDetail(profile.id);
	}, [onHandleGetEmployeeDetail, profile.id]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_HR);
		};
	}, [cancelRequest]);

	return (
		<div className="page-leave-management">
			<div className="leave-management">
				<div className="leave-management__header">
					<h1 className="leave-management__title">All Leave</h1>

					<div className="leave-management__configuration-button">
						<AppButton outline type="button" label="Configure Holidays" icon={gearIcon} onClick={onConfigHoliday} />
					</div>
				</div>

				<div className="leave-management__body">
					<AppTabs tabs={leaveManagementTabs} />
				</div>
			</div>
		</div>
	);
};

export default PageLeaveManagement;
