import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import { Modal } from "@mui/material";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import AppButton from "components/app-button";

export const AppWorkOrderImportFromCustomerModal = (props, ref) => {
	const [onShowData, setOnShowData] = useState({});
	const [visible, setVisible] = useState(false);
	const [totalPages, setTotalPages] = useState();
	const onHandleAddServices = useMemo(() => props.onHandleAddServices, [props.onHandleAddServices]);

	//prettier-ignore
	const onHandleRetrieveServices = useCallback(async (pageNumber) => {
		let response = null;

		const params = sanitizeObject({ page: pageNumber, size: 10 });

		try {
			response = await api.get.assetMaintenance.assetServices({ ...params, "asset-id": onShowData.assetId });
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			if (!totalPages) setTotalPages(response.setTotalPages);
			
			const obj = response.content;

			return obj;
		}
	}, [onShowData.assetId, totalPages]);

	const onHandlePageByPageRetrieval = useCallback(async () => {
		let serviceList = [];
		let pageNumber = 0;

		if (!totalPages) {
			onHandleRetrieveServices(0);
		} else {
			while (pageNumber < totalPages) {
				const retrievedServices = await onHandleRetrieveServices(pageNumber);

				serviceList = [...serviceList, ...retrievedServices];

				pageNumber++;
			}
		}

		return serviceList;
	}, [onHandleRetrieveServices, totalPages]);

	const onHandleConfirm = useCallback(async () => {
		const serviceList = await onHandlePageByPageRetrieval();

		onHandleAddServices(serviceList);

		setVisible(false);
	}, [onHandleAddServices, onHandlePageByPageRetrieval]);

	//prettier-ignore
	const onHandleShow = useCallback((data) => {
		setOnShowData(data);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-work-order-import-from-customer-modal" }} open={visible}>
			<div className="work-order-import-from-customer-modal">
				<h1 className="work-order-import-from-customer-modal__title">Are you sure?</h1>

				<p className="work-order-import-from-customer-modal__description">
					After clicking confirm, the Services Lines <br /> will be imported from <span className="work-order-import-from-customer-modal__name">{onShowData.siteName}</span>
				</p>

				<div className="work-order-import-from-customer-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="submit" label="Confirm" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppWorkOrderImportFromCustomerModal));
