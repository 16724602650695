import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import classNames from "common/class-names";
import getTotalDays from "common/get-total-days";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ROLES from "constants/roles";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppPendingLeavesViewModal from "components/pages/human-resources/app-pending-leaves-view-modal";
import AppPendingLeavesRejectModal from "components/pages/human-resources/app-pending-leaves-reject-modal";

import eyeIcon from "assets/images/eye-open-icon.png";
import rejectIcon from "assets/images/pages/customer/reject-icon.svg";
import approveIcon from "assets/images/pages/customer/approve-icon.svg";

const AppHrViewPendingLeavesTable = (props) => {
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.LEAVE_MANAGEMENT], [profile]);
	const restricted = useMemo(() => !accessible?.view, [accessible]);
	const pendingLeavesRef = useRef();
	const rejectionRef = useRef();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [data, setData] = useState(convertPaginationTableData());
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const [selectedPending, setSelectedPending] = useState([]);
	const [selectedPermission, setSelectedPermission] = useState([]);
	const employeeId = useMemo(() => props.employeeId, [props.employeeId]);
	const isHR = useMemo(() => props.isHR, [props.isHR]);
	const defaultStatus = useMemo(() => [STATUS.PENDING, STATUS.APPROVED_BY_SUPERVISOR].join(","), []);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", status: defaultStatus, tab: "PENDING_LEAVE" });

	const validationSchema = yup.object({
		endDate: yup.date().test(function (value, { parent }) {
			if (!value) return true;

			const totalDays = getTotalDays(parent.startDate, value);

			return totalDays >= 0 && totalDays <= 90;
		})
	});

	const formik = useFormik({
		initialValues: { startDate: "", endDate: "", status: [STATUS.PENDING, STATUS.APPROVED_BY_SUPERVISOR] },
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const intructionClassName = useMemo(() => classNames({
		"filter-menu__instruction": true,
		"filter-menu__instruction--error": formik.errors.endDate
	}), [formik.errors.endDate]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = { ...queryParams.current, size: 10 };

			memoSearchParams.current(params);

			response = await api.get.humanResource.pendingLeave(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);

			setSelectedPending([]);

			setSelectedPermission([]);
		}
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;
		
		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current["start-date"] = "";
		queryParams.current["end-date"] = "";

		onHandleGetList();
	}, [formik, onHandleGetList]);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
		queryParams.current["start-date"] && formik.setFieldValue("startDate", dayjs(queryParams.current["start-date"]));
		queryParams.current["end-date"] && formik.setFieldValue("endDate", dayjs(queryParams.current["end-date"]));
	}, [formik]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Pending", name: STATUS.PENDING },
			{ label: "Approved by Supervisor", name: STATUS.APPROVED_BY_SUPERVISOR }
		];

		return data;
	}, []);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (values.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	//prettier-ignore
	const onHandleSelectPending = useCallback((boolean, idNo, permissionId) => {
		setSelectedPending((prev) => {
			let nextPending = [...prev];

			if (boolean) {
				nextPending.push(idNo);
			} else {
				nextPending = nextPending.filter((id) => id !== idNo);
			}

			if (!isHR) {
				setSelectedPermission((prevStatus) => {
					if (!nextPending?.length) {
						return [];
					} else if (boolean) {
						return permissionId;
					}
					return prevStatus;
				});
			}

			return nextPending;
		});
	}, [isHR]);

	//prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		if (boolean) {
			const ids = data.content.filter(item => !selectedPermission.length || (item.supervisorId === selectedPermission && item.status === STATUS.PENDING) || (item.reportingManagerId === selectedPermission && item.status === STATUS.APPROVED_BY_SUPERVISOR)).map(item => item.id);
			
			setSelectedPending(ids);
		} else {
			setSelectedPending([]);

			setSelectedPermission([]);
		}
	}, [data.content, selectedPermission]);

	//prettier-ignore
	const onHandleViewPendingLeaves = useCallback((obj) => {
		pendingLeavesRef.current.onHandleShow(data.content[obj.rowIndex]);
	}, [data]);

	//prettier-ignore
	const onHandleRejectReason = useCallback(async (values, id) => {
		let response = null;

		try {
			await api.post.humanResource.rejectEmployeeLeave({ "leave-id": id, reason: values });

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Leave was rejected successfully" }));

			onHandleGetList();
		}
	}, [dispatch, onHandleGetList]);

	//prettier-ignore
	const onHandleRejectReasons = useCallback(async (values, ids) => {
		let response = null;

		try {
			const payload = { leaveRequestIds: ids, rejectReason: values };

			await api.post.humanResource.rejectPendingLeave(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Leave was rejected successfully" }));

			onHandleGetList();
		}
	}, [dispatch, onHandleGetList]);

	const onHandleRejectLeaves = useCallback(() => {
		rejectionRef.current.onHandleShow(selectedPending);
	}, [selectedPending]);

	const onHandleApproveLeave = useCallback(async () => {
		let response = null;

		try {
			const payload = { leaveRequestIds: selectedPending };

			await api.post.humanResource.approvePendingLeave(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Leave was approved successfully" }));

			onHandleGetList();
		}
	}, [selectedPending, dispatch, onHandleGetList]);

	const onHandleRejectLeave = useCallback((id) => {
		rejectionRef.current.onHandleShow(id);
	}, []);

	//prettier-ignore
	const onHandleBack = useCallback((id) => {
		const backData = data.content.filter((item) => id.includes(item.id));

		pendingLeavesRef.current.onHandleShow(backData[0]);
	}, [data]);

	// prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");

		if (values.startDate && dayjs(values.startDate).isValid()) {
			queryParams.current["start-date"] = dayjs(values.startDate).format(DATE_TIME.YYYY_MM_DD);
		}

		if (values.endDate && dayjs(values.endDate).isValid()) {
			queryParams.current["end-date"] = dayjs(values.endDate).format(DATE_TIME.YYYY_MM_DD);
		}

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "checkbox",
			options: {
				sort: false,
				customHeadRender: () => {
					const selectedAll = data.content.length > 0 && selectedPending.length === data.content.filter((item) => !selectedPermission.length || selectedPermission.includes(item.status === STATUS.PENDING ? item.supervisorId : item.reportingManagerId)).length;

					return (
						<TableCell key="table-key" className="app-table__cell app-table__cell--header">
							{(isHR || selectedPending.length > 0) && <AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />}
						</TableCell>
					);
				},
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tablePendingStatus = data.content[rowIndex]?.status;
					const permittedId = tablePendingStatus === STATUS.PENDING ? data.content[rowIndex]?.supervisorId : data.content[rowIndex]?.reportingManagerId;
					const isSupervisor = data.content[rowIndex]?.supervisorId === employeeId;
					const isReportingManager = data.content[rowIndex]?.reportingManagerId === employeeId;
					const tablePendingID = data.content[rowIndex]?.id;
					const selected = selectedPending.includes(tablePendingID);
					const checkPermission = !selectedPermission.length || selectedPermission.includes(employeeId);
					const permission = (isSupervisor && tablePendingStatus === STATUS.PENDING) || (isReportingManager && tablePendingStatus === STATUS.APPROVED_BY_SUPERVISOR) || isHR;

					return (
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className="app-table__cell">{permission && <AppCheckbox className="checkbox" disabled={!checkPermission} onClick={(v) => onHandleSelectPending(v, tablePendingID, permittedId)} value={selected} />} </TableCell>
								</TableRow>
							</TableBody>
						</Table>
					);
				}
			}
		},
		{
			name: "employeeName",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "days",
			label: "Days",
			options: {
				sort: false
			}
		},
		{
			name: "leaveType",
			label: "Leave Type",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "submitDate",
			label: "Submit Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			}
		},
		{
			name: "startDate",
			label: "Start Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			}
		},
		{
			name: "endDate",
			label: "End Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" disabled={restricted} className="table__view" onClick={() => onHandleViewPendingLeaves(tableMeta)}>
							<img src={eyeIcon} alt="view-icon" />
						</button>
					);
				}
			}
		}
	], [selectedPending, data.content, isHR, onHandleSelectAll, selectedPermission, employeeId, onHandleSelectPending, restricted, onHandleViewPendingLeaves]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	//prettier-ignore
	const HeaderApprovalButton = useCallback((obj) => {
		if (!obj.selectedPending.length) return null;

		return (
			<div className="hr-view-pending-leaves-table__header-button">
				<div className="hr-view-pending-leaves-table__reject-button">
					<AppButton outline type="button" disabled={obj.restricted} label="Reject" icon={rejectIcon} onClick={obj.onHandleRejectLeaves} />
				</div>
				
				{!obj.isHR && (
					<div className="hr-view-pending-leaves-table__approve-button">
						<AppButton type="button" disabled={obj.restricted} label="Approve" icon={approveIcon} onClick={obj.onHandleApproveLeave} />
					</div>
				)}
			</div>
		);
	}, []);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.PENDING_LEAVE);
		};
	}, [cancelRequest]);

	return (
		<div className="app-hr-view-pending-leaves-table">
			<div className="hr-view-pending-leaves-table">
				<div className="hr-view-pending-leaves-table__header">
					<AppTableFilterHeader restricted={restricted} formik={{ ...formik }} searchPlaceholder="Search by Name or Leave Type" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
						<div className="hr-view-pending-leaves-table-filter">
							<div className="hr-view-pending-leaves-table-filter__container">
								<div className="hr-view-pending-leaves-table-filter__inputs">
									<AppInputDate name="startDate" label="Submit Date" placeholder="Start Date" value={formik.values.startDate} onChange={formik.setFieldValue} />

									<AppInputDate name="endDate" placeholder="End Date" value={formik.values.endDate} onChange={formik.setFieldValue} />
								</div>

								<p className={intructionClassName}>Please select a date or a date range of up to 90 days</p>
							</div>

							<div className="hr-view-pending-leaves-table-filter__container">
								<p className="hr-view-pending-leaves-table-filter__label">Status</p>

								<div className="hr-view-pending-leaves-table-filter__checkbox-status">
									{menuFilterStatus.map((o) => {
										const isActive = formik.values.status.findIndex((i) => i === o.name) > -1;

										return (
											<div className="hr-view-pending-leaves-table-filter__checkbox" key={o.label}>
												<AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</AppTableFilterHeader>

					<HeaderApprovalButton restricted={restricted} data={data.content} selectedPending={selectedPending} onHandleRejectLeaves={onHandleRejectLeaves} onHandleApproveLeave={onHandleApproveLeave} isHR={isHR} />
				</div>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>

			<AppPendingLeavesViewModal ref={pendingLeavesRef} onHandleRejectLeave={onHandleRejectLeave} onHandleGetList={onHandleGetList} isHR={isHR} employeeId={employeeId} />

			<AppPendingLeavesRejectModal ref={rejectionRef} onHandleRejectReason={onHandleRejectReason} onHandleRejectReasons={onHandleRejectReasons} onHandleBack={onHandleBack} />
		</div>
	);
};

export default AppHrViewPendingLeavesTable;
