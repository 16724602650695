import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ROLES from "constants/roles";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppPendingClaimModal from "components/pages/human-resources/app-pending-claim-modal";
import AppPendingClaimRejectionModal from "components/pages/human-resources/app-pending-claim-rejection-modal";

import eyeIcon from "assets/images/eye-open-icon.png";
import rejectIcon from "assets/images/pages/human-resources/reject-icon.svg";
import approveIcon from "assets/images/pages/human-resources/approve-icon.svg";

const AppPendingClaimTable = (props) => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [data, setData] = useState(convertPaginationTableData());
	const pendingClaimRef = useRef();
	const pendingClaimRejectionModal = useRef();
	const [selectedClaim, setSelectedClaim] = useState([]);
	const [selectedPermission, setSelectedPermission] = useState([]);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CLAIM_MANAGEMENT], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const employeeId = useMemo(() => props.employeeId, [props.employeeId]);
	const isHR = useMemo(() => props.isHR, [props.isHR]);
	const defaultStatus = useMemo(() => [STATUS.APPROVED_BY_SUPERVISOR, STATUS.PENDING].join(","), []);
	const queryParams = useRef({ page: 0, "employee-id": id, status: defaultStatus, tab: "PENDING_CLAIM" });

	const onHandleGetList = useCallback(async (id) => {
		let response = null;

		const params = sanitizeObject({ ...queryParams.current, "employee-id": id, size: 10 });

		try {
			response = await api.get.humanResource.employeePendingClaim(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);

			setSelectedClaim([]);

			setSelectedPermission([]);
		}
	}, []);

	//prettier-ignore
	const onHandleSelectClaim = useCallback((boolean, id, permissionId) => {
		setSelectedClaim((prev) => {
			let nextClaim = [...prev];

			if (boolean) {
				nextClaim.push(id);
			} else {
				nextClaim = nextClaim.filter((nextClaimId) => nextClaimId !== id);
			}

			if (!isHR) {
				setSelectedPermission((prevStatus) => {
					if (!nextClaim?.length) {
						return [];
					} else if (boolean) {
						return permissionId;
					}
					return prevStatus;
				});
			}

			return nextClaim;
		});
	}, [isHR]);

	//prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		if (boolean) {
			const ids = data.content.filter(item => !selectedPermission.length || (item.supervisorId === selectedPermission && item.status === STATUS.PENDING) || (item.reportingManagerId === selectedPermission && item.status === STATUS.APPROVED_BY_SUPERVISOR)).map(item => item.id);
			
			setSelectedClaim(ids);
		} else {
			setSelectedClaim([]);

			setSelectedPermission([]);
		}
	}, [data.content, selectedPermission]);

	//prettier-ignore
	const onHandleBack = useCallback((id) => {
		const backData = data.content.filter((item) => id.includes(item.id));

		pendingClaimRef.current.onHandleShow(backData[0]);
	}, [data]);

	//prettier-ignore
	const onHandleViewClaimDetails = useCallback((obj) => {
		pendingClaimRef.current.onHandleShow(data.content[obj.rowIndex]);
	}, [data]);

	//prettier-ignore
	const onHandleRejectReason = useCallback(async (values, ids) => {
		let response = null;

		try {
			await api.post.humanResource.rejectEmployeeClaim({ "claim-id": ids, reason: values });

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Claim was rejected successfully" }));

			onHandleGetList(id);
		}
	}, [dispatch, onHandleGetList, id]);

	//prettier-ignore
	const onHandleRejectReasons = useCallback(async (values, ids) => {
		let response = null;

		try {
			const payload = { claimRequestIds: ids, reason: values };

			await api.post.humanResource.rejectPendingClaim(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Claim was rejected successfully" }));

			onHandleGetList(id);
		}
	}, [dispatch, id, onHandleGetList]);

	const onHandleRejectClaim = useCallback((id) => {
		pendingClaimRejectionModal.current.onHandleShow(id);
	}, []);

	const onHandleRejectClaims = useCallback(() => {
		pendingClaimRejectionModal.current.onHandleShow(selectedClaim);
	}, [selectedClaim]);

	//prettier-ignore
	const onHandleApproveClaim = useCallback(async (ids) => {
		let response = null;

		try {
			await api.post.humanResource.approveEmployeeClaim({ "claim-id": ids });

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Claim was approved successfully" }));

			onHandleGetList(id);
		}
	}, [dispatch, onHandleGetList, id]);

	const onHandleApproveClaims = useCallback(async () => {
		let response = null;

		try {
			const payload = { claimRequestIds: selectedClaim };

			await api.post.humanResource.approvePendingClaim(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Claim was approved successfully" }));

			onHandleGetList(id);
		}
	}, [selectedClaim, dispatch, onHandleGetList, id]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "checkbox",
			options: {
				sort: false,
				customHeadRender: () => {
					const selectedAll = data.content.length > 0 && selectedClaim.length === data.content.filter((item) => !selectedPermission.length || selectedPermission.includes(item.status === STATUS.PENDING ? item.supervisorId : item.reportingManagerId)).length;

					return (
						<TableCell key="table-key" className="app-table__cell app-table__cell--header">
							{(isHR || selectedClaim.length > 0) && <AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />}
						</TableCell>
					);
				},
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tableClaimStatus = data.content[rowIndex]?.status;
					const permittedId = tableClaimStatus === STATUS.PENDING ? data.content[rowIndex]?.supervisorId : data.content[rowIndex]?.reportingManagerId;
					const isSupervisor = data.content[rowIndex]?.supervisorId === employeeId;
					const isReportingManager = data.content[rowIndex]?.reportingManagerId === employeeId;
					const tableClaimID = data.content[rowIndex]?.id;
					const selected = selectedClaim.includes(tableClaimID);
					const checkPermission = !selectedPermission.length || selectedPermission.includes(employeeId);
					const permission = (isSupervisor && tableClaimStatus === STATUS.PENDING) || (isReportingManager && tableClaimStatus === STATUS.APPROVED_BY_SUPERVISOR) || isHR;

					return (
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className="app-table__cell">{permission && <AppCheckbox className="checkbox" disabled={!checkPermission} onClick={(v) => onHandleSelectClaim(v, tableClaimID, permittedId)} value={selected} />} </TableCell>
								</TableRow>
							</TableBody>
						</Table>
					);
				}
			}
		},
		{
			name: "claimType",
			label: "Claim Type",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "createdDate",
			label: "Submit Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const submitDateData = data.content[rowIndex].submitDate;

					return dayjs(submitDateData).format(DATE_TIME.DD_MM_YYYY);
				}
			}
		},
		{
			name: "receiptAmount",
			label: "Amount (MYR)",
			options: {
				sort: false
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: false,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" disabled={restricted} className="table__view" onClick={() => onHandleViewClaimDetails(tableMeta)}>
							<img src={eyeIcon} alt="expand-icon" />
						</button>
					);
				}
			}
		}
	], [data.content, employeeId, isHR, onHandleSelectAll, onHandleSelectClaim, onHandleViewClaimDetails, restricted, selectedClaim, selectedPermission]);

	//prettier-ignore
	const HeaderDetailsButton = useCallback((obj) => {
		if (!obj.selectedClaim.length) return null;

		return (
			<div className="pending-claim-table__header-button">
					<div className="pending-claim-table__reject-button">
						<AppButton outline type="button" disabled={obj.restricted} label="Reject" icon={rejectIcon} onClick={obj.onHandleRejectClaims} />
					</div>

				{!obj.isHR && (
					<div className="pending-claim-table__approve-button">
						<AppButton type="button" disabled={obj.restricted} label="Approve" icon={approveIcon} onClick={obj.onHandleApproveClaims} />
					</div>
				)}
			</div>
		);
	}, []);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList(id);
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList(id);
					break;
				default:
					break;
			}
		}
	}), [data.page, data.totalElements, id, onHandleGetList]);

	useEffect(() => {
		onHandleGetList(id);
	}, [id, onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_PENDING_CLAIM);
		};
	}, [cancelRequest]);

	return (
		<div className="app-pending-claim-table">
			<div className="pending-claim-table">
				<HeaderDetailsButton isHR={isHR} data={data.content} selectedClaim={selectedClaim} onHandleApproveClaims={onHandleApproveClaims} onHandleRejectClaims={onHandleRejectClaims} restricted={restricted} />

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>

			<AppPendingClaimRejectionModal ref={pendingClaimRejectionModal} onHandleRejectReason={onHandleRejectReason} onHandleRejectReasons={onHandleRejectReasons} onHandleBack={onHandleBack} />

			<AppPendingClaimModal ref={pendingClaimRef} onHandleRejectClaim={onHandleRejectClaim} onHandleApproveClaim={onHandleApproveClaim} isHR={isHR} employeeId={employeeId} />
		</div>
	);
};

export default AppPendingClaimTable;
