import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import useBreadcrumb from "hooks/use-breadcrumb";

import serveRequestErrors, { serveLayoutRequestErrors } from "common/serve-request-errors";

import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppTabs from "components/app-tabs";
import AppButton from "components/app-button";
import AppSalesOrderDetails from "components/pages/sales/app-sales-order-details";
import AppSalesOrderInvoice from "components/pages/sales/app-sales-order-invoice";
import AppSalesOrderDeliveryOrder from "components/pages/sales/app-sales-order-delivery-order";

import exportIcon from "assets/images/export-icon.png";
import detailsIcon from "assets/images/pages/sales/details-icon.svg";
import invoiceIcon from "assets/images/pages/sales/invoice-icon.svg";
import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";
import deliveryOrderIcon from "assets/images/pages/sales/delivery-order-icon.svg";
import activeDetailsIcon from "assets/images/pages/sales/details-active-icon.svg";
import activeInvoiceIcon from "assets/images/pages/sales/invoice-active-icon.svg";
import activeDeliveryOrderIcon from "assets/images/pages/sales/delivery-order-active-icon.svg";

const PageSalesOrderCreateEdit = () => {
	const { id } = useParams();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [data, setData] = useState([]);
	const [isDraft, setIsDraft] = useState([]);
	const [menuAnchor, setMenuAnchor] = useState(null);
	const initialValues = useMemo(() => ({ lastModifiedBy: "", lastModifiedDate: "" }), []);

	const formik = useFormik({ initialValues: initialValues });

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const onHandleSetSalesOrderInfo = useCallback((data) => {
		setIsDraft(data.status === STATUS.DRAFT);

		setData(data);
	}, []);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		const payload = {
			id: uniqueId
		};

		let response = null;

		try {
			response = await api.get.salesOrders.salesOrder(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			onHandleSetSalesOrderInfo({ ...response });

			memoSetFormValues({
				referenceNo: response.referenceNo,
				lastModifiedBy: response.lastModifiedBy,
				lastModifiedDate: dayjs(response.lastModifiedDate).format(DATE_TIME.LAST_UPDATED_BY_DATE),
			});
		}
	}, [memoSetFormValues, onHandleSetSalesOrderInfo]);

	//prettier-ignore
	const salesOrderTabs = useMemo(() => [
		{ label: "Details", icon: detailsIcon, activeIcon: activeDetailsIcon, name: "DETAILS", component: <AppSalesOrderDetails data={data} onHandleGetDetails={onHandleGetDetails} />, accessible: true },
		{ label: "Delivery Order", icon: deliveryOrderIcon, activeIcon: activeDeliveryOrderIcon, name: "DELIVERY_ORDER", component: <AppSalesOrderDeliveryOrder />, accessible: true },
		{ label: "Invoices", icon: invoiceIcon, activeIcon: activeInvoiceIcon, name: "INVOICE", component: <AppSalesOrderInvoice />, accessible: true },
	], [data, onHandleGetDetails]);

	const isEdit = useMemo(() => data.status === STATUS.DRAFT || data.status === STATUS.DRAFT_WITH_EXCEPTION, [data.status]);

	const label = isEdit ? "Edit Sales Order " : "View Sales Order ";

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Sales", path: pathnames.sales.quotations },
			{ label: "Sales Order", path: pathnames.sales.salesOrders },
			{ label: label + formik.values.referenceNo, path: pathnames.sales.salesOrderCreateEdit }
		];

		return data;
	}, [label, formik.values.referenceNo]);

	const title = useMemo(() => {
		const status = data.status;

		if (status === STATUS.DRAFT || status === STATUS.DRAFT_WITH_EXCEPTION) {
			return isEdit ? "Edit Sales Order" : "View Sales Order";
		}

		return "View Sales Order";
	}, [data.status, isEdit]);

	useBreadcrumb({ breadCrumb });

	const onHandleSetComplete = () => {
		setMenuAnchor(null);
	};

	const onHandleSetDropOff = () => {
		setMenuAnchor(null);
	};

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);
	}, []);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	const onHandleExportPDF = useCallback(async () => {
		let response = null;
		let fileName = "";

		try {
			const payload = { "sale-order-id": id };

			const transformResponse = (data, headers) => {
				fileName = headers?.["content-disposition"]?.split("inline; filename=")?.[1]?.split(";")?.[0];

				if (fileName) return data;

				try {
					const jsonResponse = JSON.parse(new TextDecoder().decode(data));

					if (jsonResponse) return jsonResponse;
				} catch (error) {
					return data;
				}
			};

			response = await api.post.salesOrders.generateSalesOrderPdf(payload, { transformResponse });
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const a = document.createElement("a");
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(new Blob([response]), { type: "application/octet-stream" });
			a.href = url;
			a.download = fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			}, 1000);
		}
	}, [id]);

	useEffect(() => {
		if (id) {
			onHandleGetDetails(id);
		}
	}, [id, onHandleGetDetails]);

	useEffect(() => {
		if (id) {
			cancelRequest(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.EXCLUSIVE_SITE_PIC);
		}
	}, [cancelRequest, id, onHandleGetDetails]);

	return (
		<div className="page-sales-order-create-edit">
			<div className="sales-order-create-edit">
				<div className="sales-order-create-edit__header">
					<h1 className="sales-order-create-edit__title">{title}</h1>

					<div className="sales-order-create-edit__header sales-order-create-edit__header--column">
						{!isDraft && (
							<div className="sales-order-create-edit__header-buttons">
								<AppButton outline className="sales-order-create-editt__action-button" type="button" label="Actions" icon={chevronIcon} onClick={onToggleFilterMenu} />

								<AppButton outline type="button" label="Export as PDF" icon={exportIcon} onClick={onHandleExportPDF} />
							</div>
						)}

						<p className="sales-order-create-edit__last-update">
							<span>Last Updated By</span> {formik.values.lastModifiedBy}, {formik.values.lastModifiedDate}
						</p>
					</div>
				</div>

				<div className="sales-order-create-edits__body">
					<AppTabs tabs={salesOrderTabs} />
				</div>
			</div>

			<Menu classes={{ root: "sales-order-create-edit-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleSetComplete}>Set As Complete with Exception</MenuItem>

				<MenuItem onClick={onHandleSetDropOff}>Set As Drop Off</MenuItem>
			</Menu>
		</div>
	);
};

export default PageSalesOrderCreateEdit;
