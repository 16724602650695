import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import api from "services/api";
import getWorkspaceListing from "services/get-workspace-listing";
import getAssetTypeListing from "services/get-asset-type-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

export const AppReportDetailsCreateEditModal = (props, ref) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const [visible, setVisible] = useState(false);
	const siteId = useMemo(() => props.siteId, [props.siteId]);
	const wirAssets = useMemo(() => props.wirAssets, [props.wirAssets]);
	const onHandleUpdateWIRAssets = useMemo(() => props.onHandleUpdateWIRAssets, [props.onHandleUpdateWIRAssets]);

	const initialValues = useMemo(() => {
		const values = {
			id: "",
			assetName: "",
			workspace: "",
			configWorkSpaceId: "",
			assetType: "",
			configAssetTypeId: "",
			customerAssetName: "",
			deleted: false
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			assetName: yup.string().required(ERRORS.REQUIRED),
			configWorkSpaceId: yup.string().required(ERRORS.REQUIRED),
			configAssetTypeId: yup.string().required(ERRORS.REQUIRED),
			customerAssetName: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const isUpdateAsset = useMemo(() => !formik.values.id, [formik.values.id]);
	const title = useMemo(() => (isUpdateAsset ? "Add Asset Type" : "Edit Asset Type"), [isUpdateAsset]);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({ configWorkSpaceId: obj?.configWorkSpaceId || "", customerAssetName: obj?.customerAssetName || "", assetName: obj?.assetName || "", configAssetTypeId: obj?.configAssetTypeId || "", id: obj?.id || "", });
		
		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		const wirAssetsCopy = structuredClone(wirAssets);

		try {
			if (isCreate) {
				const { id, ...remainder } = values;

				onHandleUpdateWIRAssets([...wirAssetsCopy, remainder]);
			}

			if (!isCreate) {
				let payload = {
					workInspectionId: id,
					customerContractSiteId: siteId,
					assets: [...wirAssetsCopy, values]
				};

				await api.post.workInspectionReport.assets(payload);
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Assets were added successfully!" }));

				props.onHandleGetPicTableList();
			}

			onHandleDismiss();
		}
	}, [dispatch, id, isCreate, onHandleDismiss, onHandleUpdateWIRAssets, props, siteId, wirAssets]);

	//prettier-ignore
	const onHandleSelectWorkSpace = useCallback(async (event) => {
		const value = event.target.value;

		let response = null;

		try {
			response = await api.get.customerConfiguration.workspace(value);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		formik.setFieldValue("configWorkSpaceId", event.target.value);
		formik.setFieldValue("workspace", response.name);
	}, [formik]);

	//prettier-ignore
	const onHandleSelectAssetType = useCallback(async (event) => {
		const value = event.target.value;

		let response = null;

		try {
			response = await api.get.customerConfiguration.assetType(value);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		formik.setFieldValue("configAssetTypeId", event.target.value);
		formik.setFieldValue("assetType", response.name);
	}, [formik]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-report-details-create-edit-modal" }} open={visible}>
			<div className="report-details-create-edit-modal">
				<h1 className="report-details-create-edit-modal__title">{title}</h1>

				<form className="report-details-create-edit-modal__form" onSubmit={formik.handleSubmit}>
					{/* prettier-ignore */}
					<AppInput required type="text" name="customerAssetName" label="Customer Asset Name" placeholder="Enter Customer Asset Name" value={formik.values.customerAssetName} error={formik.errors.customerAssetName} touched={formik.touched.customerAssetName} onChange={formik.handleChange} />

					{/* prettier-ignore */}
					<AppInput required type="text" name="assetName" label="Asset Name" placeholder="Enter Asset Name" value={formik.values.assetName} error={formik.errors.assetName} touched={formik.touched.assetName} onChange={formik.handleChange} />

					{/* prettier-ignore */}
					<AppSelectInput required pagination searchable={false} name="configWorkSpaceId" label="Workspace" placeholder="Select..." loadOptions={getWorkspaceListing} value={formik.values.configWorkSpaceId} error={formik.errors.configWorkSpaceId} touched={formik.touched.configWorkSpaceId} onChange={(e) => onHandleSelectWorkSpace(e)} />

					{/* prettier-ignore */}
					<AppSelectInput required pagination searchable={false} name="configAssetTypeId" label="Asset Type" placeholder="Select..." loadOptions={getAssetTypeListing} value={formik.values.configAssetTypeId} error={formik.errors.configAssetTypeId} touched={formik.touched.configAssetTypeId} onChange={(e) => onHandleSelectAssetType(e)} />

					<div className="report-details-create-edit-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Confirm" disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppReportDetailsCreateEditModal));

AppReportDetailsCreateEditModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
