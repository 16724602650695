import PageWorkOrder from "pages/work-order/page-work-order";
import PageWorkOrderAll from "pages/work-order/page-work-order-all";
import PageProjectWorkOrder from "pages/work-order/page-project-work-order";
import PageBreakdownWorkOrder from "pages/work-order/page-breakdown-work-order";
import PageCorrectiveWorkOrder from "pages/work-order/page-corrective-work-order";
import PagePreventiveWorkOrder from "pages/work-order/page-preventive-work-order";
import PageWorkOrderCreateEdit from "pages/work-order/page-work-order-create-edit";

import pathnames from "routes/pathnames";

const workOrderRoutes = [
	{
		path: pathnames.workOrder.breakdownWorkOrder,
		element: <PageBreakdownWorkOrder />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.workOrder.correctiveWorkOrder,
		element: <PageCorrectiveWorkOrder />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.workOrder.preventiveWorkOrder,
		element: <PagePreventiveWorkOrder />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.workOrder.workOrderCreateEdit + ":orderType/:id",
		element: <PageWorkOrderCreateEdit />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.workOrder.projectWorkOrder,
		element: <PageProjectWorkOrder />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.workOrder.workOrder + ":orderType",
		element: <PageWorkOrder />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.workOrder.workOrderAll,
		element: <PageWorkOrderAll />,
		authenticated: true,
		role: null
	}
];

export default workOrderRoutes;
