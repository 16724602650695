import React, { useCallback, useMemo } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { promptAlertMessage } from "store/slices/alert";

import pathnames from "routes/pathnames";

import api from "services/api";

import COMMON from "common/index";
import stringEncryptRSA from "common/string-encrypt-rsa";
import serveRequestErrors from "common/serve-request-errors";

import REGEX from "constants/regex";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCloseIcon from "components/icons/app-close-icon";
import AppPasswordChecklist from "components/app-password-checklist";

const AppUserChangePassword = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// prettier-ignore
	const initialValues = useMemo(() => {
		const data = { currentPassword: "", newPassword: "", confirmNewPassword: "" };

		return data;
	},[]);

	// prettier-ignore
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: yup.object({
			currentPassword: yup.string().required(ERRORS.REQUIRED),
			newPassword: yup.string().min(8, ERRORS.LENGTH).required(ERRORS.REQUIRED).matches(REGEX.UPPERCASE, ERRORS.REQUIRED).matches(REGEX.LOWERCASE, ERRORS.REQUIRED).matches(REGEX.SYMBOL, ERRORS.REQUIRED).matches(REGEX.NUMERIC, ERRORS.REQUIRED),
			confirmNewPassword: yup.string().required(ERRORS.REQUIRED).oneOf([yup.ref("newPassword")], ERRORS.PASSWORDS_MATCH),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			await api.post.profile.profile({ currentPassword: stringEncryptRSA(values.currentPassword), newPassword: stringEncryptRSA(values.newPassword) });

			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {

			localStorage.removeItem(COMMON.AUTH_TOKEN);
			localStorage.removeItem(COMMON.REFRESH_TOKEN);
			localStorage.removeItem(COMMON.ACCESSIBLE_PATH);
			sessionStorage.removeItem(COMMON.AUTH_TOKEN);
			sessionStorage.removeItem(COMMON.REFRESH_TOKEN);
	
			dispatch({ type: COMMON.REDUX_ACTION.LOGOUT });
	
			navigate(pathnames.login.login);

			dispatch(promptAlertMessage({ message: "Password has been updated" }));
		}
	}, [navigate, dispatch, formik]);

	const onHandleCancel = useCallback(() => {
		const redirectPath = localStorage.getItem(COMMON.ACCESSIBLE_PATH);

		navigate(redirectPath);
	}, [navigate]);

	return (
		<div className="app-user-change-password">
			<div className="user-change-password">
				<form className="user-change-password__form" onSubmit={formik.handleSubmit}>
					<div className="user-change-password__container">
						<div className="user-change-password__row">
							{/* prettier-ignore */}
							<AppInput required type="password" name="currentPassword" label="Current Password" placeholder="Current Password" value={formik.values.currentPassword} error={formik.errors.currentPassword} touched={formik.touched.currentPassword} onChange={formik.handleChange}/>
						</div>

						<div className="user-change-password__row">
							<div className="user-change-password__column">
								{/* prettier-ignore */}
								<AppInput required type="password" name="newPassword" label="New Password" placeholder="New Password" value={formik.values.newPassword} error={formik.errors.newPassword} touched={formik.touched.newPassword} onChange={formik.handleChange}/>

								<AppPasswordChecklist value={formik.values.newPassword} touched={formik.touched.newPassword} confirmNewPassword={formik.values.confirmNewPassword} />
							</div>

							{/* prettier-ignore */}
							<div className="user-change-password__column">
								<AppInput required type="password" name="confirmNewPassword" label="Confirm New Password" placeholder="Confirm New Password" value={formik.values.confirmNewPassword} error={formik.errors.confirmNewPassword} touched={formik.touched.confirmNewPassword} onChange={formik.handleChange}/>

								{formik.values.newPassword !== formik.values.confirmNewPassword && (
									<p className="user-change-password__description">
										<span className="user-change-password__icon">
											<AppCloseIcon color="#DC4C4C" width="12" height="12" viewBox="0 0 16 11" />
										</span>
										Passwords do not match
									</p>
								)}
							</div>
						</div>
					</div>

					<div className="user-change-password__button-container">
						<AppButton outline type="button" onClick={onHandleCancel} label="Cancel" />

						<AppButton type="submit" disabled={formik.isSubmitting} label="Confirm" />
					</div>
				</form>
			</div>
		</div>
	);
};

export default AppUserChangePassword;
