import { Fragment, forwardRef, memo, useCallback, useImperativeHandle, useMemo } from "react";

import * as yup from "yup";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FieldArray, FormikProvider, useFormik } from "formik";

import pathnames from "routes/pathnames";

import getStateListing from "services/get-state-listing";
import getGenderListing from "services/get-gender-listing";
import getRegionListing from "services/get-region-listing";
import getIdTypeListing from "services/get-id-type-listing";
import getPostcodeListing from "services/get-postcode-listing";
import getNationalityListing from "services/get-nationality-listing";
import getRelationshipListing from "services/get-relationship-listing";
import getMaritalStatusListing from "services/get-marital-status-listing";

import getOrdinalNumber from "common/get-ordinal-number";
import formatPassportString from "common/format-passport-string";

import REGEX from "constants/regex";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import MASKING from "constants/masking";
import IDENTITY_TYPE from "constants/identity-type";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppMaskingInput from "components/app-masking-input";

const AppPersonalInfo = (props, ref) => {
	const navigate = useNavigate();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.EMPLOYEES], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	//prettier-ignore
	const defaultField = useMemo(() => [
		{ fullName: "", relationship: "", mobileNo: "", mobileNoPrefix: "" },
		{ fullName: "", relationship: "", mobileNo: "", mobileNoPrefix: "" }
	], []);
	const defaultEmergencyContactProperties = ["fullName", "relationship", "mobileNo", "mobileNoPrefix"];

	//prettier-ignore
	const initialValues = useMemo(() => {
		let data = {
			fullName: "",
			identificationType: "",
			identificationNumber: "",
			passport: "",
			dateOfBirth: "",
			gender: "",
			nationality: "",
			maritalStatus: "",
			primaryMobileNo: "",
			primaryMobileNoPrefix: "",
			secondaryMobileNo: "",
			secondaryMobileNoPrefix: "",
			email: "",
			residentialAddress1: "",
			residentialAddress2: "",
			residentialPostcode: "",
			residentialState: "",
			residentialRegion: "",
			sameAsCorrespondenceAddress: true,
			correspondenceAddress1: "",
			correspondenceAddress2: "",
			correspondencePostcode: "",
			correspondenceState: "",
			correspondenceRegion: "",
			emergencyContact: defaultField,
		};

		if (props.personal) {
			const existingContacts = props.personal.emergencyContact || [];

    		const emergencyContact = existingContacts.concat(
				defaultField.slice(existingContacts.length)
			);
			
			data = {
				...props.personal,
				emergencyContact
			};
		}

		return data;
	}, [props.personal, defaultField]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: yup.object({
			fullName: yup.string().required(ERRORS.REQUIRED),
			identificationType: yup.string().required(ERRORS.REQUIRED),
			passport: yup.string().when(["identificationType"], {
				is: (type) => type === IDENTITY_TYPE.PASSPORT,
				then: () => yup.string().required(ERRORS.REQUIRED)
			}),
			identificationNumber: yup.string().when(["identificationType"], {
				is: (type) => type === IDENTITY_TYPE.NRIC,
				then: () => yup.string().required(ERRORS.REQUIRED)
			}),
			dateOfBirth: yup.date().required(ERRORS.REQUIRED),
			gender: yup.string().required(ERRORS.REQUIRED),
			nationality: yup.string().required(ERRORS.REQUIRED),
			maritalStatus: yup.string().required(ERRORS.REQUIRED),
			primaryMobileNo: yup.string().required(ERRORS.REQUIRED),
			email: yup.string().matches(REGEX.EMAIL, ERRORS.REQUIRED).required(ERRORS.REQUIRED),
			residentialAddress1: yup.string().when(["sameAsCorrespondenceAddress"], {
				is: (boolean) => !boolean,
				then: () => yup.string().required(ERRORS.REQUIRED)
			}),
			residentialPostcode: yup.string().when(["sameAsCorrespondenceAddress"], {
				is: (boolean) => !boolean,
				then: () => yup.string().required(ERRORS.REQUIRED)
			}),
			residentialState: yup.string().when(["sameAsCorrespondenceAddress"], {
				is: (boolean) => !boolean,
				then: () => yup.string().required(ERRORS.REQUIRED)
			}),
			residentialRegion: yup.string().when(["sameAsCorrespondenceAddress"], {
				is: (boolean) => !boolean,
				then: () => yup.string().required(ERRORS.REQUIRED)
			}),
			correspondenceAddress1: yup.string().required(ERRORS.REQUIRED),
			correspondencePostcode: yup.string().required(ERRORS.REQUIRED),
			correspondenceState: yup.string().required(ERRORS.REQUIRED),
			correspondenceRegion: yup.string().required(ERRORS.REQUIRED),
			// prettier-ignore
			emergencyContact: yup.array().of(
				yup.object().shape({
					fullName: yup.string(),
					relationship: yup.string(),
					mobileNo: yup.string(),
					mobileNoPrefix: yup.string()
				})
			).test("first-contact-required", ERRORS.REQUIRED, function (contacts) {
				if (!contacts || contacts.length === 0) {
					return this.createError({ path: "emergencyContact", message: ERRORS.REQUIRED });
				}

				const firstContact = contacts[0];
				const secondContact = contacts[1];
				const errors = {};
				const secondContactErrors = {};
				const partialPopulatedSecondContact = () => {
					if (!secondContact) return;

					if (Object.keys(secondContact)?.length === 1 && Object.keys(secondContact?.[0] === "mobileNoPrefix")) {
						return false;
					} else {
						return Object.keys(secondContact)?.length >= 1 && Object.keys(secondContact)?.length < defaultEmergencyContactProperties.length;
					}
				};

				defaultEmergencyContactProperties.forEach((key) => {
					if (!secondContact?.[key]) {
						return (secondContactErrors[key] = ERRORS.REQUIRED);
					}

					return;
				});

				if (!firstContact.fullName) {
					errors.fullName = ERRORS.REQUIRED;
				}

				if (!firstContact.relationship) {
					errors.relationship = ERRORS.REQUIRED;
				}

				if (!firstContact.mobileNo) {
					errors.mobileNo = ERRORS.REQUIRED;
				}

				if (!firstContact.mobileNoPrefix) {
					errors.mobileNoPrefix = ERRORS.REQUIRED;
				}

				if (Object.keys(errors).length > 0) {
					return this.createError({ path: "emergencyContact[0]", message: errors });
				} else if (partialPopulatedSecondContact()) {
					return this.createError({ path: "emergencyContact[1]", message: secondContactErrors });
				}

				return true;
			})
		}),
		onSubmit: (values) => {
			const nextEmergencyContact = values.emergencyContact.map((contact) => ({
				fullName: contact.fullName || "",
				relationship: contact.relationship || "",
				mobileNo: contact.mobileNo || "",
				mobileNoPrefix: contact.mobileNoPrefix || ""
			}));

			values = { ...values, emergencyContact: nextEmergencyContact };

			props.onSubmit(values);
		}
	});

	const getRegionLoadOptions = useCallback(() => getRegionListing({ state: formik.values.residentialState }), [formik.values.residentialState]);
	const getPostcodeLoadOptions = useCallback(() => getPostcodeListing(`${formik.values.residentialState}/${formik.values.residentialRegion}`), [formik.values.residentialState, formik.values.residentialRegion]);
	const getCorrespondenceRegionLoadOptions = useCallback(() => getRegionListing({ state: formik.values.correspondenceState }), [formik.values.correspondenceState]);
	const getCorrespondencePostcodeLoadOptions = useCallback(() => getPostcodeListing(`${formik.values.correspondenceState}/${formik.values.correspondenceRegion}`), [formik.values.correspondenceState, formik.values.correspondenceRegion]);

	const onHandleCancel = useCallback(() => {
		navigate(pathnames.humanResources.employeeMaintenance);
	}, [navigate]);

	//prettier-ignore
	const onHandleChangeIdType = useCallback((event) => {
		const value = event.target.value;

		if(value !== formik.values.identificationType) {
			formik.setFieldValue("identificationNumber", "");
			formik.setFieldValue("passport", "");
		}

		formik.handleChange(event);
	}, [formik]);

	//prettier-ignore
	const onHandleSelectAddressField = useCallback(async (event) => {
		const name = event.target.name;
		const value = event.target.value;

		switch (name) {
			case "correspondenceState":
				formik.setFieldValue("correspondenceRegion", "");
				formik.setFieldValue("correspondencePostcode", "");
				break;
			case "correspondenceRegion":
				formik.setFieldValue("correspondencePostcode", "");
				break;
			case "correspondencePostcode":
				break;
			case "residentialState":
				formik.setFieldValue("residentialRegion", "");
				formik.setFieldValue("residentialPostcode", "");
				break;
			case "residentialRegion":
				formik.setFieldValue("residentialPostcode", "");
				break;
			case "residentialPostcode":
				break;
			default:
				break;
		}

		formik.setFieldValue(name, value);
	}, [formik]);

	const IdentificationField = useCallback((obj) => {
		const identificationType = obj.values.identificationType;
		const isPassport = identificationType === "PASSPORT";

		if (!isPassport) {
			//prettier-ignore
			return <AppMaskingInput required type="number" name="identificationNumber" label="NRIC No." placeholder="e.g. 901010-14-1234" disabled={!identificationType} value={obj.values.identificationNumber} error={obj.errors.identificationNumber} touched={obj.touched.identificationNumber} onChange={obj.onChange} format={MASKING.NRIC} />
		}

		if (isPassport) {
			//prettier-ignore
			return <AppInput required type="text" name="passport" label="Passport" placeholder="e.g. VD1289281" disabled={!identificationType} value={obj.values.passport} error={obj.errors.passport} touched={obj.touched.passport} onFormat={formatPassportString} onChange={obj.onChange} />
		}
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleSubmit: formik.handleSubmit
	}));

	return (
		<FormikProvider value={formik}>
			<div className="app-personal-info">
				<div className="personal-info">
					<form className="personal-info__form" onSubmit={formik.handleSubmit}>
						<div className="personal-info__container">
							<div className="personal-info__label">General</div>

							<div className="personal-info__row">
								{/* prettier-ignore */}
								<AppInput disabled={restricted} name="fullName" type="text" required label="Full Name" placeholder="Enter Full Name" touched={formik.touched.fullName} error={formik.errors.fullName} value={formik.values.fullName} onChange={formik.handleChange} />
							</div>

							<div className="personal-info__row">
								{/* prettier-ignore */}
								<AppSelectInput searchable={false} disabled={restricted} name="identificationType" label="ID Type" placeholder="Select..." loadOptions={getIdTypeListing} value={formik.values.identificationType} error={formik.errors.identificationType} touched={formik.touched.identificationType} onChange={onHandleChangeIdType} />

								<IdentificationField disabled={restricted} values={formik.values} errors={formik.errors} touched={formik.touched} onChange={formik.handleChange} />
							</div>

							<div className="personal-info__row">
								{/* prettier-ignore */}
								<AppInputDate required disabled={restricted} name="dateOfBirth" label="Date of Birth"  placeholder="DD/MM/YYYY" value={formik.values.dateOfBirth} error={formik.errors.dateOfBirth} touched={formik.touched.dateOfBirth} onChange={formik.setFieldValue} />

								{/* prettier-ignore */}
								<AppSelectInput required disabled={restricted} searchable={false} name="gender" label="Gender" placeholder="Select..." loadOptions={getGenderListing} value={formik.values.gender} error={formik.errors.gender} touched={formik.touched.gender} onChange={formik.handleChange} />
							</div>

							<div className="personal-info__row personal-info__row--divider">
								{/* prettier-ignore */}
								<AppSelectInput required disabled={restricted} name="nationality" label="Nationality" placeholder="Select..." loadOptions={getNationalityListing} value={formik.values.nationality} error={formik.errors.nationality} touched={formik.touched.nationality} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppSelectInput required disabled={restricted} searchable={false} name="maritalStatus" label="Marital Status" placeholder="Select..." loadOptions={getMaritalStatusListing} value={formik.values.maritalStatus} error={formik.errors.maritalStatus} touched={formik.touched.maritalStatus} onChange={formik.handleChange} />
							</div>

							<div className="personal-info__label">Contact Info</div>

							<div className="personal-info__row">
								{/* prettier-ignore */}
								<AppMobileInput required disabled={restricted} type="number" name="primaryMobileNo" prefixNoName="primaryMobileNoPrefix" label="Primary Mobile Number" placeholder="eg: 12345678" value={formik.values.primaryMobileNo} prefixNo={formik.values.primaryMobileNoPrefix} error={formik.errors.primaryMobileNo} touched={formik.touched.primaryMobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

								{/* prettier-ignore */}
								<AppInput required disabled={restricted} type="text" name="email" label="Email" placeholder="Enter Email" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />
							</div>

							<div className="personal-info__row">
								{/* prettier-ignore */}
								<AppMobileInput disabled={restricted} type="number" name="secondaryMobileNo" prefixNoName="secondaryMobileNoPrefix" label="Secondary Mobile Number" placeholder="eg: 12345678" value={formik.values.secondaryMobileNo} prefixNo={formik.values.secondaryMobileNoPrefix} error={formik.errors.secondaryMobileNo} touched={formik.touched.secondaryMobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />
							</div>

							<div className="personal-info__label">Correspondence Address</div>

							<div className="personal-info__row">
								{/* prettier-ignore */}
								<AppInput required disabled={restricted} type="text" name="correspondenceAddress1" label="Address Line 1" placeholder="Address Line 1" value={formik.values.correspondenceAddress1} error={formik.errors.correspondenceAddress1} touched={formik.touched.correspondenceAddress1} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppInput disabled={restricted} type="text" name="correspondenceAddress2" label="Address Line 2" placeholder="Address Line 2" value={formik.values.correspondenceAddress2} error={formik.errors.correspondenceAddress2} touched={formik.touched.correspondenceAddress2} onChange={formik.handleChange} />
							</div>

							<div className="personal-info__row">
								{/* prettier-ignore */}
								<AppSelectInput required disabled={restricted} type="text" name="correspondenceState" label="State" placeholder="State" loadOptions={getStateListing} value={formik.values.correspondenceState} error={formik.errors.correspondenceState} touched={formik.touched.correspondenceState} onChange={onHandleSelectAddressField} />

								{/* prettier-ignore */}
								<AppSelectInput required type="text" name="correspondenceRegion" label="Region" placeholder="Region" loadOptions={getCorrespondenceRegionLoadOptions} disabled={!formik.values.correspondenceState || restricted} value={formik.values.correspondenceRegion} error={formik.errors.correspondenceRegion} touched={formik.touched.correspondenceRegion} onChange={onHandleSelectAddressField} />
							</div>

							<div className="personal-info__row">
								{/* prettier-ignore */}
								<AppSelectInput required type="text" name="correspondencePostcode" label="Postcode" placeholder="Postcode" loadOptions={getCorrespondencePostcodeLoadOptions} disabled={!formik.values.correspondenceRegion || restricted} value={formik.values.correspondencePostcode} error={formik.errors.correspondencePostcode} touched={formik.touched.correspondencePostcode} onChange={onHandleSelectAddressField} />
							</div>

							<div className="personal-info__label">Residential Address</div>

							<div className="personal-info__row  personal-info__row--correspondence-address">
								<AppCheckbox label="Same as Correspondence Address" value={formik.values.sameAsCorrespondenceAddress} onClick={(v) => formik.setFieldValue("sameAsCorrespondenceAddress", v)} />
							</div>

							{!formik.values.sameAsCorrespondenceAddress && (
								<Fragment>
									<div className="personal-info__row">
										{/* prettier-ignore */}
										<AppInput required disabled={restricted} type="text" name="residentialAddress1" label="Address Line 1" placeholder="Address Line 1" value={formik.values.residentialAddress1} error={formik.errors.residentialAddress1} touched={formik.touched.residentialAddress1} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppInput disabled={restricted} type="text" name="residentialAddress2" label="Address Line 2" placeholder="Address Line 2" value={formik.values.residentialAddress2} error={formik.errors.residentialAddress2} touched={formik.touched.residentialAddress2} onChange={formik.handleChange} />
									</div>

									<div className="personal-info__row">
										{/* prettier-ignore */}
										<AppSelectInput required disabled={restricted} type="text" name="residentialState" label="State" placeholder="State" loadOptions={getStateListing} value={formik.values.residentialState} error={formik.errors.residentialState} touched={formik.touched.residentialState} onChange={onHandleSelectAddressField} />

										{/* prettier-ignore */}
										<AppSelectInput required type="text" name="residentialRegion" label="Region" placeholder="State" loadOptions={getRegionLoadOptions} disabled={!formik.values.residentialState || restricted} value={formik.values.residentialRegion} error={formik.errors.residentialRegion} touched={formik.touched.residentialRegion} onChange={onHandleSelectAddressField} />
									</div>

									<div className="personal-info__row">
										{/* prettier-ignore */}
										<AppSelectInput required type="text" name="residentialPostcode" label="Postcode" placeholder="Postcode" loadOptions={getPostcodeLoadOptions} disabled={!formik.values.residentialRegion || restricted} value={formik.values.residentialPostcode} error={formik.errors.residentialPostcode} touched={formik.touched.residentialPostcode} onChange={onHandleSelectAddressField} />
									</div>
								</Fragment>
							)}

							<FieldArray
								name="emergencyContact"
								render={(f) => {
									const values = f.form.values?.emergencyContact;
									const errors = f.form.errors?.emergencyContact;
									const touched = f.form.touched?.emergencyContact;

									return (
										<Fragment>
											{values.map((o, i) => {
												const ordinalNumber = getOrdinalNumber(i + 1) + " Emergency Contact";
												const isFirstContact = i === 0;

												return (
													<Fragment key={i}>
														<p className="personal-info__label">{ordinalNumber}</p>

														<div className="personal-info__row">
															{/* prettier-ignore */}
															<AppInput required={isFirstContact} disabled={restricted} name={`emergencyContact.${i}.fullName`} type="text" label="Full Name" placeholder="Enter Full Name" touched={touched?.[i]?.fullName} error={errors?.[i]?.fullName} value={values[i].fullName} onChange={formik.handleChange} />

															{/* prettier-ignore */}
															<AppSelectInput required={isFirstContact} searchable={false} disabled={restricted} type="text" name={`emergencyContact.${i}.relationship`} label="Relationship" placeholder="Relationship" loadOptions={getRelationshipListing} error={errors?.[i]?.relationship} touched={touched?.[i]?.relationship} value={values[i].relationship} onChange={formik.handleChange} />
														</div>

														<div className="personal-info__row">
															{/* prettier-ignore */}
															<AppMobileInput required={isFirstContact} disabled={restricted} type="number" name={`emergencyContact.${i}.mobileNo`} prefixNoName={`emergencyContact.${i}.mobileNoPrefix`} label="Mobile Number" placeholder="eg: 12345678" value={values[i].mobileNo} prefixNo={values[i].mobileNoPrefix} error={errors?.[i]?.mobileNo} touched={touched?.[i]?.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />
														</div>
													</Fragment>
												);
											})}
										</Fragment>
									);
								}}
							/>
						</div>

						<div className="personal-info__button-container">
							<AppButton outline type="button" onClick={onHandleCancel} label="Cancel" />

							<AppButton type="submit" label="Next" disabled={formik.isSubmitting || restricted} />
						</div>
					</form>
				</div>
			</div>
		</FormikProvider>
	);
};

export default memo(forwardRef(AppPersonalInfo));
