import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import useBreadcrumb from "hooks/use-breadcrumb";
import useBeforeUnload from "hooks/use-before-unload";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppTabs from "components/app-tabs";
import AppCustomerEditAssetDetails from "components/pages/customer/app-customer-edit-asset-details";
import AppCustomerEditAssetServices from "components/pages/customer/app-customer-edit-asset-services";

import assetDetailsIcon from "assets/images/pages/customer/asset-details-icon.svg";
import assetServicesIcon from "assets/images/pages/customer/asset-services-icon.svg";
import assetDetailsActiveIcon from "assets/images/pages/customer/asset-details-active-icon.svg";
import assetServicesActiveIcon from "assets/images/pages/customer/asset-services-active-icon.svg";

const TABS = {
	ASSET_DETAILS: "ASSET_DETAILS",
	ASSET_SERVICES: "ASSET_SERVICES"
};

const PageCustomerEditAsset = (props, ref) => {
	useBeforeUnload();

	const { id } = useParams();
	const [assetDetails, setAssetDetails] = useState();
	const customerEditAssetDetailsRef = useRef();

	const profile = useSelector((state) => state.profile);
	const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	const breadCrumb = useMemo(() => {
		return [
			{ label: "Customer Listing ", path: pathnames.customer.customers },
			{ label: "Edit Site ", path: pathnames.customer.customerCreateEditSite + assetDetails?.customerContractSiteId },
			{ label: "Edit Asset", path: pathnames.customer.customerEditAsset + id }
		];
	}, [assetDetails?.customerContractSiteId, id]);

	useBreadcrumb({ breadCrumb });

	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.assetMaintenance.asset(uniqueId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			response.id = uniqueId;

			setAssetDetails(response);
		}
	}, []);

	//prettier-ignore
	const AssetTabs = useMemo(() => [
		{ label: "Asset Details", icon: assetDetailsIcon, activeIcon: assetDetailsActiveIcon, name: TABS.ASSET_DETAILS, component: <AppCustomerEditAssetDetails ref={customerEditAssetDetailsRef} assetDetails={assetDetails} onHandleGetDetails={onHandleGetDetails} />, accessible: profile?.permissions?.[ROLES.CUSTOMER_CONTRACT]?.view },
		{ label: "Asset Services", icon: assetServicesIcon, activeIcon: assetServicesActiveIcon, name: TABS.ASSET_SERVICES, component: <AppCustomerEditAssetServices customerContractSiteId={assetDetails?.customerContractSiteId} />, accessible: profile?.permissions?.[ROLES.CUSTOMER_CONTRACT]?.view }
	], [assetDetails, onHandleGetDetails, profile?.permissions]);

	useEffect(() => {
		onHandleGetDetails(id);

		return () => {
			if (id) {
				memoCancelRequest(ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET);
			}
		};
	}, [id, memoCancelRequest, onHandleGetDetails]);

	return (
		<div className="page-customer-edit-asset">
			<div className="customer-edit-asset">
				<div className="customer-edit-asset__header">
					<h1 className="customer-edit-asset__title">Edit Asset</h1>

					<p className="customer-edit-asset__last-update">
						<span>Last Updated By</span> {assetDetails?.lastModifiedByName}, {dayjs(assetDetails?.lastModifiedDate).format(DATE_TIME.DD_MM_YYYY_HH_MM_A)}
					</p>
				</div>

				<AppTabs tabs={AssetTabs} />
			</div>
		</div>
	);
};

export default PageCustomerEditAsset;
