import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";
import getRolesListing from "services/get-roles-listing";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ROLES from "constants/roles";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppSelectInput from "components/app-select-input";
import AppTableFilterHeader from "components/app-table-filter-header";

import editIcon from "assets/images/edit-icon.png";

const AppUserListingTable = () => {
	const isMounted = useRef(true);
	const navigate = useNavigate();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.USER_ACCESS], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const defaultStatus = useMemo(() => [STATUS.ACTIVE, STATUS.INACTIVE].join(","), []);
	//prettier-ignore
	const queryParams = useRef({ page: 0, value: searchParams.get("value") || "", "role-id": searchParams.get("role-id") || "", "start-date": searchParams.get("start-date") || "", "end-date": searchParams.get("end-date") || "", status: searchParams.get("status") || defaultStatus, tab: "USER_LISTING", });
	const [data, setData] = useState(convertPaginationTableData());

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.userAccess.users(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	const formik = useFormik({
		initialValues: { value: "", "role-id": "", "start-date": "", "end-date": "", status: [STATUS.ACTIVE, STATUS.INACTIVE] },
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current["start-date"] = dayjs(values["start-date"]).isValid() ? dayjs(values["start-date"]).format('YYYY-MM-DD HH:mm:ss') : "";
		queryParams.current["end-date"] = dayjs(values["end-date"]).isValid() ? dayjs(values["end-date"]).format('YYYY-MM-DD HH:mm:ss') : "";
		queryParams.current.status = values.status.join(",");
		queryParams.current["role-id"] = values["role-id"];

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.value = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current["role-id"] = "";
		queryParams.current["start-date"] = "";
		queryParams.current["end-date"] = "";
		queryParams.current.status = defaultStatus;

		onHandleGetList();
	}, [formik, defaultStatus, onHandleGetList]);

	// prettier-ignore
	const onHandleUserEdit = useCallback((tableMeta) => {
		const id = data.content[tableMeta.rowIndex]?.id;

		navigate(pathnames.operations.userAccessEditUser + id + "?tab=INFORMATION");
	}, [data, navigate]);

	const onHandleAddUser = useCallback(() => {
		navigate(pathnames.operations.userAccessCreateUser);
	}, [navigate]);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter(o => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Active", name: STATUS.ACTIVE },
			{ label: "Inactive", name: STATUS.INACTIVE }
		];

		return data;
	}, []);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
		formik.setFieldValue("start-date", queryParams.current.startDate);
		formik.setFieldValue("end-date", queryParams.current.endDate);
		formik.setFieldValue("role-id", queryParams.current.roleId);
	}, [formik]);

	// prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false,
			},
		},
		{
			name: "fullName",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "userId",
			label: "User ID",
			options: {
				sort: false,
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => <AppStatus status={value} />
			},
		},
		{
			name: "staffUserRoles",
			label: "Role(s)",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const roles = value.map((o) => o.roleNameEn).toString();
					
					return roles;
				},
			},
		},
		{
			name: "createdDate",
			label: "Created Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY_HH_MM_A),
			},
		},
		{
			name: "createdBy",
			label: "Created By",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const item = data.content[tableMeta.rowIndex];
					const roles = item.staffUserRoles.map((o) => o.code);
					const isSuperAdmin = roles.includes(ROLES.SUPER_ADMINISTRATOR);

					if(isSuperAdmin) return null;
					
					return (
						<button type="button" className="table__action" onClick={() => onHandleUserEdit(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [data, onHandleUserEdit]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch(action) {
				case "changePage":
					queryParams.current.page = tableState.page;
					
					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) } 

					onHandleGetList();
					break;
				default:
					break;    
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		if (isMounted.current) {
			isMounted.current = false;

			let status = searchParams.get("status");
			let startDate = dayjs(searchParams.get("start-date"));
			let endDate = dayjs(searchParams.get("end-date"));
			let roleId = searchParams.get("role-id");

			const nextFormValues = {};

			if (status) {
				status = status.split(",");

				nextFormValues.status = status;
			}

			if (dayjs(startDate).isValid()) {
				nextFormValues["start-date"] = startDate;
			}

			if (dayjs(endDate).isValid()) {
				nextFormValues["end-date"] = endDate;
			}

			if (roleId) {
				nextFormValues["role-id"] = roleId;
			}

			if (Object.keys(nextFormValues).length) {
				memoSetFormValues(nextFormValues);
			}
		}
	}, [isMounted, memoSetFormValues, searchParams]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.USER_ACCESS.USERS);
		};
	}, [cancelRequest]);

	return (
		<div className="app-user-listing-table">
			<div className="user-listing-table">
				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by User ID or Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} restricted={restricted} onHandleAdd={onHandleAddUser} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="user-listing-table-filter">
						{/* prettier-ignore */}
						<AppSelectInput searchable={false} type="text" name="role-id" label="Role" placeholder="Select..." loadOptions={getRolesListing} value={formik.values["role-id"]} error={formik.errors["role-id"]} touched={formik.touched["role-id"]} onChange={formik.handleChange} />

						<div className="user-listing-table-filter__container">
							{/* prettier-ignore */}
							<AppInputDate name="startDate" label="Date" value={formik.values.startDate} onChange={formik.setFieldValue} placeholder="Select start date" />

							{/* prettier-ignore */}
							<AppInputDate name="endDate" value={formik.values.endDate} onChange={formik.setFieldValue} placeholder="Select end date" />

							<p className="user-listing-table-filter__instruction">Please select a date or a date range of up to 90 days.</p>
						</div>

						<div className="user-listing-table-filter__container">
							<p className="user-listing-table-filter__label">Status</p>

							{menuFilterStatus.map((o) => {
								const isActive = formik.values.status.findIndex((i) => i === o.name) > -1;

								return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
							})}
						</div>
					</div>
				</AppTableFilterHeader>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>
		</div>
	);
};

export default AppUserListingTable;
