import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import useBreadcrumb from "hooks/use-breadcrumb";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ENDPOINT_PATH from "constants/end-point-path";

import AppTabs from "components/app-tabs";
import AppStatus from "components/app-status";
import AppViewEmployeeLeaveHistoryTable from "components/pages/human-resources/app-view-employee-leave-history-table";
import AppViewEmployeeLeaveSummaryTable from "components/pages/human-resources/app-view-employee-leave-summary-table";
import AppViewEmployeePendingLeaveTable from "components/pages/human-resources/app-view-employee-pending-leave-table";

import clockIcon from "assets/images/clock-icon.svg";
import activeClockIcon from "assets/images/clock-icon blue.svg";
import hourglassIcon from "assets/images/pages/human-resources/hourglass-icon.svg";
import leaveApproveIcon from "assets/images/pages/human-resources/leave-approval-icon.svg";
import activeHourglassIcon from "assets/images/pages/human-resources/hourglass-active-icon.svg";
import activeLeaveApproveIcon from "assets/images/pages/human-resources/leave-approval-active-icon.svg";

const PageViewEmployeeLeave = () => {
	const { id } = useParams();
	const profile = useSelector((state) => state.profile);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [isHR, setIsHR] = useState();
	const [employeeId, setEmployeeId] = useState();

	const formik = useFormik({
		initialValues: { employeeName: "", employeeId: "", todayStatus: "" }
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		const params = sanitizeObject({
			"employee-id": uniqueId
		});

		try {
			response = await api.get.humanResource.employeeLeaveSummary(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				employeeName: response.employeeName,
				employeeId: response.employeeId,
				todayStatus: response.todayStatus
			});
		}
	}, [memoSetFormValues]);

	const onHandleGetEmployeeDetail = useCallback(async (uniqueId) => {
		let response = null;

		try {
			const params = sanitizeObject({ "user-id": uniqueId });

			response = await api.get.humanResource.employeeHR(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setIsHR(!response.supervisor && !response.reportingManager);

			setEmployeeId(response.id);
		}
	}, []);

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Leave Management", path: pathnames.humanResources.leaveManagement },
			{ label: "All Leave", path: pathnames.humanResources.leaveManagement + "?tab=EMPLOYEE_DIRECTORY" },
			{ label: `${formik.values.employeeId} ${formik.values.employeeName} Leave`, path: pathnames.humanResources.viewEmployeeLeave + id }
		];

		return data;
	}, [formik.values, id]);

	useBreadcrumb({ breadCrumb });

	//prettier-ignore
	const viewEmployeeLeaveTabs = useMemo(() => [
		{ label: "Leave Summary", icon: hourglassIcon, activeIcon: activeHourglassIcon, name: "LEAVE_SUMMARY", component: <AppViewEmployeeLeaveSummaryTable />, accessible: true },
		{ label: "Leave History", icon: clockIcon, activeIcon: activeClockIcon, name: "LEAVE_HISTORY", component: <AppViewEmployeeLeaveHistoryTable />, accessible: true },
		{ label: "Pending Leave", icon: leaveApproveIcon, activeIcon: activeLeaveApproveIcon, name: "PENDING_LEAVE", component: <AppViewEmployeePendingLeaveTable isHR={isHR} employeeId={employeeId} />, accessible: true }
	], [employeeId, isHR]);

	useEffect(() => {
		onHandleGetDetails(id);

		onHandleGetEmployeeDetail(profile.id);
	}, [id, onHandleGetDetails, onHandleGetEmployeeDetail, profile.id]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_LEAVE_SUMMARY);

			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_HR);
		};
	}, [cancelRequest]);

	return (
		<div className="page-view-employee-leave">
			<div className="view-employee-leave">
				<div className="view-employee-leave__header">
					<h1 className="view-employee-leave__title">{formik.values.employeeName}</h1>

					<AppStatus status={formik.values.todayStatus} />
				</div>

				<p className="view-employee-leave__id">{formik.values.employeeId}</p>

				<div className="view-employee-leave__body">
					<AppTabs tabs={viewEmployeeLeaveTabs} />
				</div>
			</div>
		</div>
	);
};

export default PageViewEmployeeLeave;
