import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ENDPOINT_PATH from "constants/end-point-path";

import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import editIcon from "assets/images/edit-icon.png";

const AppLeavePackagesTable = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const [data, setData] = useState(convertPaginationTableData());
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "", tab: "LEAVE_PACKAGES" });
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ROLE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	//prettier-ignore
	const onHandleEdit = useCallback((tableMeta) => {
		const id = data.content[tableMeta.rowIndex]?.id;

		navigate(pathnames.humanResources.leavePackageCreateEdit + id);
	}, [data, navigate]);

	const onHandleCreate = () => {
		navigate(pathnames.humanResources.leavePackageCreateEdit + PAGE.CREATE);
	};

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.humanResource.leavePackages(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.name = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const tableColumns = [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "name",
			label: "Package Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "description",
			label: "Description",
			options: {
				sort: false
			}
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleEdit(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	];

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };
					
					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.LEAVE_PACKAGES);
		};
	}, [cancelRequest]);

	return (
		<div className="app-leave-packages-table">
			<div className="leave-packages-table">
				<AppTableFilterHeader searchPlaceholder="Search by Package Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} restricted={restricted} onHandleAdd={onHandleCreate} />

				<div className="leave-packages-table__body">
					<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
				</div>
			</div>
		</div>
	);
};

export default AppLeavePackagesTable;
