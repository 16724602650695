import React, { useCallback, useMemo, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Menu, MenuItem, Table, TableBody, TableCell, TableRow } from "@mui/material";

import pathnames from "routes/pathnames";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";

import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";

import editIcon from "assets/images/edit-icon.png";
import trashIcon from "assets/images/trash-icon.png";
import qrBlueIcon from "assets/images/qr-blue-icon.svg";
import qrWhiteIcon from "assets/images/qr-white-icon.svg";
import moreIcon from "assets/images/vertical-breadcrumbs-icon.png";

const AppCustomerAssetListTable = (props) => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_CONTRACT_SITE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const selectedItem = useRef();
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [selectedPending, setSelectedPending] = useState([]);
	const data = useMemo(() => props.data, [props.data]);
	const appQrIcon = useMemo(() => (restricted ? qrWhiteIcon : qrBlueIcon), [restricted]);
	const queryParams = useRef({ page: 0 });
	const downloadingFile = useRef(false);

	const onHandleSelectPending = useCallback((boolean, idNo) => {
		setSelectedPending((prev) => {
			let nextPending = [...prev];

			if (boolean) {
				nextPending.push(idNo);
			} else {
				nextPending = nextPending.filter((id) => id !== idNo);
			}

			return nextPending;
		});
	}, []);

	// prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		const ids = data.content.map((item) => item.id);

		setSelectedPending(boolean ? ids : []);
	}, [data]);

	// prettier-ignore
	const onHandleEditAsset = useCallback(() => {
		navigate(pathnames.customer.customerEditAsset + selectedItem.current.id);
	}, [navigate]);

	//prettier-ignore
	const onHandleDeleteAsset = useCallback(async () => {
		let response = null;

		try {
			await api.post.assetMaintenance.deleteAsset({ "asset-id": selectedItem.current.id });

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} 

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Asset was deleted successfully!" }));

			props.onHandleGetAssetList(id, { page: 0 });

			setMenuAnchor(false);
		}
	}, [id, props, dispatch]);

	const onHandleDownloadQRFile = useCallback(async () => {
		let response = null;

		if (selectedPending.length) {
			if (downloadingFile.current) return;

			downloadingFile.current = true;

			try {
				const payload = [...selectedPending];

				response = await api.post.assetMaintenance.generateAssetPdf(payload);
			} catch (error) {
				serveLayoutRequestErrors(error);
			} finally {
				downloadingFile.current = false;
			}

			if (response) {
				const url = response.fileUrl;
				const a = document.createElement("a");
				document.body.appendChild(a);

				a.href = url;
				a.download = response.fileName;
				a.click();

				setTimeout(() => {
					window.URL.revokeObjectURL(url);
					document.body.removeChild(a);
				}, 0);
			}
		}
	}, [selectedPending]);

	const onHandleCloseMenu = useCallback(() => {
		selectedItem.current = null;

		setMenuAnchor(null);
	}, []);

	//prettier-ignore
	const onToggleTableAction = useCallback((event, tableMeta) => {
		selectedItem.current = { ...data.content[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		setMenuAnchor(event.currentTarget);
	}, [data]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "checkbox",
			options: {
				sort: false,
				customHeadRender: () => {
					const selectedAll = data.content?.length > 0 && selectedPending.length === data.content?.length;

					return (
						<TableCell key="table-key" className="app-table__cell app-table__cell--header">
							<AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />

							{!!selectedPending.length && <div className="app-table__cell app-table__cell--header-text">{selectedPending.length} Record(s) Selected</div>}
						</TableCell>
					);
				},
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tablePendingID = data.content[rowIndex]?.id;
					const selected = selectedPending.includes(tablePendingID);

					return (
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className="app-table__cell">
										<AppCheckbox className="checkbox" onClick={(v) => onHandleSelectPending(v, tablePendingID)} value={selected} />
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					);
				}
			}
		},
		{
			name: "customerAssetName",
			label: "Customer Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "assetName",
			label: "Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "assetType",
			label: "Asset Type",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "frequency",
			label: "Frequency",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "lastService",
			label: "Last Service",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => value || "-"
			}
		},
		{
			name: "workspace",
			label: "Workspace",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "action",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={(event) => onToggleTableAction(event, tableMeta)}>
							<img src={moreIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [data, onHandleSelectAll, onHandleSelectPending, onToggleTableAction, selectedPending]);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					props.onHandleGetAssetList(id, queryParams.current);
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					props.onHandleGetAssetList(id, queryParams.current);
					break;
				default:
					break;
			}
		}
	}), [data, id, props]);

	return (
		<div className="app-customer-asset-list-table">
			<div className="customer-asset-list-table">
				<div className="customer-asset-list-table__header">
					<p className="customer-asset-list-table__title">Asset List</p>

					<div className="customer-asset-list-table__button">{!!selectedPending.length && <AppButton outline type="button" disabled={restricted} label="Generate QR" icon={appQrIcon} onClick={onHandleDownloadQRFile} />}</div>
				</div>

				<AppTable data={data?.content || []} columns={tableColumns} options={tableOptions} />
			</div>

			<Menu classes={{ root: "customer-asset-list-table-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleEditAsset}>
					<img src={editIcon} alt="edit-asset" />
					Edit Asset
				</MenuItem>

				<MenuItem onClick={onHandleDeleteAsset}>
					<img src={trashIcon} alt="delete-asset" />
					Delete Asset
				</MenuItem>
			</Menu>
		</div>
	);
};

export default AppCustomerAssetListTable;
