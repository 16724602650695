import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { TableCell } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppIcon from "components/app-icon";
import AppStatus from "components/app-status";
import AppTable from "components/app-table/app-table";
import AppCorrectiveAttendanceViewEmployeeModal from "components/pages/human-resources/app-corrective-attendance-view-employee-modal";

import eyeIcon from "assets/images/eye-open-icon.png";
import chevronIcon from "assets/images/chevron-right-icon.png";

const AppCorrectiveAttendanceHistoryEmployeeTable = () => {
	const { userId } = useParams();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const [data, setData] = useState(convertPaginationTableData());
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", tab: "CORRECTIVE_ATTENDANCE_HISTORY" });
	const correctiveAttendanceRef = useRef();

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10, "user-id": userId });

			memoSearchParams.current(params);

			response = await api.get.humanResource.correctiveAttendanceHistory(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, [userId]);

	//prettier-ignore
	const onHandleViewCorrectiveAttendance = useCallback((obj) => {
		correctiveAttendanceRef.current.onHandleShow(data.content[obj.rowIndex]);
	}, [data]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "attendanceRecordSignature.correctiveDate",
			label: "Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			}
		},
		{
			name: "clockIn",
			label: "Previous Clock In",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.H_MM_A)
			}
		},
		{
			name: "symbol",
			options: {
				sort: false,
				customHeadRender: () => {
					return (
						<TableCell key="table-key-clock-in" className="app-table__cell app-table__cell--header">
							<AppIcon src={chevronIcon} />
						</TableCell>
					);
				},
				customBodyRender: () => <AppIcon src={chevronIcon} />
			}
		},
		{
			name: "clockInCorrective",
			label: "Corrected Clock In",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.H_MM_A)
			}
		},
		{
			name: "clockOut",
			label: "Previous Clock Out",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.H_MM_A)
			}
		},
		{
			name: "symbol",
			options: {
				sort: false,
				customHeadRender: () => {
					return (
						<TableCell key="table-key-clock-out" className="app-table__cell app-table__cell--header">
							<AppIcon src={chevronIcon} />
						</TableCell>
					);
				},
				customBodyRender: () => <AppIcon src={chevronIcon} />
			}
		},
		{
			name: "clockOutCorrective",
			label: "Corrected Clock Out",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.H_MM_A)
			}
		},
		{
			name: "attendanceRecordSignature.approvedBy",
			label: "Approved By",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const status = data.content[tableMeta.rowIndex].status;
					const reviewedBy = status === STATUS.APPROVED ? data.content[tableMeta.rowIndex].attendanceRecordSignature.approvedByName : data.content[tableMeta.rowIndex].attendanceRecordSignature.rejectedByName;

					return reviewedBy;
				}
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: false,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__view" onClick={() => onHandleViewCorrectiveAttendance(tableMeta)}>
							<img src={eyeIcon} alt="view-icon" />
						</button>
					);
				}
			}
		}
	], [data.content, onHandleViewCorrectiveAttendance]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.CORRECTIVE_ATTENDANCE_HISTORY);
		};
	}, [cancelRequest]);

	return (
		<div className="app-corrective-attendance-history-employee-table">
			<div className="corrective-attendance-history-employee-table">
				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>

			<AppCorrectiveAttendanceViewEmployeeModal ref={correctiveAttendanceRef} />
		</div>
	);
};

export default AppCorrectiveAttendanceHistoryEmployeeTable;
