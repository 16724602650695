import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import AppButton from "components/app-button";

export const AppInvoiceVoidModal = (props, ref) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [data, setData] = useState({});
	const [visible, setVisible] = useState(false);
	const onHandleGetDetails = useMemo(() => props.onHandleGetDetails, [props.onHandleGetDetails]);

	const onHandleSubmit = useCallback(async () => {
		let response = null;

		try {
			response = await api.post.invoice.invoiceVoid(id);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			onHandleGetDetails(id);

			dispatch(promptLayoutAlertMessage({ message: "Invoice has been successfully voided!" }));

			setVisible(false);
		}
	}, [dispatch, id, onHandleGetDetails]);

	const onHandleShow = useCallback((value) => {
		setData(value);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-invoice-void-modal" }} open={visible}>
			<div className="invoice-void-modal">
				<h1 className="invoice-void-modal__title">Confirm Void Invoice?</h1>

				<p className="invoice-void-modal__description">
					After clicking confirm, the status for Invoice {data?.referenceNo} will be set to <span>Void</span>.
				</p>

				<div className="invoice-void-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="button" label="Confirm" onClick={onHandleSubmit} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInvoiceVoidModal));

AppInvoiceVoidModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
