import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import editIcon from "assets/images/edit-icon.png";

const AppRoleManagementTable = () => {
	const navigate = useNavigate();
	const isMounted = useRef(true);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ROLE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const defaultStatus = useMemo(() => [STATUS.ACTIVE, STATUS.INACTIVE].join(","), []);
	const queryParams = useRef({ page: 0, roleName: searchParams.get("roleName") || "", status: searchParams.get("status") || defaultStatus, tab: "ROLE_MANAGEMENT" });
	const [data, setData] = useState(convertPaginationTableData());

	const formik = useFormik({
		initialValues: { status: [STATUS.ACTIVE, STATUS.INACTIVE] },
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.userAccess.roles(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.roleName = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleEditRole = useCallback((tableMeta) => {        
		const id = data.content[tableMeta.rowIndex]?.id;

		navigate(pathnames.operations.userAccessCreateEditRole + id);
	}, [data, navigate]);

	const onHandleCreateRole = useCallback(() => {
		navigate(pathnames.operations.userAccessCreateEditRole + PAGE.CREATE);
	}, [navigate]);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter(o => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Active", name: STATUS.ACTIVE },
			{ label: "Inactive", name: STATUS.INACTIVE }
		];

		return data;
	}, []);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
	}, [formik]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.status = defaultStatus;

		onHandleGetList();
	}, [formik, defaultStatus, onHandleGetList]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false,
			},
		},
		{
			name: "roleNameEn",
			label: "Role",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "descriptionEn",
			label: "Description",
			options: {
				sort: false,
			},
		},
		{
			name: "noOfMembers",
			label: "User(s)",
			options: {
				sort: false,
			},
		},
		{
			name: "createdDate",
			label: "Created Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY_HH_MM_A),
			},
		},
		{
			name: "createdBy",
			label: "Created By",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleEditRole(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [onHandleEditRole]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch(action) {
				case "changePage":
					queryParams.current.page = tableState.page;
					
					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) } 

					onHandleGetList();
					break;
				default:
					break;    
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		if (isMounted.current) {
			isMounted.current = false;

			let status = searchParams.get("status");

			if (status) {
				status = status.split(",");

				memoSetFormValues({ status });
			}
		}
	}, [isMounted, memoSetFormValues, searchParams]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.USER_ACCESS.ROLES);
		};
	}, [cancelRequest]);

	return (
		<div className="app-role-management-table">
			<div className="role-management-table">
				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Role" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} restricted={restricted} onHandleAdd={onHandleCreateRole} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="role-management-table-filter">
						<div className="role-management-table-filter__container">
							<p className="role-management-table-filter__label">Status</p>

							{menuFilterStatus.map((o) => {
								const isActive = formik.values.status.findIndex((i) => i === o.name) > -1;

								return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
							})}
						</div>
					</div>
				</AppTableFilterHeader>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>
		</div>
	);
};

export default AppRoleManagementTable;
