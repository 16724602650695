import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import COMMON from "common";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import { setProfile } from "store/slices/profile";

import classNames from "common/class-names";
import secondsToTime from "common/seconds-to-time";
import roleAccessible from "common/role-accessible";
import serveRequestErrors from "common/serve-request-errors";
import getTimerServiceWorker from "common/get-timer-service-worker";
import findFirstAccessiblePath from "common/find-first-accessible-path";

import STATUS from "constants/status";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";

import closeModal from "assets/images/close-icon.png";

export const OtpVerificationModal = (props, ref) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState("");
	const otpFields = useRef([]);
	const [visible, setVisible] = useState(false);
	const [invalidOtp, setInvalidOtp] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [otp, setOtp] = useState(["", "", "", "", "", ""]);

	const timerServiceWorker = useRef();
	const totalSeconds = useMemo(() => parseInt(process.env.REACT_APP_TIMER_MINS * 60) + parseInt(process.env.REACT_APP_TIMER_SECS), []);
	const [timer, setTimer] = useState(totalSeconds);
	const counter = useMemo(() => secondsToTime(timer), [timer]);
	const isEndCounter = useMemo(() => timer === 0, [timer]);

	//prettier-ignore
	const errorClassName = useMemo(() => classNames({
		"otp-verification-modal__error": true,
		"otp-verification-modal__error--hide": !invalidOtp,
	}), [invalidOtp]);

	const timerInterval = useRef();

	const onHandleResendOtp = useCallback(async () => {
		let response = null;

		try {
			response = await api.post.account.otpResend();
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			timerServiceWorker.current.postMessage({ turn: "on" });
		}
	}, []);

	//prettier-ignore
	const onHandleChange = useCallback((index, event) => {
		const value = event.target.value;

		const nextOtp = [...otp];
		
		nextOtp[index] = value;

		setOtp(nextOtp);

		if (value && index < otpFields.current.length - 1) {
			otpFields.current[index + 1].focus();
		}
	}, [otp]);

	const onHandleGetProfile = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.profile.profile();
		} catch (error) {
			serveRequestErrors(error);

			localStorage.removeItem(COMMON.AUTH_TOKEN);
			localStorage.removeItem(COMMON.REFRESH_TOKEN);
			localStorage.removeItem(COMMON.ACCESSIBLE_PATH);
			sessionStorage.removeItem(COMMON.AUTH_TOKEN);
			sessionStorage.removeItem(COMMON.REFRESH_TOKEN);
		}

		if (response) {
			dispatch(setProfile(response));

			const { permissions } = roleAccessible(response.roles);

			if (response.forceChangePassword === STATUS.FORCE_CHANGE_PASSWORD) {
				navigate(pathnames.userAccount + "?tab=RESET-PASSWORD");
			} else {
				const firstAccessiblePath = findFirstAccessiblePath(permissions);

				if (firstAccessiblePath) {
					localStorage.setItem(COMMON.ACCESSIBLE_PATH, firstAccessiblePath);

					navigate(firstAccessiblePath);
				} else {
					navigate("*");
				}
			}
		}
	}, [dispatch, navigate]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (event) => {
		let response = null;
		const stringOtp = otp.join('');

		setIsSubmitting(true);

		try{
			await api.post.account.otpCodeVerify({ otpCode: stringOtp });

			response = true;
		}catch(error){
			serveRequestErrors(error);

			event.preventDefault();

			setInvalidOtp(true);
		} finally {
			setIsSubmitting(false);
		}
		
		if(response){
			onHandleGetProfile()
		}
	}, [onHandleGetProfile, otp]);

	//prettier-ignore
	const onHandleShow = useCallback((values) => {
		setEmail(values.email);


		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setOtp(["", "", "", "", "", ""]);

		clearInterval(timerInterval.current);

		localStorage.removeItem(COMMON.AUTH_TOKEN);
		localStorage.removeItem(COMMON.REFRESH_TOKEN);
		localStorage.removeItem(COMMON.ACCESSIBLE_PATH);
		sessionStorage.removeItem(COMMON.AUTH_TOKEN);
		sessionStorage.removeItem(COMMON.REFRESH_TOKEN);

		props.setSubmitting(false);

		setVisible(false);
	}, [props]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	useEffect(() => {
		const timerServiceWorkerJs = getTimerServiceWorker(totalSeconds);
		timerServiceWorker.current = new Worker(timerServiceWorkerJs);

		timerServiceWorker.current.postMessage({ turn: "on" });

		timerServiceWorker.current.onmessage = ({ data: { totalMilliseconds } }) => {
			if (totalMilliseconds <= 0) {
				setTimer(0);

				timerServiceWorker.current.postMessage({ turn: "off" });
			} else {
				setTimer(totalMilliseconds);
			}
		};

		return () => timerServiceWorker.current.postMessage({ turn: "off" });
	}, [totalSeconds]);

	return (
		<Modal classes={{ root: "app-otp-verification-modal" }} open={visible}>
			<div className="otp-verification-modal">
				<button className="otp-verification-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeModal} />
				</button>

				<h1 className="otp-verification-modal__title">Verification</h1>

				<p className="otp-verification-modal__description">Please enter the OTP sent to {email}</p>

				<form className="otp-verification-modal__form" action="">
					<div className="otp-verification-modal__inputs">
						{otp.map((field, i) => (
							<AppInput className="otp-verification-modal__input" key={i} name={field} type="number" maxLength={1} ref={(ref) => (otpFields.current[i] = ref)} onChange={(event) => onHandleChange(i, event)} />
						))}
					</div>

					<p className={errorClassName}>Invalid OTP</p>

					<AppButton type="submit" label="Next" onClick={onHandleSubmit} disabled={isSubmitting} />
				</form>

				<div className="otp-verification-modal__resend-code">
					{!isEndCounter && <p className="otp-verification-modal__timer">Resend Code in {counter}</p>}

					{isEndCounter && (
						<p className="otp-verification-modal__resend-button" onClick={onHandleResendOtp}>
							Resend OTP
						</p>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(OtpVerificationModal));

OtpVerificationModal.propTypes = {
	ref: PropTypes.object
};
