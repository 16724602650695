import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import useBreadcrumb from "hooks/use-breadcrumb";
import useBeforeUnload from "hooks/use-before-unload";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppTabs from "components/app-tabs";
import AppFamilyInfo from "components/pages/human-resources/app-family-info";
import AppEmployeeInfo from "components/pages/human-resources/app-employee-info";
import AppPersonalInfo from "components/pages/human-resources/app-personal-info";

import familyIcon from "assets/images/pages/human-resources/family-info-icon.svg";
import employeeIcon from "assets/images/pages/human-resources/employee-info-icon.svg";
import personalIcon from "assets/images/pages/human-resources/personal-info-icon.svg";
import familyActiveIcon from "assets/images/pages/human-resources/family-info-active-icon.svg";
import employeeActiveIcon from "assets/images/pages/human-resources/employee-info-active-icon.svg";
import personalActiveIcon from "assets/images/pages/human-resources/personal-info-active-icon.svg";

const TABS = {
	EMPLOYEE_INFO: "EMPLOYEE_INFO",
	PERSONAL_INFO: "PERSONAL_INFO",
	FAMILY_INFO: "FAMILY_INFO"
};

const PageEmployeeMaintenanceCreateEdit = (props) => {
	useBeforeUnload();
	const { id } = useParams();
	const tabRef = useRef();
	const familyInfoRef = useRef();
	const employeeInfoRef = useRef();
	const personalInfoRef = useRef();
	const nextTab = useRef();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const title = useMemo(() => (isCreate ? "Add Employee" : "Edit Employee"), [isCreate]);
	const [employee, setEmployee] = useState();
	const [personal, setPersonal] = useState();
	const [family, setFamily] = useState();
	const [updatedBy, setUpdatedBy] = useState();

	const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Human Resources", path: pathnames.humanResources.employeeMaintenance },
			{ label: "Employee Maintenance", path: pathnames.humanResources.employeeMaintenance }
		];

		if (isCreate) {
			data.push({ label: "Add Employee", path: pathnames.humanResources.employeeMaintenanceCreateEdit + PAGE.CREATE });
		}

		if (!isCreate) {
			data.push({ label: personal?.fullName || "", path: pathnames.humanResources.employeeMaintenanceCreateEdit + id });
		}

		return data;
	}, [isCreate, id, personal]);

	useBreadcrumb({ breadCrumb });

	const onHandleSubmit = useCallback((obj) => {
		const currentTab = tabRef.current.onHandleGetCurrentTab();

		switch (currentTab) {
			case TABS.EMPLOYEE_INFO:
				setEmployee(obj);

				if (!nextTab.current) nextTab.current = TABS.PERSONAL_INFO;
				break;
			case TABS.PERSONAL_INFO:
				setPersonal(obj);

				if (!nextTab.current) nextTab.current = TABS.FAMILY_INFO;
				break;
			case TABS.FAMILY_INFO:
				setFamily(obj);
				break;
			default:
				break;
		}

		tabRef.current.onHandleSelectTab(nextTab.current);

		nextTab.current = null;
	}, []);

	const onHandleTabChange = useCallback((obj, currentTab) => {
		const isEmployeeTab = currentTab === TABS.EMPLOYEE_INFO;
		const isPersonalTab = currentTab === TABS.PERSONAL_INFO;
		const isFamilyTab = currentTab === TABS.FAMILY_INFO;

		if (isPersonalTab) personalInfoRef.current?.onHandleSubmit();

		if (isFamilyTab) familyInfoRef.current?.onHandleSubmit();

		if (isEmployeeTab) employeeInfoRef.current?.onHandleSubmit();

		nextTab.current = obj.name;
	}, []);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.humanResource.employee(uniqueId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setEmployee({
				supervisorId: response.employeeDetail.supervisorId,
				leaveBenefitPackageId: response.employeeDetail.leaveBenefitPackageId,
				claimBenefitPackageId: response.employeeDetail.claimBenefitPackageId,
				reportingManagerId: response.employeeDetail.reportingManagerId,
				remark: response.remark,
				bankName: response.bank.bankName,
				swiftCode: response.bank.swiftCode,
				epfNo: response.contribution.epfNo,
				bankAccount: response.bank.bankAccount,
				socsoNo: response.contribution.socsoNo,
				endWorkWeek: response.schedule.endWorkWeek,
				workEmail: response.employeeDetail.workEmail,
				employeeId: response.employeeDetail.employeeId,
				jobLevelId: response.employeeDetail.jobLevelId,
				positionId: response.employeeDetail.hrConfigPosition.id,
				startWorkWeek: response.schedule.startWorkWeek,
				incomeTaxNo: response.contribution.incomeTaxNo,
				endWorkHour: dayjs(response.schedule.endWorkHour),
				departmentId: response.employeeDetail.hrConfigDepartment.id,
				startWorkHour: dayjs(response.schedule.startWorkHour),
				dateJoined: dayjs(response.employeeDetail.dateJoined),
				employmentStatus: response.employeeDetail.employmentStatus,
				probationEndDate: dayjs(response.employeeDetail.probationEndDate),
				lastEmploymentDate: response.employeeDetail.lastEmploymentDate ? dayjs(response.employeeDetail.lastEmploymentDate) : "",
			});
			
			setPersonal({
				fullName: response.personalInfo.fullName,
				identificationType: response.personalInfo.identificationType,
				identificationNumber: response.personalInfo.identificationNumber || "",
				passport: response.personalInfo.passport || "",
				dateOfBirth: dayjs(response.personalInfo.dateOfBirth),
				gender: response.personalInfo.gender,
				nationality: response.personalInfo.nationality,
				maritalStatus: response.personalInfo.maritalStatus,
				primaryMobileNo: response.personalInfoContact.primaryMobileNo,
				primaryMobileNoPrefix: response.personalInfoContact.primaryMobileNoPrefix,
				secondaryMobileNo: response.personalInfoContact.secondaryMobileNo,
				secondaryMobileNoPrefix: response.personalInfoContact.secondaryMobileNoPrefix,
				email: response.personalInfoContact.email,
				residentialAddress1: response.personalInfoContact.residentialAddress1 || "",
				residentialAddress2: response.personalInfoContact.residentialAddress2 || "",
				residentialPostcode: response.personalInfoContact.residentialPostcode || "",
				residentialState: response.personalInfoContact.residentialState || "",
				residentialRegion: response.personalInfoContact.residentialRegion || "",
				sameAsCorrespondenceAddress: response.personalInfoContact.sameAsCorrespondenceAddress === "Y",
				correspondenceAddress1: response.personalInfoContact.correspondenceAddress1,
				correspondenceAddress2: response.personalInfoContact.correspondenceAddress2,
				correspondencePostcode: response.personalInfoContact.correspondencePostcode,
				correspondenceState: response.personalInfoContact.correspondenceState,
				correspondenceRegion: response.personalInfoContact.correspondenceRegion,
				emergencyContact: response.emergencyContact,
			});

			setFamily({
				familyInfo: response.familyInfo.map(o => ({ ...o, dateOfBirth: dayjs(o.dateOfBirth) }))
			});

			setUpdatedBy({
				lastModifiedBy: response.lastModifiedBy,
				lastModifiedByName: response.lastModifiedByName,
				lastModifiedDate: dayjs(response.lastModifiedDate).format(DATE_TIME.LAST_UPDATED_BY_DATE),
			});
		}
	}, []);

	//prettier-ignore
	const tabs = useMemo(() => {
		const data = [
			{ label: "Employee Info", name: TABS.EMPLOYEE_INFO, icon: employeeIcon, activeIcon: employeeActiveIcon, component: <AppEmployeeInfo ref={employeeInfoRef} employee={employee} onSubmit={onHandleSubmit} />},
			{ label: "Personal Info", name: TABS.PERSONAL_INFO, icon: personalIcon, activeIcon: personalActiveIcon, component: <AppPersonalInfo ref={personalInfoRef}  employee={employee} personal={personal} onSubmit={onHandleSubmit} /> },
			{ label: "Family Info", name: TABS.FAMILY_INFO, icon: familyIcon, activeIcon: familyActiveIcon, component: <AppFamilyInfo ref={familyInfoRef} employee={employee} personal={personal} family={family} onSubmit={onHandleSubmit} /> },
		];

		return data;
	}, [employee, personal, family, onHandleSubmit]);

	useEffect(() => {
		if (!isCreate) onHandleGetDetails(id);

		return () => {
			if (id) memoCancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE);
		};
	}, [isCreate, id, memoCancelRequest, onHandleGetDetails]);

	return (
		<div className="page-employee-maintenance-create-edit">
			<div className="employee-maintenance-create-edit">
				<div className="employee-maintenance-create-edit__header">
					<h1 className="employee-maintenance-create-edit__title">{title}</h1>

					{!isCreate && (
						<p className="employee-maintenance-create-edit__last-update">
							<span>Last Updated By</span> {updatedBy?.lastModifiedByName}, {updatedBy?.lastModifiedDate}
						</p>
					)}
				</div>

				<div className="employee-maintenance-create-edit__body">
					<AppTabs ref={tabRef} tabs={tabs} onChange={onHandleTabChange} />
				</div>
			</div>
		</div>
	);
};

export default PageEmployeeMaintenanceCreateEdit;

PageEmployeeMaintenanceCreateEdit.propTypes = {
	onHandleCancelRequest: PropTypes.func
};
