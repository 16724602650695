import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray, FormikProvider, setNestedObjectValues, useFormik } from "formik";

import api from "services/api";
import getServiceTypeListing from "services/get-service-type-listing";
import getServiceInputListing from "services/get-service-input-listing";
import getServiceStatusListing from "services/get-service-status-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import classNames from "common/class-names";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import STATUS from "constants/status";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

import trashIcon from "assets/images/trash-icon.png";
import addBlueIcon from "assets/images/add-blue-icon.png";
import addIconDisabled from "assets/images/add-icon-disabled.png";

export const AppCreateEditServiceModal = (props, ref) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.INPUT_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const [submitLabel, setSubmitLabel] = useState("Create");

	const initialValues = useMemo(() => {
		const values = { id: "", serviceType: "", status: STATUS.IN_USE, configInputId: "", serviceAction: "", serviceRemark: "", serviceSubtask: [] };

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			configInputId: yup.string().required(ERRORS.REQUIRED),
			status: yup.string().required(ERRORS.REQUIRED),
			serviceType: yup.string().required(ERRORS.REQUIRED),
			serviceAction: yup.string().required(ERRORS.REQUIRED),
			serviceSubtask: yup.array().of(
				yup.object().shape({
					subtaskAction: yup.string().required(ERRORS.REQUIRED),
					configInputId: yup.string().required(ERRORS.REQUIRED),
					seq: yup.number().required(ERRORS.REQUIRED)
				})
			)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const optionSequenceList = useMemo(() => formik.values.serviceSubtask.length ? formik.values.serviceSubtask.map((o, i) => { return { label: i + 1, value: i }; }) : [{ label: 1, value: 0 }], [formik.values.serviceSubtask]);

	const isCreate = useMemo(() => !formik.values.id, [formik.values.id]);

	//prettier-ignore
	const addButtonClassNames = useMemo(() => classNames({
		"create-edit-service-modal__add": true,
		"create-edit-service-modal__add--disabled": restricted
	}), [restricted]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				workOrderId: id,
				woServices: [
					{
						id: values.id,
						serviceType: values.serviceType,
						configInputId: values.configInputId,
						serviceAction: values.serviceAction,
						serviceRemark: values.serviceRemark,
						serviceSubtask: values.serviceSubtask,
						deleted: false
					}
				]
			};

			if (!isCreate) payload.id = values.id;

			await api.post.workOrder.service(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			formik.resetForm();

			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Input was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Input was updated successfully!" }));
			}

			props.onHandleGetList();

			setVisible(false);
		}
	}, [id, isCreate, formik, props, dispatch]);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		const processedServiceSubtasks = obj?.subtasks.map((o, i) => ({
			seq: i,
			id: o?.id,
			subtaskAction: o.subtaskAction,
			configInputId: o.subTaskInput.id
		}));

		formik.setValues({ id: obj?.id || "", serviceType: obj?.serviceType || "", status: STATUS.IN_USE, configInputId: obj?.serviceInput?.id || "", serviceAction: obj?.serviceAction || "", serviceRemark: obj?.serviceRemark || "", serviceSubtask: processedServiceSubtasks || [] });

		if (obj) setSubmitLabel("Update");

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	const onHandleOptionSequence = useCallback((event, index, moveOption) => {
		const nextSeq = parseInt(event.target.value);

		moveOption(index, nextSeq);
	}, []);

	//prettier-ignore
	const onHandleAddOption = useCallback(async (renderProps) => {
		const fieldErrors = await renderProps.form.validateForm();

		if (fieldErrors.serviceSubtask) {
			renderProps.form.setTouched(setNestedObjectValues(fieldErrors, true));

			return;
		}

		const nextOption = { subtaskAction: "", configInputId:"", seq: formik.values.serviceSubtask.length };

		renderProps.push(nextOption);
	}, [formik]);

	const buttonIcon = useMemo(() => {
		if (restricted) {
			return addIconDisabled;
		} else {
			return addBlueIcon;
		}
	}, [restricted]);

	const InputOptions = useCallback((obj) => {
		return (
			<div className="create-edit-service-modal__options">
				{obj.serviceSubtask.map((o, i) => {
					const firstOption = i === 0;
					const error = obj.optionsErrors?.[i];
					const touched = obj.optionsTouched?.[i];
					const sequenceLabel = firstOption ? "#" : "";
					const inputLabel = firstOption ? "Input" : "";
					const optionLabel = firstOption ? "Sub Task Action" : "";
					const trashClassNames = classNames({ "create-edit-service-modal__delete": true, "create-edit-service-modal__delete--first": firstOption, "create-edit-service-modal__delete--disabled": obj.restricted });

					return (
						<div className="create-edit-service-modal__option" key={i}>
							<AppSelectInput searchable={false} type="number" name={`serviceSubtask[${i}].seq`} label={sequenceLabel} placeholder="" options={obj.optionSequenceList} value={i} onChange={(e) => obj.onHandleOptionSequence(e, i, obj.move)} className="create-edit-service-modal__option create-edit-service-modal__option--seq" />

							<AppInput required type="text" name={`serviceSubtask[${i}].subtaskAction`} label={optionLabel} placeholder="Enter Option Name" value={o.subtaskAction} error={error?.subtaskAction} touched={touched} onChange={obj.formik.handleChange} className="custom-app-input" />

							<AppSelectInput required name={`serviceSubtask[${i}].configInputId`} label={inputLabel} placeholder="" loadOptions={getServiceInputListing} value={o.configInputId} error={error?.configInputId} touched={touched} onChange={obj.formik.handleChange} className="custom-app-input" />

							{obj.serviceSubtask.length > 1 && (
								<button type="button" className={trashClassNames} disabled={obj.restricted} onClick={() => obj.remove(i)}>
									<img src={trashIcon} alt="trash-icon" />
								</button>
							)}
						</div>
					);
				})}
			</div>
		);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-create-edit-service-modal" }} open={visible}>
			<FormikProvider value={formik}>
				<div className="create-edit-service-modal">
					<h1 className="create-edit-service-modal__title">New Service</h1>

					<FieldArray
						name="serviceSubtask"
						render={(f) => {
							const values = f.form.values?.serviceSubtask;
							const errors = f.form.errors?.serviceSubtask;
							const touched = f.form.touched?.serviceSubtask;

							return (
								<form className="create-edit-service-modal__form" onSubmit={formik.handleSubmit}>
									<div className="create-edit-service-modal__wrapper">
										<div className="create-edit-service-modal__row">
											<AppSelectInput required name="serviceType" label="Service Type" placeholder="Select..." loadOptions={getServiceTypeListing} value={formik.values.serviceType} error={formik.errors.serviceType} touched={formik.touched.serviceType} onChange={formik.handleChange} />

											<AppSelectInput disabled name="status" label="Status" placeholder="Select..." loadOptions={getServiceStatusListing} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
										</div>

										<div className="create-edit-service-modal__row">
											<AppSelectInput required name="configInputId" label="Input" placeholder="Select..." loadOptions={getServiceInputListing} value={formik.values.configInputId} error={formik.errors.configInputId} touched={formik.touched.configInputId} onChange={formik.handleChange} />
										</div>

										<AppInput type="text" name="serviceAction" label="Service Action" placeholder="Enter Service Action" value={formik.values.serviceAction} error={formik.errors.serviceAction} touched={formik.touched.serviceAction} onChange={formik.handleChange} />

										<AppInput multiline type="textarea" name="serviceRemark" label="Service Remarks" placeholder="Enter Service Remarks" value={formik.values.serviceRemark} error={formik.errors.serviceRemark} touched={formik.touched.serviceRemark} onChange={formik.handleChange} />

										<InputOptions restricted={restricted} optionSequenceList={optionSequenceList} formik={formik} serviceSubtask={values} optionsErrors={errors} optionsTouched={touched} remove={f.remove} move={f.move} onHandleOptionSequence={onHandleOptionSequence} />
									</div>

									<div className="create-edit-service-modal__add-service-button">
										<div className={addButtonClassNames}>
											<AppButton outline type="button" label="Add" icon={buttonIcon} disabled={restricted} onClick={() => onHandleAddOption(f)} />
										</div>
									</div>

									<div className="create-edit-service-modal__button-container">
										<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

										<AppButton type="submit" label={submitLabel} disabled={formik.isSubmitting || restricted} />
									</div>
								</form>
							);
						}}
					/>
				</div>
			</FormikProvider>
		</Modal>
	);
};

export default memo(forwardRef(AppCreateEditServiceModal));

AppCreateEditServiceModal.propTypes = {
	ref: PropTypes.object
};
