import React, { forwardRef, memo, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ENDPOINT_PATH from "constants/end-point-path";

import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppSiteDetailsCreateEditTeamMembersModal from "components/pages/work-order/app-site-details-create-edit-team-members-modal";

import deleteIcon from "assets/images/trash-icon.png";
import addBlueIcon from "assets/images/add-blue-icon.png";

const AppSiteDetailsTeamMembersTable = (props, ref) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const teamMemberRef = useRef();
	const [data, setData] = useState(convertPaginationTableData());
	const queryParams = useRef({ page: 0 });
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const teamMembersData = useMemo(() => props.teamMembersData, [props.teamMembersData]);
	const onHandleUpdateTeamMembersLocal = useMemo(() => props.onHandleUpdateTeamMembersLocal, [props.onHandleUpdateTeamMembersLocal]);
	const onHandleUpdateTeamMembers = useMemo(() => props.onHandleUpdateTeamMembers, [props.onHandleUpdateTeamMembers]);

	//prettier-ignore
	const onHandleAddTeamMember = useCallback((props, ref) => {
		teamMemberRef.current.onHandleShow(teamMembersData);
	}, [teamMembersData]);

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		let response = null;

		const params = sanitizeObject({ ...queryParams.current, size: 10, "work-order-id": id });

		try {
			response = await api.get.workOrder.teamMembers(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, [id]);

	//prettier-ignore
	const onHandleRemoveTeamMember = useCallback(async (teamMember) => {
		let nextTeamMembersData = structuredClone(teamMembersData);

		if (isCreate) {
			nextTeamMembersData = nextTeamMembersData.filter((o) => o.id !== teamMember.id);

			onHandleUpdateTeamMembersLocal(nextTeamMembersData);
		}

		if (!isCreate) {
			let response = null;

			try {
				const params = { workOrderId: id, employeeId: teamMember.employeeId };

				await api.post.workOrder.deleteTeamMember(params);

				response = true
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				dispatch(promptLayoutAlertMessage({ message: "Team member was successfully deleted!" }));
			}

			onHandleGetList();
		}
	}, [dispatch, id, isCreate, onHandleGetList, onHandleUpdateTeamMembersLocal, teamMembersData]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "employeeId",
			label: "Employee ID",
			options: {
				sort: false
			}
		},
		{
			name: "personalInfo.fullName",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "remove",
			label: "Remove",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const obj = data.content[rowIndex];

					return (
						<button type="button" className="table__delete" onClick={() => onHandleRemoveTeamMember(obj)}>
							<img src={deleteIcon} alt="delete-icon" />
						</button>
					);
				}
			}
		}
	], [data.content, onHandleRemoveTeamMember]);

	const emptyState = useMemo(() => {
		if (data.content.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={tableColumns.length} align="center">
						<p className="table__text">
							No Team Members Added.
							<span className="table__link" onClick={onHandleAddTeamMember}>
								Add Team Member?
							</span>
						</p>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [data, onHandleAddTeamMember, tableColumns.length]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		if (isCreate) {
			const teamMembersDataCopy = structuredClone(teamMembersData);

			setData({ content: teamMembersDataCopy });
		} else {
			onHandleGetList(id);
		}
	}, [id, isCreate, onHandleGetList, teamMembersData]);

	useEffect(() => {
		return () => {
			if (!isCreate) cancelRequest(ENDPOINT_PATH.WORK_INSPECTION_REPORT.TEAM_MEMBERS);
		};
	}, [cancelRequest, isCreate]);

	useImperativeHandle(ref, () => ({
		onHandleGetList: onHandleGetList
	}));

	return (
		<div className="app-site-details-team-members-table">
			<div className="site-details-team-members-table">
				<div className="site-details-team-members-table__header">
					<p className="site-details-team-members-table__label">Team Members</p>

					<div className="site-details-team-members-table__add-button">
						<AppButton outline type="button" label="Add Team Member" icon={addBlueIcon} onClick={onHandleAddTeamMember} />
					</div>
				</div>

				<AppTable data={data.content} columns={tableColumns} components={emptyState} options={tableOptions} />
			</div>

			<AppSiteDetailsCreateEditTeamMembersModal ref={teamMemberRef} onHandleUpdateTeamMembersLocal={onHandleUpdateTeamMembersLocal} onHandleUpdateTeamMembers={onHandleUpdateTeamMembers} />
		</div>
	);
};

export default memo(forwardRef(AppSiteDetailsTeamMembersTable));
