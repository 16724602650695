import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";
import STATUS from "constants/status";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppInputDragAndDropFiles from "components/app-input-drag-and-drop-files";

import closeIcon from "assets/images/close-icon.png";

export const AppPendingLeavesViewModal = (props, ref) => {
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const downloadingFile = useRef(false);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.LEAVE_MANAGEMENT], [profile]);
	const restricted = useMemo(() => !accessible?.update, [accessible]);

	const initialValues = useMemo(() => {
		const values = {
			employeeName: "",
			employeeId: "",
			leaveType: "",
			timeOffType: "",
			status: "",
			startDate: "",
			endDate: "",
			submitDate: "",
			days: "",
			balanceDays: "",
			description: "",
			approved: "",
			rejectReason: "",
			file: []
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({
			id: obj.id,
			employeeName: obj?.employeeName || "",
			employeeId: obj?.employeeId || "",
			status: obj?.status || "",
			leaveType: obj?.leaveType || "",
			timeOffType: obj?.timeOffType || "",
			startDate: dayjs(obj?.startDate) || "",
			endDate: dayjs(obj?.endDate) || "",
 			submitDate: dayjs(obj?.submitDate) || "",
			days: obj?.days || "",
			balanceDays: obj?.balanceDays || "",
			description: obj?.description || "",
			supervisorId: obj?.supervisorId,
			reportingManagerId: obj?.reportingManagerId,
			file: obj?.docs
		});

		setVisible(true);
	}, [formik]);

	const actionPermission = useMemo(() => (props.employeeId === formik.values.supervisorId && formik.values.status === STATUS.PENDING) || (props.employeeId === formik.values.reportingManagerId && formik.values.status === STATUS.APPROVED_BY_SUPERVISOR), [formik.values, props.employeeId]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	const onHandleReject = useCallback(() => {
		setVisible(false);

		props.onHandleRejectLeave(formik.values.id);
	}, [formik, props]);

	//prettier-ignore
	const onHandleApproval = useCallback(async () => {
		let response = null;
		
		const id = [formik.values.id];

		try {
			await api.post.humanResource.approveEmployeeLeave({ "leave-id": id });

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Leave was approved successfully" }));

			props.onHandleGetList();
		}
	}, [formik, dispatch, props]);

	const onHandleDownloadFile = useCallback(async (fileObject) => {
		if (downloadingFile.current) return;

		downloadingFile.current = true;

		let response = null;

		try {
			const payload = { "doc-id": fileObject.id };

			response = await api.post.humanResource.downloadLeaveDoc(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const url = response.fileUrl;
			const a = document.createElement("a");
			document.body.appendChild(a);

			a.href = url;
			a.download = response.fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				downloadingFile.current = false;
			}, 0);
		}
	}, []);

	const onHandleSubmit = useCallback(async () => {
		setVisible(false);

		onHandleApproval();
	}, [onHandleApproval]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-pending-leaves-view-modal" }} open={visible}>
			<div className="pending-leaves-view-modal">
				<button type="button" className="pending-leaves-view-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<div className="pending-leaves-view-modal__header">
					<h1 className="pending-leaves-view-modal__title">Leave Details</h1>
				</div>

				<form className="pending-leaves-view-modal__form" onSubmit={formik.handleSubmit}>
					<div className="pending-leaves-view-modal__row">
						<AppInput disabled type="text" name="employeeName" label="Name" value={formik.values.employeeName} error={formik.errors.employeeName} touched={formik.touched.employeeName} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="employeeId" label="Employee ID" value={formik.values.employeeId} error={formik.errors.employeeId} touched={formik.touched.employeeId} onChange={formik.handleChange} />
					</div>

					<div className="pending-leaves-view-modal__row">
						<AppInput disabled type="text" name="status" label="Status" value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="leaveType" label="Leave Type" value={formik.values.leaveType} error={formik.errors.leaveType} touched={formik.touched.leaveType} onChange={formik.handleChange} />
					</div>

					<div className="pending-leaves-view-modal__row">
						<AppInput disabled type="text" name="timeOffType" label="Time Off" value={formik.values.timeOffType} error={formik.errors.timeOffType} touched={formik.touched.timeOffType} onChange={formik.handleChange} />

						<AppInputDate disabled type="text" name="startDate" label="Start Date" value={formik.values.startDate} error={formik.errors.startDate} touched={formik.touched.startDate} onChange={formik.handleChange} />
					</div>

					<div className="pending-leaves-view-modal__row">
						<AppInputDate disabled type="text" name="endDate" label="End Date" value={formik.values.endDate} error={formik.errors.endDate} touched={formik.touched.endDate} onChange={formik.handleChange} />

						<AppInputDate disabled type="text" name="submitDate" label="Submit Date" value={formik.values.submitDate} error={formik.errors.submitDate} touched={formik.touched.submitDate} onChange={formik.handleChange} />
					</div>

					<div className="pending-leaves-view-modal__row">
						<AppInput disabled type="text" name="days" label="Day(s)" value={formik.values.days} error={formik.errors.days} touched={formik.touched.days} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="balanceDays" label="Balance Days" value={formik.values.balanceDays} error={formik.errors.balanceDays} touched={formik.touched.balanceDays} onChange={formik.handleChange} />
					</div>

					<AppInput disabled type="text" label="Description" name="description" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} onChange={formik.handleChange} />

					<AppInputDragAndDropFiles disabled name="file" value={formik.values.file} onHandleDownloadFile={onHandleDownloadFile} onChange={formik.setFieldValue} />

					{formik.values.approved === STATUS.REJECTED && <AppInput type="textarea" label="Reject Reason" name="rejectReason" value={formik.values.rejectReason} error={formik.errors.rejectReason} touched={formik.touched.rejectReason} onChange={formik.handleChange} />}

					<div className="pending-leaves-view-modal__button-container">
						{(actionPermission || props.isHR) && (
							<div className="pending-leaves-view-modal__reject-button">
								<AppButton outline type="button" disabled={restricted} label="Reject" onClick={onHandleReject} />
							</div>
						)}

						{actionPermission && (
							<div className="pending-leaves-view-modal__approve-button">
								<AppButton type="submit" disabled={restricted} label="Approve" />
							</div>
						)}
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppPendingLeavesViewModal));

AppPendingLeavesViewModal.propTypes = {
	isHR: PropTypes.bool.isRequired,
	employeeId: PropTypes.string.isRequired,
	onHandleGetList: PropTypes.func.isRequired,
	onHandleRejectLeave: PropTypes.func.isRequired
};
