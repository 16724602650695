import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import capitalizeCharacter from "common/capitalize-character";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppStatus from "components/app-status";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import editIcon from "assets/images/edit-icon.png";

const AppCustomerCreateEditContractTable = (props) => {
	const { id } = useParams();
	const navigate = useNavigate();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_MAINTENANCE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const queryParams = useRef({ page: 0, keyword: "", sort: "" });
	const [data, setData] = useState(convertPaginationTableData());
	const customerRefNo = useMemo(() => props.customerRefNo || "", [props.customerRefNo]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			response = await api.get.customer.contracts({ "customer-id": id, ...params });
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, [id]);

	const onHandleAddContract = useCallback(() => {
		navigate(pathnames.customer.customerCreateEditContract + id + "/" + PAGE.CREATE, { state: { customerRefNo, picContacts: props.picContacts } });
	}, [navigate, id, customerRefNo, props.picContacts]);

	// prettier-ignore
	const onHandleEditContract = useCallback((tableMeta) => {
		const contractId = data.content[tableMeta.rowIndex]?.id;

		navigate(pathnames.customer.customerCreateEditContract + contractId, { state: { customerRefNo } });
	}, [data.content, navigate, customerRefNo]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "referenceNo",
			label: "Contract ID",
			options: {
				sort: false
			}
		},
		{
			name: "startDate",
			label: "Start Date",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.YYYY_MM_DD).toString()
			}
		},
		{
			name: "endDate",
			label: "End Date",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.YYYY_MM_DD).toString()
			}
		},
		{
			name: "type",
			label: "Contract Type",
			options: {
				sort: false,
				customBodyRender: (value) => capitalizeCharacter(value)
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: false,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleEditContract(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleEditContract]);

	const emptyState = useMemo(() => {
		if (data.content.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={tableColumns.length} align="center">
						<p className="table__text">
							No Contracts Added.{" "}
							<span className="table__link" onClick={onHandleAddContract}>
								Add Contract?
							</span>
						</p>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [data.content.length, onHandleAddContract, tableColumns.length]);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.CUSTOMER.CONTRACT);
		};
	}, [cancelRequest]);

	return (
		<div className="app-customer-create-edit-contract-table">
			<div className="customer-create-edit-contract-table">
				{/* prettier-ignore */}
				<AppTableFilterHeader searchPlaceholder="Search by Contract ID" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} restricted={restricted} onHandleAdd={onHandleAddContract} />

				<AppTable data={data.content} columns={tableColumns} components={emptyState} options={tableOptions} />
			</div>
		</div>
	);
};

export default AppCustomerCreateEditContractTable;
