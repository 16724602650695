import React, { Fragment, forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray, FormikProvider, setNestedObjectValues, useFormik } from "formik";

import api from "services/api";
import getInputTypeListing from "services/get-input-type-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import classNames from "common/class-names";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import INPUT_TYPE from "constants/input-type";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppSelectInput from "components/app-select-input";

import trashIcon from "assets/images/trash-icon.png";
import addBlueIcon from "assets/images/add-blue-icon.png";
import addIconDisabled from "assets/images/add-icon-disabled.png";

export const AppInputCreateEditModal = (props, ref) => {
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.INPUT_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);

	const initialValues = useMemo(() => {
		const values = { status: "", id: "", label: "", type: "", required: false, options: [] };

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			label: yup.string().required(ERRORS.REQUIRED),
			type: yup.string().required(ERRORS.REQUIRED),
			options: yup.array().of(
				yup.object().shape({
					value: yup.string().required(ERRORS.REQUIRED),
					seq: yup.number().required(ERRORS.REQUIRED)
				})
			)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const isSelectionInput = useMemo(() => formik.values.type === INPUT_TYPE.SINGLE_SELECTION || formik.values.type === INPUT_TYPE.MULTIPLE_SELECTION, [formik.values.type]);

	// prettier-ignore
	const optionSequenceList = useMemo(() => formik.values.options.length ? formik.values.options.map((o, i) => { return { label: i + 1, value: i } }) : [{ label: 1, value: 0 }], [formik.values.options]);

	const isCreate = useMemo(() => formik.values.status === PAGE.CREATE, [formik.values.status]);

	const title = useMemo(() => (isCreate ? "Add Input" : "Edit Input"), [isCreate]);

	// prettier-ignore
	const addButtonClassNames = useMemo(() => classNames({
		"input-create-edit-modal__add": true,
		"input-create-edit-modal__add--disabled": restricted
	}), [restricted]);

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = { label: values.label, type: values.type, required: values.required, options: values.options };

			if (isCreate) {
				await api.post.input.createInput(payload);
			}

			if (!isCreate) {
				payload.id = values.id;

				await api.post.input.updateInput(payload);
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			setVisible(false);

			formik.resetForm();

			if(isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Input was added successfully!" }));
			}

			if(!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Input was updated successfully!" }));
			}

			props.onHandleGetList();
		}
	}, [formik, isCreate, props, dispatch]);

	// prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({ status: obj?.id ? PAGE.EDIT : PAGE.CREATE, id: obj?.id, label: obj?.label || "", type: obj?.type || "", required: obj?.required || false, options: obj?.options || [] });

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	// prettier-ignore
	const onHandleChangeType = useCallback((event) => {
		const value = event.target.value;
		let options = structuredClone(formik.values.options);

		switch (value) {
			case INPUT_TYPE.SINGLE_SELECTION:
			case INPUT_TYPE.MULTIPLE_SELECTION:
				if (!options.length) options = [{ seq: 0, value: "" }];

				break;

			default:
				options = [];

				break;
		}

		formik.setFieldValue("options", options);

		formik.setFieldValue("type", value);
	}, [formik]);

	// prettier-ignore
	const onHandleSetRequired = useCallback((value) => {
		formik.setFieldValue("required", value);
	}, [formik]);

	// prettier-ignore
	const onHandleOptionSequence = useCallback((event, index, moveOption) => {
		const nextSeq = parseInt(event.target.value);

		moveOption(index, nextSeq);
	}, []);

	// prettier-ignore
	const onHandleAddOption = useCallback(async (renderProps) => {
		const fieldErrors = await renderProps.form.validateForm();
		
		if(fieldErrors.options) {
			renderProps.form.setTouched(setNestedObjectValues(fieldErrors, true));

			return;
		}

		const nextOption = { value: "", seq: formik.values.options.length + 1 };

		renderProps.push(nextOption);
	}, [formik]);

	const buttonIcon = useMemo(() => {
		if (restricted) {
			return addIconDisabled;
		} else {
			return addBlueIcon;
		}
	}, [restricted]);

	const InputOptions = useCallback((obj) => {
		return (
			<div className="input-create-edit-modal__container">
				<div className="input-create-edit-modal__label">Input Options (for Work Order)</div>

				<div className="input-create-edit-modal__options">
					{obj.options.map((o, i) => {
						const error = obj.optionsErrors?.[i];
						const touched = obj.optionsTouched?.[i];
						const firstOption = i === 0;
						const sequenceLabel = firstOption ? "#" : "";
						const optionLabel = firstOption ? "Options" : "";
						const trashClassNames = classNames({ "input-create-edit-modal__delete": true, "input-create-edit-modal__delete--first": firstOption, "input-create-edit-modal__delete--disabled": obj.restricted });

						return (
							<div className="input-create-edit-modal__option" key={i}>
								{/* prettier-ignore */}
								<AppSelectInput searchable={false} disabled={obj.restricted} type="number" name={`options[${i}].seq`} label={sequenceLabel} placeholder="" options={obj.optionSequenceList} value={i} onChange={(e) => obj.onHandleOptionSequence(e, i, obj.move)} />

								{/* prettier-ignore */}
								<AppInput required disabled={obj.restricted} type="text" name={`options[${i}].value`} label={optionLabel} placeholder="Enter Option Name" value={o.value} error={error?.value} touched={touched} onChange={obj.formik.handleChange} />

								{obj.options.length > 1 && (
									<button type="button" className={trashClassNames} disabled={obj.restricted} onClick={() => obj.remove(i)}>
										<img src={trashIcon} alt="trash-icon" />
									</button>
								)}
							</div>
						);
					})}
				</div>
			</div>
		);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-input-create-edit-modal" }} open={visible}>
			<FormikProvider value={formik}>
				<div className="input-create-edit-modal">
					<h1 className="input-create-edit-modal__title">{title}</h1>

					<form className="input-create-edit-modal__form" onSubmit={formik.handleSubmit}>
						<FieldArray
							name="options"
							render={(f) => {
								const values = f.form.values?.options;
								const errors = f.form.errors?.options;
								const touched = f.form.touched?.options;

								return (
									<Fragment>
										<div className="input-create-edit-modal__wrapper">
											<div className="input-create-edit-modal__label">Details</div>

											{/* prettier-ignore */}
											<AppInput required disabled={restricted} type="text" name="label" label="Input Name" placeholder="Enter Input Name" value={formik.values.label} error={formik.errors.label} touched={formik.touched.label} onChange={formik.handleChange} />

											{/* prettier-ignore */}
											<AppSelectInput searchable={false} required disabled={restricted} type="text" name="type" label="Input Type" placeholder="Select..." loadOptions={getInputTypeListing} value={formik.values.type} error={formik.errors.type} touched={formik.touched.type} onChange={onHandleChangeType} />

											{isSelectionInput && /*prettier-ignore*/ <InputOptions restricted={restricted} optionSequenceList={optionSequenceList} formik={formik} options={values} optionsErrors={errors} optionsTouched={touched} remove={f.remove} move={f.move} onHandleOptionSequence={onHandleOptionSequence} />}
										</div>

										{isSelectionInput && (
											<div className={addButtonClassNames}>
												<AppButton outline type="button" label="Add" icon={buttonIcon} disabled={restricted} onClick={() => onHandleAddOption(f)} />
											</div>
										)}

										<AppCheckbox disabled={restricted} name="required" onClick={onHandleSetRequired} label="Input Mandatory" value={formik.values.required} />
									</Fragment>
								);
							}}
						/>

						<div className="input-create-edit-modal__button-containers">
							<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

							<AppButton type="submit" label="Confirm" disabled={formik.isSubmitting || restricted} />
						</div>
					</form>
				</div>
			</FormikProvider>
		</Modal>
	);
};

export default memo(forwardRef(AppInputCreateEditModal));

AppInputCreateEditModal.propTypes = {
	ref: PropTypes.object
};
