import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import classNames from "common/class-names";
import getTotalDays from "common/get-total-days";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ROLES from "constants/roles";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppAllPendingClaimModal from "components/pages/human-resources/app-all-pending-claim-modal";
import AppPendingClaimRejectionModal from "components/pages/human-resources/app-pending-claim-rejection-modal";

import eyeIcon from "assets/images/eye-open-icon.png";
import rejectIcon from "assets/images/pages/human-resources/reject-icon.svg";
import approveIcon from "assets/images/pages/human-resources/approve-icon.svg";

const AppAllPendingClaimTable = (props) => {
	const dispatch = useDispatch();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [data, setData] = useState(convertPaginationTableData());
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const allPendingClaimRef = useRef();
	const pendingClaimRejectionModal = useRef();
	const profile = useSelector((state) => state.profile);
	const [selectedClaim, setSelectedClaim] = useState([]);
	const [selectedPermission, setSelectedPermission] = useState([]);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CLAIM_MANAGEMENT], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const employeeId = useMemo(() => props.employeeId, [props.employeeId]);
	const isHR = useMemo(() => props.isHR, [props.isHR]);
	const defaultStatus = useMemo(() => [STATUS.APPROVED_BY_SUPERVISOR, STATUS.PENDING].join(","), []);
	const status = useMemo(() => {
		const currentStatuses = searchParams.get("status")?.split(",");
		const relevantStatus = currentStatuses?.every((e) => defaultStatus.split(",").includes(e));

		if (!relevantStatus) {
			return defaultStatus;
		} else {
			return searchParams.get("status");
		}
	}, [defaultStatus, searchParams]);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", "start-date": searchParams.get("start-date") || "", "end-date": searchParams.get("end-date") || "", status: status, tab: "PENDING_CLAIM" });

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = { ...queryParams.current, size: 10 };

			memoSearchParams.current(params);

			response = await api.get.humanResource.pendingClaim(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);

			setSelectedClaim([]);

			setSelectedPermission([]);
		}
	}, []);

	const validationSchema = yup.object({
		endDate: yup.date().test(function (value, { parent }) {
			if (!value) return true;

			const totalDays = getTotalDays(parent.startDate, value);

			return totalDays >= 0 && totalDays <= 90;
		})
	});

	const formik = useFormik({
		initialValues: { submitDate: "", startDate: "", endDate: "", claimType: "", amount: "", status: [STATUS.APPROVED_BY_SUPERVISOR, STATUS.PENDING] },
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const intructionClassName = useMemo(() =>classNames({
		"filter-menu__instruction": true,
		"filter-menu__instruction--error": formik.errors.endDate
	}), [formik.errors.endDate]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Pending", name: STATUS.PENDING },
			{ label: "Approved by Supervisor", name: STATUS.APPROVED_BY_SUPERVISOR }
		];

		return data;
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current["start-date"] = "";
		queryParams.current["end-date"] = "";

		onHandleGetList();
	}, [formik, onHandleGetList]);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	//prettier-ignore
	const onHandleSelectClaim = useCallback((boolean, idNo, permissionId) => {
		setSelectedClaim((prev) => {
			let nextClaim = [...prev];

			if (boolean) {
				nextClaim.push(idNo);
			} else {
				nextClaim = nextClaim.filter((id) => id !== idNo);
			}

			if (!isHR) {
				setSelectedPermission((prevStatus) => {
					if (!nextClaim?.length) {
						return [];
					} else if (boolean) {
						return permissionId;
					}
					
					return prevStatus;
				});
			}

			return nextClaim;
		});
	}, [isHR]);

	//prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		if (boolean) {
			const ids = data.content.filter(item => !selectedPermission.length || (item.supervisorId === selectedPermission && item.status === STATUS.PENDING) || (item.reportingManagerId === selectedPermission && item.status === STATUS.APPROVED_BY_SUPERVISOR)).map(item => item.id);
			
			setSelectedClaim(ids);
		} else {
			setSelectedClaim([]);

			setSelectedPermission([]);
		}
	}, [data.content, selectedPermission]);

	//prettier-ignore
	const onHandleBack = useCallback((id) => {
		const backData = data.content.filter((item) => id.includes(item.id));

		allPendingClaimRef.current.onHandleShow(backData[0]);
	}, [data]);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");

		if (values.startDate && dayjs(values.startDate).isValid()) {
			queryParams.current["start-date"] = dayjs(values.startDate).format(DATE_TIME.YYYY_MM_DD);
		}

		if (values.endDate && dayjs(values.endDate).isValid()) {
			queryParams.current["end-date"] = dayjs(values.endDate).format(DATE_TIME.YYYY_MM_DD);
		}
		
		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
		formik.setFieldValue("start-date", queryParams.current["start-date"]);
		formik.setFieldValue("end-date", queryParams.current["end-date"]);
	}, [formik]);

	//prettier-ignore
	const onHandleViewClaimDetails = useCallback((obj) => {
		allPendingClaimRef.current.onHandleShow(data.content[obj.rowIndex]);
	}, [data]);

	const onHandleRejectClaims = useCallback(() => {
		pendingClaimRejectionModal.current.onHandleShow(selectedClaim);
	}, [selectedClaim]);

	const onHandleRejectClaim = useCallback((id) => {
		pendingClaimRejectionModal.current.onHandleShow(id);
	}, []);

	//prettier-ignore
	const onHandleRejectReason = useCallback(async (values, id) => {
		let response = null;

		try {
			await api.post.humanResource.rejectEmployeeClaim({ "claim-id": id, reason: values });

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Claim was rejected successfully" }));

			onHandleGetList();
		}
	}, [dispatch, onHandleGetList]);

	//prettier-ignore
	const onHandleRejectReasons = useCallback(async (values, ids) => {
		let response = null;

		try {
			const payload = { claimRequestIds: ids, reason: values };

			await api.post.humanResource.rejectPendingClaim(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Claim was rejected successfully" }));

			onHandleGetList();
		}
	}, [dispatch, onHandleGetList]);

	//prettier-ignore
	const onHandleApproveClaim = useCallback(async (id) => {
		let response = null;

		try {
			await api.post.humanResource.approveEmployeeClaim({ "claim-id": id });

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}
		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Claim was approved successfully" }));

			onHandleGetList();
		}
	}, [dispatch, onHandleGetList]);

	const onHandleApproveClaims = useCallback(async () => {
		let response = null;

		try {
			const payload = { claimRequestIds: selectedClaim };

			await api.post.humanResource.approvePendingClaim(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}
		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Claim was approved successfully" }));

			onHandleGetList();
		}
	}, [selectedClaim, dispatch, onHandleGetList]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "checkbox",
			options: {
				sort: false,
				customHeadRender: () => {
					const selectedAll = data.content.length > 0 && selectedClaim.length === data.content.filter((item) => !selectedPermission.length || selectedPermission.includes(item.status === STATUS.PENDING ? item.supervisorId : item.reportingManagerId)).length;

					return (
						<TableCell key="table-key" className="app-table__cell app-table__cell--header">
							{(isHR || selectedClaim.length > 0) && <AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />}
						</TableCell>
					);
				},
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tableClaimStatus = data.content[rowIndex]?.status;
					const permittedId = tableClaimStatus === STATUS.PENDING ? data.content[rowIndex]?.supervisorId : data.content[rowIndex]?.reportingManagerId;
					const isSupervisor = data.content[rowIndex]?.supervisorId === employeeId;
					const isReportingManager = data.content[rowIndex]?.reportingManagerId === employeeId;
					const tableClaimID = data.content[rowIndex]?.id;
					const selected = selectedClaim.includes(tableClaimID);
					const checkPermission = !selectedPermission.length || selectedPermission.includes(employeeId);
					const permission = (isSupervisor && tableClaimStatus === STATUS.PENDING) || (isReportingManager && tableClaimStatus === STATUS.APPROVED_BY_SUPERVISOR) || isHR;

					return (
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className="app-table__cell">{permission && <AppCheckbox className="checkbox" disabled={!checkPermission} onClick={(v) => onHandleSelectClaim(v, tableClaimID, permittedId)} value={selected} />} </TableCell>
								</TableRow>
							</TableBody>
						</Table>
					);
				}
			}
		},
		{
			name: "employeeDetail.personalInfo.fullName",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const employeeNameData = data.content[rowIndex].name;

					return employeeNameData;
				}
			}
		},
		{
			name: "claimType",
			label: "Claim Type",
			options: {
				sort: false
			}
		},
		{
			name: "submitDate",
			label: "Submit Date",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			}
		},
		{
			name: "receiptAmount",
			label: "Amount (MYR)",
			options: {
				sort: false
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: false,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" disabled={restricted} className="table__view" onClick={() => onHandleViewClaimDetails(tableMeta)}>
							<img src={eyeIcon} alt="expand-icon" />
						</button>
					);
				}
			}
		}
	], [data.content, employeeId, isHR, onHandleSelectAll, onHandleSelectClaim, onHandleViewClaimDetails, restricted, selectedClaim, selectedPermission]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	//prettier-ignore
	const HeaderDetailsButton = useCallback((obj) => {
		if (!obj.selectedClaim.length) return null;

		return (
			<div className="all-pending-claim-table__header-button">
				<div className="all-pending-claim-table__reject-button">
					<AppButton outline type="button" disabled={obj.restricted} label="Reject" icon={rejectIcon} onClick={obj.onHandleRejectClaims} />
				</div>

				{!obj.isHR && <div className="all-pending-claim-table__approve-button">
					<AppButton type="button" disabled={obj.restricted} label="Approve" icon={approveIcon} onClick={obj.onHandleApproveClaims} />
				</div>}
			</div>
		);
	}, []);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.PENDING_CLAIM);
		};
	}, [cancelRequest]);

	return (
		<div className="app-all-pending-claim-table">
			<div className="all-pending-claim-table">
				<div className="all-pending-claim-table__header">
					<AppTableFilterHeader formik={{ ...formik }} restricted={restricted} searchPlaceholder="Search by Name" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
						<div className="all-pending-claim-table-filter">
							<div className="all-pending-claim-table-filter__container">
								<div className="all-pending-claim-table-filter__inputs">
									<AppInputDate name="startDate" label="Submit Date" placeholder="Start Date" value={formik.values.startDate} onChange={formik.setFieldValue} />

									<AppInputDate name="endDate" placeholder="End Date" value={formik.values.endDate} onChange={formik.setFieldValue} />
								</div>

								<p className={intructionClassName}>Please select a date or a date range of up to 90 days</p>
							</div>

							<div className="all-pending-claim-table-filter__container">
								<p className="all-pending-claim-table-filter__label">Status</p>

								<div className="all-pending-claim-table-filter__checkbox-status">
									{menuFilterStatus.map((o) => {
										const isActiveStatus = formik.values.status.findIndex((i) => i === o.name) > -1;

										return (
											<div className="all-pending-claim-table-filter__checkbox" key={o.label}>
												<AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActiveStatus} />
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</AppTableFilterHeader>

					<HeaderDetailsButton data={data.content} isHR={isHR} selectedClaim={selectedClaim} onHandleApproveClaims={onHandleApproveClaims} onHandleRejectClaims={onHandleRejectClaims} restricted={restricted} />
				</div>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>

			<AppPendingClaimRejectionModal ref={pendingClaimRejectionModal} onHandleRejectReason={onHandleRejectReason} onHandleRejectReasons={onHandleRejectReasons} onHandleBack={onHandleBack} />

			<AppAllPendingClaimModal ref={allPendingClaimRef} onHandleApproveClaim={onHandleApproveClaim} onHandleRejectClaim={onHandleRejectClaim} isHR={isHR} employeeId={employeeId} />
		</div>
	);
};

export default AppAllPendingClaimTable;
