import React, { forwardRef, memo, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ENDPOINT_PATH from "constants/end-point-path";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import closeIcon from "assets/images/close-icon.png";

export const AppAddServiceChecklistModal = (props, ref) => {
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [visible, setVisible] = useState(false);
	const [searchParams] = useSearchParams();
	const [selectedServiceChecklists, setSelectedServiceChecklists] = useState([]);
	const [data, setData] = useState(convertPaginationTableData());
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "", tab: "ASSET_TYPES" });
	const onHandleAddServices = useMemo(() => props.onHandleAddServices, [props.onHandleAddServices]);

	const onHandleExtractServiceIds = useCallback((selectedServiceChecklists) => {
		let extractedServiceIds = [];

		selectedServiceChecklists.forEach((checklist) => {
			checklist.configServicesMappings.forEach((service) => extractedServiceIds.push(service.service.id));
		});

		return extractedServiceIds;
	}, []);

	const onHandleGetService = useCallback(async (id) => {
		let response = null;

		try {
			response = await api.get.serviceListing.serviceListing(id);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			return response;
		}
	}, []);

	const onHandleSubmit = useCallback(async () => {
		const extractedServiceIds = onHandleExtractServiceIds(selectedServiceChecklists);

		const services = await Promise.all(extractedServiceIds.map(async (id) => await onHandleGetService(id)));

		onHandleAddServices(services);

		setVisible(false);
	}, [onHandleAddServices, onHandleExtractServiceIds, onHandleGetService, selectedServiceChecklists]);

	const onHandleShow = useCallback((values) => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			response = await api.get.serviceChecklist.serviceChecklists(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSelectServiceChecklist = useCallback((boolean, obj) => {
		let nextChecklist = [...selectedServiceChecklists];

		const index = nextChecklist.findIndex((o) => o.id === obj.id);

		if (!boolean && index > -1) {
			nextChecklist = nextChecklist.filter((o) => o.id !== obj.id);
		} else {
			nextChecklist.push(obj);
		}

		setSelectedServiceChecklists(nextChecklist);
	}, [selectedServiceChecklists]);

	const onHandleSearch = useCallback((event) => {
		queryParams.current.name = event.target.value;
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true
			}
		},
		{
			name: "referenceNo",
			label: "Checklist ID",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "name",
			label: "Checklist Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "noOfServices",
			label: "No. of Services",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "add",
			label: "Add",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const obj = data.content[rowIndex];
					const selected = selectedServiceChecklists.findIndex((o) => o.id === obj.id) > -1;

					return <AppCheckbox label="" onClick={(v) => onHandleSelectServiceChecklist(v, obj)} value={selected} />;
				}
			}
		}
	], [data.content, selectedServiceChecklists, onHandleSelectServiceChecklist]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CHECKLISTS);
		};
	}, [cancelRequest]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-add-service-checklist-modal" }} open={visible}>
			<div className="add-service-checklist-modal">
				<button type="button" className="add-service-checklist-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="add-service-checklist-modal__title">Add Service</h1>

				<div className="add-service-checklist-modal__form">
					<p className="add-service-checklist-modal__description">
						Select from <span>In-Use</span> Services from General Configuration Module
					</p>

					<AppTableFilterHeader searchPlaceholder="Search by Service ID or Service Action" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} />

					<AppTable data={data.content} columns={tableColumns} />

					<div className="add-service-checklist-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="button" label="Add" onClick={onHandleSubmit} />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppAddServiceChecklistModal));

AppAddServiceChecklistModal.propTypes = {
	ref: PropTypes.object
};
