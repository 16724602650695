import React, { useMemo } from "react";

import AppTable from "components/app-table/app-table";

const AppReportDetailsQuotationsTable = (props) => {
	const data = useMemo(() => props.data?.map((o, i) => ({ ...o, paginationNumbers: i + 1 })), [props]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
        {
            name: "paginationNumbers",
            label: "#",
            options: {
                sort: false
            }
        },
        {
            name: "referenceNo",
            label: "Quotation ID",
            options: {
                sort: false
            }
        },
        {
            name: "quotationTitle",
            label: "Quotation Title",
            options: {
                sort: false
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                sort: false
            }
        }
    ], []);

	return (
		<div className="app-report-details-quotations-table">
			<div className="report-details-quotations-table">
				<AppTable data={data} columns={tableColumns} />
			</div>
		</div>
	);
};

export default AppReportDetailsQuotationsTable;
