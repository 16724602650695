import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ROLES from "constants/roles";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import editIcon from "assets/images/edit-icon.png";

const AppMobileAppUsersTable = () => {
	const navigate = useNavigate();
	const isMounted = useRef(true);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_USER], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const defaultStatus = useMemo(() => [STATUS.ACTIVE, STATUS.INACTIVE].join(","), []);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", status: searchParams.get("status") || defaultStatus, tab: "MOBILE_APP_USER" });
	const [data, setData] = useState(convertPaginationTableData());

	const formik = useFormik({
		initialValues: { status: [STATUS.ACTIVE, STATUS.INACTIVE] },
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			const modifiedParams = sanitizeObject({ ...queryParams.current, size: 10, name: queryParams.current.keyword, email: queryParams.current.keyword });

			response = await api.get.mobile.users(modifiedParams);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.status = defaultStatus;

		onHandleGetList();
	}, [formik, defaultStatus, onHandleGetList]);

	//prettier-ignore
	const onHandleEditCustomer = useCallback((tableMeta) => {
		const id = data.content[tableMeta.rowIndex]?.id;

		navigate(pathnames.customer.mobileAppCreateEdit + id);
	}, [data, navigate]);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Active", name: STATUS.ACTIVE },
			{ label: "Inactive", name: STATUS.INACTIVE }
		];

		return data;
	}, []);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
	}, [formik]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "customerPicContact.picName",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "customerPicContact.mobileNo",
			label: "Mobile Number",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					if (data.content[tableMeta.rowIndex]) {
						return data.content[tableMeta.rowIndex].customerPicContact?.mobileNoPrefix + value || "-";
					}

					return "-";
				}
			}
		},
		{
			name: "email",
			label: "Email Address",
			options: {
				sort: false
			}
		},
		{
			name: "createdDate",
			label: "Submit Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY_HH_MM_A)
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: false,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						!restricted && <button type="button" className="table__action" onClick={() => onHandleEditCustomer(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [data, restricted, onHandleEditCustomer ]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		if (isMounted.current) {
			isMounted.current = false;

			let status = searchParams.get("status");

			if (status) {
				status = status.split(",");

				memoSetFormValues({ status });
			}
		}
	}, [isMounted, memoSetFormValues, searchParams, defaultStatus]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.MOBILE.USERS);
		};
	}, [cancelRequest]);

	return (
		<div className="app-mobile-app-users-table">
			<div className="mobile-app-users-table">
				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Customer Alias or PIC Name or Email Address" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} restricted={restricted} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="mobile-app-users-table-filter">
						<div className="mobile-app-users-table-filter__status">
							<div className="mobile-app-users-table-filter__container">
								<p className="mobile-app-users-table-filter__label">Status</p>

								{menuFilterStatus.map((o) => {
									const isActive = formik.values.status.findIndex((i) => i === o.name) > -1;

									return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
								})}
							</div>
						</div>
					</div>
				</AppTableFilterHeader>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>
		</div>
	);
};

export default AppMobileAppUsersTable;
