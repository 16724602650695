import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import AppButton from "components/app-button";

export const AppWorkOrderInventoryRemoveModal = (props, ref) => {
	const dispatch = useDispatch();
	const [data, setData] = useState();
	const [visible, setVisible] = useState(false);
	const onHandleGetList = useMemo(() => props.onHandleGetList, [props.onHandleGetList]);

	const onHandleConfirm = useCallback(async () => {
		let response = null;

		try {
			await api.post.workOrder.deleteInventory({ "work-order-inventory-id": data?.id });

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			onHandleGetList();

			dispatch(promptLayoutAlertMessage({ message: "Inventory was successfully deleted!" }));

			setVisible(false);
		}
	}, [data?.id, dispatch, onHandleGetList]);

	//prettier-ignore
	const onHandleShow = useCallback((data) => {
		setData(data);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-work-order-inventory-remove-modal" }} open={visible}>
			<div className="work-order-inventory-remove-modal">
				<h1 className="work-order-inventory-remove-modal__title">Are you sure?</h1>

				<p className="work-order-inventory-remove-modal__description">
					After clicking confirm, the spare part {data?.inventoryBarcode} <br /> <span className="work-order-inventory-remove-modal__name">{data?.inventoryItem}</span> <br /> will be removed from the Spare Part Listing.
				</p>

				<div className="work-order-inventory-remove-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="submit" label="Confirm" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppWorkOrderInventoryRemoveModal));
