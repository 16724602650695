import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import COMMON from "common";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import pathnames from "routes/pathnames";

import classNames from "common/class-names";

import AppIcon from "components/app-icon";

import person from "assets/images/components/app-input/person-icon.png";
import defaultProfileIcon from "assets/images/default-profile-icon.png";
import logout from "assets/images/components/app-layout/logout-icon.png";
import chevronLeft from "assets/images/components/app-header/chevron-left-icon.png";
import notificationIcon from "assets/images/components/app-header/notification-icon.png";

const AppHeader = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const [dropdownActive, setDropdownActive] = useState(false);
	const dropdownRef = useRef();
	//prettier-ignore
	const dropdownClassName = useMemo(() => classNames({
		"header__dropdown": true,
		"header__dropdown--active": dropdownActive
	}), [dropdownActive]);

	const onHandleDropdownActive = () => {
		setDropdownActive((prev) => (prev = !prev));
	};
	const onHandleNavigateMyAccount = () => {
		navigate(pathnames.userAccount);
		setDropdownActive((prev) => !prev);
	};

	const onHandleOpenLogoutModal = useCallback(() => {
		setDropdownActive((prev) => !prev);

		localStorage.removeItem(COMMON.AUTH_TOKEN);
		localStorage.removeItem(COMMON.REFRESH_TOKEN);
		localStorage.removeItem(COMMON.ACCESSIBLE_PATH);
		sessionStorage.removeItem(COMMON.AUTH_TOKEN);
		sessionStorage.removeItem(COMMON.REFRESH_TOKEN);

		dispatch({ type: COMMON.REDUX_ACTION.LOGOUT });

		navigate(pathnames.login.login);
	}, [dispatch, navigate]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target) && dropdownActive) {
				setDropdownActive((prev) => !prev);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [dropdownActive]);

	return (
		<header className="app-header">
			<div className="header">
				<button id="header-menu" className="header__menu" onClick={props.onHandleToggleSideNav}>
					<AppIcon className="header__side-icon" src={chevronLeft} />
				</button>

				<div className="header__profile">
					<div className="header__notification">
						<img src={notificationIcon} alt="notifications" />
					</div>

					<div ref={dropdownRef} className="header__user">
						<div className="header__user-details" onClick={onHandleDropdownActive}>
							<div className="header__image" style={{ backgroundImage: `url(${profile.avatar || defaultProfileIcon})` }}></div>
							<div className="header__content">
								<p className="header__name">{profile.email || "-"}</p>
								<p className="header__text">{profile.role || "-"}</p>
							</div>
						</div>

						<div className={dropdownClassName}>
							<div className="header__dropdown-option" onClick={onHandleNavigateMyAccount}>
								<p className="header__description">My Account</p>
								<AppIcon src={person} />
							</div>

							<div className="header__dropdown-option" onClick={onHandleOpenLogoutModal}>
								<p className="header__description">logout</p>
								<AppIcon src={logout} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default memo(AppHeader);
