import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

import pathnames from "routes/pathnames";

import classNames from "common/class-names";
import getTotalDays from "common/get-total-days";

import PAGE from "constants/page";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";

import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import sendIcon from "assets/images/send.svg";
import addIcon from "assets/images/add-icon.png";
import editIcon from "assets/images/edit-icon.png";

const tempData = [
	{ id: 1, customerName: "Customer 1", mobileNumber: "123-456-7890", assignee: "Assignee 1", status: STATUS.COMPLETED, scheduledDate: "2024-07-01", completionTimeWindow: "9:00 AM - 5:00 PM" },
	{ id: 2, customerName: "Customer 2", mobileNumber: "123-456-7891", assignee: "Assignee 2", status: STATUS.IN_PROGRESS, scheduledDate: "2024-07-02", completionTimeWindow: "9:00 AM - 5:00 PM" },
	{ id: 3, customerName: "Customer 3", mobileNumber: "123-456-7892", assignee: "Assignee 3", status: STATUS.OPEN, scheduledDate: "2024-07-03", completionTimeWindow: "9:00 AM - 5:00 PM" },
	{ id: 4, customerName: "Customer 4", mobileNumber: "123-456-7893", assignee: "Assignee 4", status: STATUS.ASSIGNED, scheduledDate: "2024-07-04", completionTimeWindow: "9:00 AM - 5:00 PM" },
	{ id: 5, customerName: "Customer 5", mobileNumber: "123-456-7894", assignee: "Assignee 5", status: STATUS.OVERDUE, scheduledDate: "2024-07-05", completionTimeWindow: "9:00 AM - 5:00 PM" },
	{ id: 6, customerName: "Customer 6", mobileNumber: "123-456-7895", assignee: "Assignee 6", status: STATUS.SCHEDULED, scheduledDate: "2024-07-06", completionTimeWindow: "9:00 AM - 5:00 PM" }
];

const PageCorrectiveWorkOrder = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [data, setData] = useState(tempData);
	const [selectedPending, setSelectedPending] = useState([]);
	const defaultStatus = useMemo(() => [STATUS.OPEN, STATUS.ASSIGNED, STATUS.VERIFIED, STATUS.ACKNOWLEDGED, STATUS.CANCELLED, STATUS.SCHEDULED, STATUS.IN_PROGRESS, STATUS.COMPLETED, STATUS.OVERDUE, STATUS.RESCHEDULED].join(","), []);
	const status = useMemo(() => {
		const currentStatuses = searchParams.get("status")?.split(",");
		const relevantStatus = currentStatuses?.every((e) => defaultStatus.split(",").includes(e));

		if (!relevantStatus) {
			return defaultStatus;
		} else {
			return searchParams.get("status");
		}
	}, [defaultStatus, searchParams]);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", "start-date": searchParams.get("start-date") || "", "end-date": searchParams.get("end-date") || "", status: status });

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");

		if (values.startDate && dayjs(values.startDate).isValid()) {
			queryParams.current["start-date"] = dayjs(values.startDate).format(DATE_TIME.YYYY_MM_DD_HH_MM_SS);
		}

		if (values.endDate && dayjs(values.endDate).isValid()) {
			queryParams.current["end-date"] = dayjs(values.endDate).format(DATE_TIME.YYYY_MM_DD_HH_MM_SS);
		}
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;
	}, []);

	const validationSchema = yup.object({
		scheduledEndDate: yup.date().test(function (value, { parent }) {
			if (!value) return true;

			const totalDays = getTotalDays(parent.scheduledStartDate, value);

			return totalDays >= 0 && totalDays <= 90;
		})
	});

	const formik = useFormik({
		initialValues: {
			scheduledStartDate: "",
			scheduledEndDate: "",
			status: [STATUS.OPEN, STATUS.ASSIGNED, STATUS.VERIFIED, STATUS.ACKNOWLEDGED, STATUS.CANCELLED, STATUS.SCHEDULED, STATUS.IN_PROGRESS, STATUS.COMPLETED, STATUS.OVERDUE, STATUS.RESCHEDULED]
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const intructionClassName = useMemo(() => classNames({
		"corrective-work-order-filter__instruction": true,
		"corrective-work-order-filter__instruction--error": formik.errors.scheduledEndDate,
	}), [formik]);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
		formik.setFieldValue("start-date", queryParams.current["start-date"]);
		formik.setFieldValue("end-date", queryParams.current["end-date"]);
	}, [formik]);

	const onHandleSelectPending = useCallback((boolean, idNo) => {
		setSelectedPending((prev) => {
			let nextPending = [...prev];

			if (boolean) {
				nextPending.push(idNo);
			} else {
				nextPending = nextPending.filter((id) => id !== idNo);
			}

			return nextPending;
		});
	}, []);

	//prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		const ids = data.map((item) => item.id);

		setSelectedPending(boolean ? ids : []);
	}, [data]);

	//prettier-ignore
	const onHandleEditCorrective = useCallback((obj) => {
		navigate(pathnames.workOrder.workOrderCreateEdit + PAGE.EDIT);
	}, [navigate]);

	const onHandleCreateCorrective = useCallback(() => {
		navigate(pathnames.workOrder.workOrderCreateEdit + PAGE.CREATE);
	}, [navigate]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Open", name: STATUS.OPEN },
			{ label: "Scheduled", name: STATUS.SCHEDULED },
			{ label: "Verified", name: STATUS.VERIFIED },
			{ label: "Overdue", name: STATUS.OVERDUE },
			{ label: "Assigned", name: STATUS.ASSIGNED },
			{ label: "In-Progress", name: STATUS.IN_PROGRESS },
			{ label: "Acknowledged", name: STATUS.ACKNOWLEDGED },
			{ label: "Completed", name: STATUS.COMPLETED },
			{ label: "Cancelled", name: STATUS.CANCELLED },
			{ label: "Rescheduled", name: STATUS.RESCHEDULED }
		];

		return data;
	}, []);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter(o => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.status = defaultStatus;
		queryParams.current["start-date"] = "";
		queryParams.current["end-date"] = "";
	}, [defaultStatus, formik]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "checkbox",
			options: {
				sort: false,
				customHeadRender: () => {
					const selectedAll = data.length > 0 && selectedPending.length === data.length;

					return (
						<TableCell key="table-key" className="app-table__cell app-table__cell--header">
							<AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />
						</TableCell>
					);
				},
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tablePendingID = data[rowIndex]?.id;
					const selected = selectedPending.includes(tablePendingID);

					return (
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className="app-table__cell">
										<AppCheckbox className="checkbox" onClick={(v) => onHandleSelectPending(v, tablePendingID)} value={selected} />
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					);
				},
			},
		},	
		{
			name: "id",
			label: "Work Order ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "customerName",
			label: "Customer Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "mobileNumber",
			label: "Mobile Number",
			options: {
				sort: false,
			},
		},
		{
			name: "assignee",
			label: "Assignee",
			options: {
				sort: false,
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />,
			},
		},
		{
			name: "scheduledDate",
			label: "Scheduled Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "completionTimeWindow",
			label: "Completion Time Window",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__edit" onClick={() => onHandleEditCorrective(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [data, onHandleEditCorrective, onHandleSelectAll, onHandleSelectPending, selectedPending]);

	//prettier-ignore
	const HeaderLinkButton = useCallback((obj) => {
		if (!obj.selectedPending.length)
			return (
				<div className="corrective-work-order__create-button">
					<AppButton type="button" label="Create" icon={addIcon} onClick={obj.onCreate} />
				</div>
			);

		return (
			<div className="corrective-work-order__header-button">
				<AppButton outline type="button" label="Send for Signature" icon={sendIcon} />
			</div>
		);
	}, []);

	useEffect(() => {
		setData(tempData);
	}, []);

	return (
		<div className="page-corrective-work-order">
			<div className="corrective-work-order">
				<h1 className="corrective-work-order__title">Corrective Work Orders</h1>

				<div className="corrective-work-order__header">
					<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Work Order ID or Customer Name or Assignee" searchDefaultValue={queryParams.current.name} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
						<div className="corrective-work-order-filter">
							<div className="corrective-work-order-filter__container">
								<div className="corrective-work-order-filter__inputs">
									<AppInputDate name="scheduledStartDate" label="Scheduled Date" placeholder="Start Date" onChange={formik.setFieldValue} value={formik.values.scheduledStartDate} />

									<AppInputDate name="scheduledEndDate" placeholder="End Date" onChange={formik.setFieldValue} value={formik.values.scheduledEndDate} />
								</div>

								<p className={intructionClassName}>Please select a date or a date range of up to 90 days</p>
							</div>

							<div className="corrective-work-order-filter__container">
								<p className="corrective-work-order-filter__label">Status</p>

								<div className="corrective-work-order-filter__checkbox-status">
									{menuFilterStatus.map((o) => {
										const isActiveStatus = formik.values.status.findIndex((i) => i === o.name) > -1;

										return (
											<div className="corrective-work-order-filter__checkbox" key={o.label}>
												<AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActiveStatus} />
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</AppTableFilterHeader>

					<HeaderLinkButton selectedPending={selectedPending} onCreate={onHandleCreateCorrective} />
				</div>

				<div className="corrective-work-order__body">
					<AppTable data={data} columns={tableColumns} />
				</div>
			</div>
		</div>
	);
};

export default PageCorrectiveWorkOrder;
