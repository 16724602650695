const STATUS = {
	IN_USE: "IN_USE",
	RETIRED: "RETIRED",
	ACTIVE: "ACTIVE",
	INACTIVE: "INACTIVE",
	MISSING: "MISSING",
	NON_ACCESSIBLE: "NON_ACCESSIBLE",
	CLOSED: "CLOSED",
	RESOLVED: "RESOLVED",
	OPEN: "OPEN",
	ASSIGNED: "ASSIGNED",
	DRAFT: "DRAFT",
	PENDING_ASSIGN: "PENDING_ASSIGN",
	PENDING_ASSET: "PENDING_ASSET",
	PENDING_ACKNOWLEDGE: "PENDING_ACKNOWLEDGE",
	PENDING_VERIFY: "PENDING_VERIFY",
	PENDING_RESCHEDULE: "PENDING_RESCHEDULE",
	PENDING_CHECKLIST: "PENDING_CHECKLIST",
	IN_PROGRESS: "IN_PROGRESS",
	ON_HOLD: "ON_HOLD",
	PENDING: "PENDING",
	ISSUED: "ISSUED",
	RECEIVED: "RECEIVED",
	APPROVED: "APPROVED",
	REJECTED: "REJECTED",
	CANCELLED: "CANCELLED",
	PERMANENT: "PERMANENT",
	INTERN: "INTERN",
	PROBATION: "PROBATION",
	TERMINATED: "TERMINATED",
	WORKING: "WORKING",
	ON_LEAVE: "ON_LEAVE",
	UNACCOUNTED_ABSENCE: "UNACCOUNTED_ABSENCE",
	COMPLETED: "COMPLETED",
	VERIFIED: "VERIFIED",
	ACKNOWLEDGED: "ACKNOWLEDGED",
	SCHEDULED: "SCHEDULED",
	OVERDUE: "OVERDUE",
	RESCHEDULED: "RESCHEDULED",
	FORCE_CHANGE_PASSWORD: "FORCE_CHANGE_PASSWORD",
	WON: "WON",
	WON_WITH_EXCEPTION: "WON_WITH_EXCEPTION",
	LOST: "LOST",
	PAID: "PAID",
	UNPAID: "UNPAID",
	APPROVED_BY_SUPERVISOR: "APPROVED_BY_SUPERVISOR",
	DRAFT_WITH_EXCEPTION: "DRAFT_WITH_EXCEPTION",
	COMPLETED_WITH_EXCEPTION: "COMPLETED_WITH_EXCEPTION",
	DROP_OFF: "DROP_OFF",
	VOID: "VOID",
	DELETED: "DELETED"
};

export default STATUS;
