import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import useBreadcrumb from "hooks/use-breadcrumb";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ENDPOINT_PATH from "constants/end-point-path";

import AppTabs from "components/app-tabs";
import AppClaimSummary from "components/pages/human-resources/app-claim-summary-table";
import AppClaimHistoryTable from "components/pages/human-resources/app-claim-history-table";
import AppPendingClaimTable from "components/pages/human-resources/app-pending-claim-table";

import claimIcon from "assets/images/pages/human-resources/claim.svg";
import clockIcon from "assets/images/pages/human-resources/clock-icon.svg";
import activeClaimIcon from "assets/images/pages/human-resources/claim-active.svg";
import activeClockIcon from "assets/images/pages/human-resources/clock-active-icon.svg";
import claimApprovalIcon from "assets/images/pages/human-resources/claim-approval-icon.svg";
import activeClaimApprovalIcon from "assets/images/pages/human-resources/claim-approval-active-icon.svg";

const PageViewClaims = () => {
	const { id } = useParams();
	const profile = useSelector((state) => state.profile);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [isHR, setIsHR] = useState();
	const [employeeId, setEmployeeId] = useState();

	const formik = useFormik({
		initialValues: {
			employeeName: "",
			employeeId: "",
			status: ""
		}
	});

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Claim Management", path: pathnames.humanResources.claimManagement },
			{ label: "All Claim", path: pathnames.humanResources.claimManagement + "?tab=EMPLOYEE_DIRECTORY" },
			{ label: `${formik.values.employeeId} ${formik.values.employeeName} Claim`, path: pathnames.humanResources.viewClaims + id }
		];

		return data;
	}, [formik.values, id]);

	useBreadcrumb({ breadCrumb });

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	// prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		const params = sanitizeObject({
			"employee-id": uniqueId
		});

		try {
			response = await api.get.humanResource.employeeClaimSummary(params);
        } catch (error) {
            serveLayoutRequestErrors(error);
        }
 
        if (response) {
            memoSetFormValues({
                employeeName: response[0].employeeName,
                employeeId: response[0].employeeId,
            });
        }
    }, [memoSetFormValues]);

	const onHandleGetEmployeeDetail = useCallback(async (uniqueId) => {
		let response = null;

		try {
			const params = sanitizeObject({ "user-id": uniqueId });

			response = await api.get.humanResource.employeeHR(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setIsHR(!response.supervisor && !response.reportingManager);

			setEmployeeId(response.id);
		}
	}, []);

	//prettier-ignore
	const viewClaimTabs = useMemo(() => [
        { label: "Claim Summary", icon: claimIcon, activeIcon: activeClaimIcon, name: "CLAIM_SUMMARY", component: <AppClaimSummary />, accessible: true },
        { label: "Claim History", icon: clockIcon, activeIcon: activeClockIcon, name: "CLAIM_HISTORY", component: <AppClaimHistoryTable isHR={isHR} />, accessible: true },
        { label: "Pending Claim", icon: claimApprovalIcon, activeIcon: activeClaimApprovalIcon, name: "PENDING_CLAIM", component: <AppPendingClaimTable isHR={isHR} employeeId={employeeId} />, accessible: true },
    ], [employeeId, isHR]);

	useEffect(() => {
		onHandleGetDetails(id);

		onHandleGetEmployeeDetail(profile.id);
	}, [id, onHandleGetEmployeeDetail, onHandleGetDetails, profile.id]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_CLAIM_SUMMARY);

			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_HR);
		};
	}, [cancelRequest]);

	return (
		<div className="page-view-claims">
			<div className="view-claims">
				<div className="view-claims__header">
					<h1 className="view-claims__title">{formik.values.employeeName}</h1>

					<h1 className="view-claims__id">{formik.values.employeeId}</h1>
				</div>

				<div className="view-claims__body">
					<AppTabs tabs={viewClaimTabs} />
				</div>
			</div>
		</div>
	);
};

export default PageViewClaims;
