import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import { Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import STATUS from "constants/status";
import ENDPOINT_PATH from "constants/end-point-path";

import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppEmployeeDirectoryViewContactModal from "components/pages/human-resources/app-employee-directory-view-contact-modal";

import eyeIcon from "assets/images/eye-open-icon.png";
import moreIcon from "assets/images/vertical-breadcrumbs-icon.png";
import phoneIcon from "assets/images/pages/human-resources/phone-icon.svg";

const AppEmployeeDirectoryTable = () => {
	const selectedItem = useRef();
	const navigate = useNavigate();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const [menuAnchor, setMenuAnchor] = useState(null);
	const defaultStatus = useMemo(() => [STATUS.WORKING, STATUS.ON_LEAVE, STATUS.UNACCOUNTED_ABSENCE].join(","), []);
	const status = useMemo(() => {
		const currentStatuses = searchParams.get("status")?.split(",");
		const relevantStatus = currentStatuses?.every((e) => defaultStatus.split(",").includes(e));

		if (!relevantStatus) {
			return defaultStatus;
		} else {
			return searchParams.get("status");
		}
	}, [defaultStatus, searchParams]);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", todayStatus: status, tab: "EMPLOYEE_DIRECTORY" });
	const [data, setData] = useState(convertPaginationTableData());
	const viewContactRef = useRef();

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.humanResource.attendanceEmployeeDirectory(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.todayStatus = values.status.join(",");

		onHandleGetList();
	}, [onHandleGetList]);

	const formik = useFormik({
		initialValues: { startDate: "", endDate: "", status: [STATUS.WORKING, STATUS.ON_LEAVE, STATUS.UNACCOUNTED_ABSENCE] },
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onViewAttendance = useCallback(() => {
		navigate(pathnames.humanResources.viewAttendance + selectedItem.current.staffUserId + "/" + selectedItem.current.id);
	}, [navigate]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Working", name: STATUS.WORKING },
			{ label: "On Leave", name: STATUS.ON_LEAVE },
			{ label: "Unaccounted Absence", name: STATUS.UNACCOUNTED_ABSENCE }
		];

		return data;
	}, []);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.todayStatus ? queryParams.current.todayStatus.split(",") : []);
	}, [formik]);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.todayStatus = defaultStatus;

		onHandleGetList();
	}, [defaultStatus, formik, onHandleGetList]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleCloseHolidayTableMenu = useCallback(() => {
		selectedItem.current = null;

		setMenuAnchor(null);
	}, []);

	//prettier-ignore
	const onToggleTableAction = useCallback((event, tableMeta) => {
		selectedItem.current = { ...data.content[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		setMenuAnchor(event.currentTarget);
	}, [data]);

	const onHandleViewContact = useCallback(() => {
		viewContactRef.current.onHandleShow(selectedItem.current.id);
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "employeeId",
			label: "Employee ID",
			options: {
				sort: false
			}
		},
		{
			name: "fullName",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "primaryMobileNo",
			label: "Mobile Number",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					if (data.content[tableMeta.rowIndex]) {
						return data.content[tableMeta.rowIndex].primaryMobileNoPrefix + value || "-";
					}

					return "-";
				}
			}
		},
		{
			name: "todayStatus",
			label: "Today's Status",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => <AppStatus status={value} />
			}
		},
		{
			name: "edit",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={(event) => onToggleTableAction(event, tableMeta)}>
							<img src={moreIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [data.content, onToggleTableAction]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.ATTENDANCE_EMPLOYEE_DIRECTORY);
		};
	}, [cancelRequest]);

	return (
		<div className="app-employee-directory-table">
			<div className="employee-directory-table">
				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Name or Employee ID" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="employee-directory-table-filter">
						<div className="employee-directory-table-filter__container">
							<p className="employee-directory-table-filter__label">Status</p>

							{menuFilterStatus.map((o) => {
								const isActive = formik.values.status.findIndex((i) => i === o.name) > -1;

								return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
							})}
						</div>
					</div>
				</AppTableFilterHeader>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>

			<Menu classes={{ root: "employee-directory-table-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseHolidayTableMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onViewAttendance}>
					<img src={eyeIcon} alt="view-attandance" />
					View Attendance
				</MenuItem>

				<MenuItem onClick={onHandleViewContact}>
					<img src={phoneIcon} alt="view-employee-contact" />
					View Employee's Contact
				</MenuItem>
			</Menu>

			<AppEmployeeDirectoryViewContactModal ref={viewContactRef} />
		</div>
	);
};

export default AppEmployeeDirectoryTable;
