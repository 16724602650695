import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import ERRORS from "constants/errors";
import STATUS from "constants/status";

import AppInput from "components/app-input";
import AppButton from "components/app-button";

const AppPendingCorrectiveAttendanceRejectionEmployeeModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [selectedData, setSelectedData] = useState([]);
	const [cancelStatus, setCancelStatus] = useState([]);

	const initialValues = useMemo(() => {
		const values = { rejectReason: "" };

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			rejectReason: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleShow = useCallback((values, isCancel) => {
		setSelectedData(values);

		setCancelStatus(isCancel);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	//prettier-ignore
	const onHandleBack = useCallback((id) => {
		props.onHandleBack(id);

		setVisible(false);

		formik.resetForm();
	}, [formik, props]);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		const updatedData = selectedData.map((item) => {
			return { ...item, rejectReason: values.rejectReason };
		});

		props.onHandleSubmit(updatedData, STATUS.REJECTED);

		onHandleDismiss();
	}, [onHandleDismiss, props, selectedData]);

	const FooterCancelButton = useCallback((obj) => {
		if (obj.cancelStatus) return <AppButton outline type="button" label="Cancel" onClick={obj.onHandleDismiss} />;

		return <AppButton outline type="button" label="Back" onClick={() => obj.onHandleBack(obj.selectedData)} />;
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-pending-corrective-attendance-rejection-employee-modal" }} open={visible}>
			<div className="pending-corrective-attendance-rejection-employee-modal">
				<form className="pending-corrective-attendance-rejection-employee-modal__form" onSubmit={formik.handleSubmit}>
					<h1 className="pending-corrective-attendance-rejection-employee-modal__title">Reason for Corrective Attendance Rejection</h1>

					{selectedData.length > 1 && <p className="pending-corrective-attendance-rejection-employee-modal__description">The reject reason will apply to all selected Corrective Attendance.</p>}

					<AppInput multiline required type="textarea" maxLength={255} name="rejectReason" label="Reject Reason" placeholder="Enter Reject Reason" value={formik.values.rejectReason} error={formik.errors.rejectReason} touched={formik.touched.rejectReason} onChange={formik.handleChange} />

					<div className="pending-corrective-attendance-rejection-employee-modal__button-container">
						<FooterCancelButton cancelStatus={cancelStatus} onHandleDismiss={onHandleDismiss} onHandleBack={onHandleBack} selectedData={selectedData} />

						<AppButton type="submit" label="Confirm" />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppPendingCorrectiveAttendanceRejectionEmployeeModal));

AppPendingCorrectiveAttendanceRejectionEmployeeModal.propTypes = {
	onHandleBack: PropTypes.func,
	onHandleSubmit: PropTypes.func
};
