import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import Menu from "@mui/material/Menu";
import { useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import { TableCell, TableRow } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";
import getPICListing from "services/get-pic-listing";
import getStateListing from "services/get-state-listing";
import getRegionListing from "services/get-region-listing";
import getPostcodeListing from "services/get-postcode-listing";
import getRegisteredNameListing from "services/get-registered-name-listing";
import getWorkInspectionListing from "services/get-work-inspection-listing";
import getPaymentCurrencyListing from "services/get-payment-currency-listing";

import useBreadcrumb from "hooks/use-breadcrumb";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import classNames from "common/class-names";
import sanitizeObject from "common/sanitize-object";
import capitalizeCharacter from "common/capitalize-character";
import convertPaginationTableData from "common/convert-pagination-table-data";
import formatCurrencyPattern, { formatCurrency } from "common/format-currency-pattern";
import serveRequestErrors, { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ERRORS from "constants/errors";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppCloseQuotationModal from "components/pages/sales/app-close-quotation-modal";
import AppCreateQuotationModal from "components/pages/sales/app-create-quotation-modal";
import AppQuotationAddItemModal from "components/pages/sales/app-quotation-add-item-modal";
import AppQuotationDeleteItemModal from "components/pages/sales/app-quotation-delete-item-modal";
import AppConvertToSalesOrderModal from "components/pages/sales/app-convert-to-sales-order-modal";
import AppConvertWithExceptionModal from "components/pages/sales/app-convert-with-exception-modal";

import editIcon from "assets/images/edit-icon.png";
import addIcon from "assets/images/add-blue-icon.png";
import trashhIcon from "assets/images/trash-icon.png";
import downloadIcon from "assets/images/download-icon.png";
import moreIcon from "assets/images/vertical-breadcrumbs-icon.png";
import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";

const PageQuotationCreateEdit = (props) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const title = useMemo(() => (isCreate ? "Create Quotation" : "Edit Quotation"), [isCreate]);
	const [itemTableAnchor, setItemTableAnchor] = useState(null);
	const [subItemTableAnchor, setSubItemTableAnchor] = useState(null);
	const [rowsExpanded, setRowsExpanded] = useState([]);
	const [selectedRowItem, setSelectedRowItem] = useState(null);
	const saveLabel = useMemo(() => (isCreate ? "Save as Draft" : "Update Draft"), [isCreate]);
	const [statusRestrict, setStatusRestrict] = useState(false);
	const [visibility, setVisibility] = useState([]);
	const quotationAddItemModalRef = useRef();
	const quotationDeleteItemModalRef = useRef();
	const quotationCreateModalRef = useRef();
	const quotationClosedModalRef = useRef();
	const quotationConvertToSalesOrderModalRef = useRef();
	const quotationConvertWithExceptionRef = useRef();
	const selectedItem = useRef();
	const selectedSubItem = useRef();
	const queryParams = useRef({ page: 0, keyword: "" });
	const queryLineParams = useRef({ page: 0, size: 10 });
	const [referenceNo, setReferenceNo] = useState("");
	const [linesTableData, setLinesTableData] = useState(convertPaginationTableData());

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Sales", path: pathnames.sales.quotations },
			{ label: "Quotations", path: pathnames.sales.quotations }
		];

		if (isCreate) {
			data.push({ label: "Create Quotation", path: pathnames.sales.quotationCreateEdit + PAGE.CREATE });
		}

		if (!isCreate) {
			data.push({ label: `${"Edit Quotation " + referenceNo}`, path: pathnames.sales.quotationCreateEdit + id });
		}

		return data;
	}, [isCreate, id, referenceNo]);

	useBreadcrumb({ breadCrumb });

	const initialValues = useMemo(() => {
		const values = {
			createFlag: false,
			customerId: "",
			picContactId: "",
			quotationTitle: "",
			workInspectionId: "",
			addressLine1: "",
			addressLine2: "",
			postcode: "",
			city: "",
			state: "",
			validityInDays: "",
			taxFlag: true,
			taxPercent: "",
			discountFlag: true,
			discountAmount: 0,
			internalNote: "",
			customerNote: "",
			partsLeadTime: "",
			mobilizationLeadTime: "",
			warranty: "",
			paymentCurrency: "",
			lineItems: [],
			quotationId: "",
			status: STATUS.DRAFT,
			prefixNo: "",
			mobileNo: "",
			email: "",
			subtotalAmount: "",
			taxAmount: "",
			totalAmount: "",
			paymentTerm: "",
			lastModifiedBy: "",
			lastModifiedDate: ""
		};

		return values;
	}, []);

	//prettier-ignore
	const validationSchema = yup.object({
		customerId: yup.string().required(ERRORS.REQUIRED),
		picContactId: yup.string().required(ERRORS.REQUIRED),
		addressLine1: yup.string().required(ERRORS.REQUIRED),
		state: yup.string().required(ERRORS.REQUIRED),
		city: yup.string().required(ERRORS.REQUIRED),
		postcode: yup.string().required(ERRORS.REQUIRED),
		validityInDays: yup.string().required(ERRORS.REQUIRED),
		quotationTitle: yup.string().required(ERRORS.REQUIRED),
		discountAmount: yup.number().nullable().when(["discountFlag"], {
			is: true,
			then: () => yup.number().required(ERRORS.REQUIRED).test("discount-less-than-subtotalAmount", "Discount cannot exceed the subtotal amount", function (value) {
				const { subtotalAmount } = this.parent;
	
				return value <= subtotalAmount;
			}),
		}),
		taxPercent: yup.number().when(["taxFlag"], {
			is: true,
			then: () => yup.number().required(ERRORS.REQUIRED)
		}),
	});

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleBack = useCallback(() => {
		navigate(pathnames.sales.quotations);
	}, [navigate]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const processedLineItems = values.lineItems.map((lineItem) => {
				if (!lineItem.hasOwnProperty("lineSubItems")) {
					lineItem.lineSubItems = [];
				}

				if (lineItem.unitPrice !== undefined) {
					lineItem.unitPrice = parseFloat(lineItem.unitPrice).toFixed(2).toString();
				}

				lineItem.lineSubItems = lineItem.lineSubItems.map((subItem) => {
					if (!subItem.unitPrice) {
						subItem.unitPrice = parseFloat(subItem.unitPrice).toFixed(2).toString();
					}

					return subItem;
				});

				return lineItem;
			});

			const payload = {
				createFlag: values.createFlag,
				customerId: values.customerId,
				picContactId: values.picContactId,
				quotationTitle: values.quotationTitle,
				workInspectionId: values.workInspectionId || "",
				addressLine1: values.addressLine1,
				addressLine2: values.addressLine2 || "",
				postcode: values.postcode,
				city: values.city,
				state: values.state,
				validityInDays: values.validityInDays,
				taxFlag: values.taxFlag,
				taxPercent: values.taxPercent || 0,
				discountFlag: values.discountFlag,
				discountAmount: values.discountAmount || "0.00",
				internalNote: values.internalNote || "",
				customerNote: values.customerNote || "",
				partsLeadTime: values.partsLeadTime || "",
				mobilizationLeadTime: values.mobilizationLeadTime || "",
				warranty: values.warranty || "",
				paymentCurrency: values.paymentCurrency || "",
				lineItems: processedLineItems,
				id: id
			};

			if (isCreate) {
				response = await api.post.quotation.createQuotation(payload);
			}

			if (!isCreate) {
				response = await api.post.quotation.updateQuotation(payload);
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (values.createFlag) {
				dispatch(promptLayoutAlertMessage({ message: "Quotation was created successfully!" }));
			} else {
				dispatch(promptLayoutAlertMessage({ message: "Draft was created successfully!" }));
			}

			onHandleBack();
		}
	}, [onHandleBack, id, formik, isCreate, dispatch]);

	//prettier-ignore
	const onToggleTableAction = useCallback((event, data) => {
		selectedItem.current = { ...formik.values.lineItems[data.rowIndex], rowIndex: data.rowIndex };

		selectedSubItem.current = null;

		setItemTableAnchor(event.currentTarget);
	}, [formik.values.lineItems]);

	//prettier-ignore
	const onToggleSubTableAnchor = useCallback((event, tableData, subItemIndex) => {
		selectedItem.current = { ...formik.values.lineItems[tableData.rowIndex], rowIndex: tableData.rowIndex };

		selectedSubItem.current = subItemIndex;

		setSubItemTableAnchor(event.currentTarget);
	}, [formik]);

	const onHandleCloseMenu = useCallback(() => {
		setItemTableAnchor(false);

		setSubItemTableAnchor(false);

		selectedItem.current = null;

		selectedSubItem.current = null;
	}, []);

	const onHandleAddQuotationItem = useCallback(() => {
		setItemTableAnchor(false);

		quotationAddItemModalRef.current.onHandleShow();
	}, []);

	//prettier-ignore
	const onHandleEditQuotationItem = useCallback(() => {
		setItemTableAnchor(false);

		setSubItemTableAnchor(false);

		const itemIndex = selectedItem.current.rowIndex;
		const subItemIndex = selectedSubItem.current;
		const itemToEdit = subItemIndex !== null ? selectedItem.current.lineSubItems[subItemIndex] : formik.values.lineItems[itemIndex];

		quotationAddItemModalRef.current.onHandleShowEdit(itemToEdit, itemIndex, subItemIndex);
	}, [formik.values.lineItems]);

	const onHandleAddSubQuotationItem = useCallback(() => {
		setItemTableAnchor(false);

		let nextItems = [...formik.values.lineItems];

		const parentItemId = nextItems[selectedItem.current.rowIndex]?.id;

		quotationAddItemModalRef.current.onHandleShowSubItem(parentItemId);
	}, [formik.values.lineItems]);

	const onHandleConfirmRemoveQuotationItem = useCallback(() => {
		setItemTableAnchor(false);

		setSubItemTableAnchor(false);

		let payload = {};

		if (typeof selectedSubItem.current === "number") {
			payload = selectedItem.current.lineSubItems[selectedSubItem.current];
		} else {
			payload = selectedItem.current;
		}

		quotationDeleteItemModalRef.current.onHandleShow(payload);
	}, []);

	const onHandleConfirmCreateQuotation = useCallback(() => {
		quotationCreateModalRef.current.onHandleShow(formik.values.quotationId);
	}, [formik]);

	const onHandleRemoveQuotationItem = useCallback(async () => {
		let response = null;
		let nextItems = [...formik.values.lineItems];

		if (typeof selectedSubItem.current === "number") {
			const subItemId = nextItems[selectedItem.current.rowIndex].lineSubItems[selectedSubItem.current].id;

			if (subItemId) {
				let payload = {
					"subItem-id": subItemId
				};

				try {
					await api.post.quotation.removeSubItem(payload);

					response = true;
				} catch (error) {
					serveLayoutRequestErrors(error);
				}

				if (response) {
					dispatch(promptLayoutAlertMessage({ message: "Sub-Item was removed successfully!" }));
				}
			}

			nextItems[selectedItem.current.rowIndex].lineSubItems.splice(selectedSubItem.current, 1);
		} else {
			let response = null;
			const itemId = nextItems[selectedItem.current.rowIndex].id;

			if (itemId) {
				let payload = {
					"item-id": itemId
				};

				try {
					await api.post.quotation.removeItem(payload);

					response = true;
				} catch (error) {
					serveLayoutRequestErrors(error);
				}

				if (response) {
					dispatch(promptLayoutAlertMessage({ message: "Item was removed successfully!" }));
				}
			}

			nextItems = nextItems.filter((_, i) => i !== selectedItem.current.rowIndex);
		}

		formik.setFieldValue("lineItems", nextItems);

		onHandleCloseMenu();
	}, [onHandleCloseMenu, formik, dispatch]);

	const onHandleCreateQuotation = useCallback(async () => {
		let payload = { ...formik.values, createFlag: true };

		onHandleSubmit(payload);
	}, [formik.values, onHandleSubmit]);

	const calculateSubItemTotal = (subItem) => {
		const quantity = parseFloat(subItem.quantity) || 0;
		const unitPrice = parseFloat(subItem.unitPrice) || 0;
		const subItemTotal = quantity * unitPrice;

		return subItemTotal;
	};

	const calculateSubtotal = useCallback((lineItems) => {
		return lineItems.reduce((total, item) => {
			let itemTotal = 0;

			if (item.serviceType === "CHARGEABLE") {
				itemTotal += parseFloat(item.lineItemTotalAmount) || 0;
			}

			if (item.lineSubItems && item.lineSubItems.length > 0) {
				itemTotal += item.lineSubItems.reduce((subTotal, subItem) => {
					if (subItem.serviceType === "CHARGEABLE") {
						const subItemTotal = calculateSubItemTotal(subItem);

						return subTotal + subItemTotal;
					}

					return subTotal;
				}, 0);
			}

			return total + itemTotal;
		}, 0);
	}, []);

	//prettier-ignore
	const calculateTotals = useCallback((lineItems, taxPercent, discountAmount) => {
		const subtotalAmount = calculateSubtotal(lineItems);
		const taxAmount = (subtotalAmount * Number(taxPercent)) / 100;
		const totalAmount = subtotalAmount + taxAmount - (parseFloat(discountAmount) || 0);

		return {
			subtotalAmount: subtotalAmount.toFixed(2),
			taxAmount: taxAmount.toFixed(2),
			totalAmount: totalAmount.toFixed(2)
		};
	}, [calculateSubtotal]);

	//prettier-ignore
	const onHandleCellSelect = useCallback((cell, cellData) => {
		if (cell?.type) return;

		const item = formik.values.lineItems[cellData.rowIndex];

		if (item) setSelectedRowItem(item);
	}, [formik]);

	//prettier-ignore
	const onHandleSubCellSelect = useCallback((rowIndex, index) => {
		const item = formik.values.lineItems[rowIndex].lineSubItems[index];

		if (item) setSelectedRowItem(item);
	}, [formik]);

	const onHandleExpandCell = useCallback((index) => {
		setRowsExpanded((prev) => (prev.includes(index) ? [] : [index]));
	}, []);

	const processLineItems = useCallback((lineItems) => {
		return lineItems.map((item, index) => ({
			number: index + 1,
			id: item.id,
			title: item.title,
			configServiceRefNo: item?.configService?.referenceNo,
			referenceNo: item?.referenceNo,
			serviceId: item.serviceId,
			serviceType: item.serviceType,
			description: item.description,
			quantity: item.quantity,
			unitName: item.unitName,
			unitPrice: item.unitPrice,
			lineItemTotalAmount: item.lineItemTotalAmount,
			lineSubItems: item.lineSubItems
		}));
	}, []);

	const tableColumns = useMemo(() => {
		const display = [
			{
				name: "number",
				label: "#",
				options: {
					sort: true,
					sortThirdClickReset: true,
					customBodyRender: (value, tableMeta) => {
						const item = formik.values?.lineItems[tableMeta.rowIndex];
						const expandedButtonClassNames = classNames({
							"table__expandable-button": true,
							"table__expandable-button--expanded": rowsExpanded.includes(tableMeta.rowIndex)
						});

						if (item?.lineSubItems?.length) {
							return (
								<div className="table__expandable">
									{value}

									<button type="button" className={expandedButtonClassNames} onClick={() => onHandleExpandCell(tableMeta.rowIndex)}>
										<div className="table__expandable-icon" />
									</button>
								</div>
							);
						}

						return value;
					}
				}
			},
			{
				name: "title",
				label: "Title",
				options: {
					sort: true,
					sortThirdClickReset: true
				}
			},
			{
				name: "serviceId",
				label: "Service ID",
				options: {
					sort: true,
					sortThirdClickReset: true,
					customBodyRender: (value, tableMeta) => {
						const item = formik.values?.lineItems[tableMeta.rowIndex];

						return item?.referenceNo || item?.configService?.referenceNo;
					}
				}
			},
			{
				name: "quantity",
				label: "Quantity",
				options: {
					sort: true,
					sortThirdClickReset: true
				}
			},
			{
				name: "unitName",
				label: "Unit",
				options: {
					sort: true,
					sortThirdClickReset: true
				}
			},
			{
				name: "unitPrice",
				label: "Price Per Unit (MYR)",
				options: {
					sort: true,
					sortThirdClickReset: true,
					setCellHeaderProps: () => ({ className: "table__price" }),
					setCellProps: () => ({ className: "table__price" }),
					customBodyRender: (value, tableMeta) => {
						return formatCurrency(value);
					}
				}
			},
			{
				name: "lineItemTotalAmount",
				label: "Amount (MYR)",
				options: {
					sort: true,
					sortThirdClickReset: true,
					setCellHeaderProps: () => ({ className: "table__price" }),
					setCellProps: () => ({ className: "table__price" }),
					customBodyRender: (value, tableMeta) => {
						return formatCurrency(value);
					}
				}
			}
		];

		const action = [
			{
				name: "action",
				label: "Action",
				options: {
					sort: false,
					customBodyRender: (value, tableMeta) => {
						return (
							<button type="button" className="table__action" onClick={(event) => onToggleTableAction(event, tableMeta)}>
								<img src={moreIcon} alt="action-icon" />
							</button>
						);
					}
				}
			}
		];

		return [...display, ...(statusRestrict ? [] : action)];
	}, [rowsExpanded, onToggleTableAction, onHandleExpandCell, formik.values.lineItems, statusRestrict]);

	//prettier-ignore
	const onHandleRenderExpandedRow = useCallback((rowData, tableMeta) => {
		const items = formik.values.lineItems?.[tableMeta.rowIndex]?.lineSubItems;

		let rowIndex = tableMeta.rowIndex;

		return items?.map((o, i) => {
			return (
				<TableRow key={i} className="table__sub-item" onClick={(o) => onHandleSubCellSelect(rowIndex, i)}>
					<TableCell className="table__numbering">{(rowIndex + 1) + "." + (i + 1) + "."}</TableCell>

					<TableCell>{o.title}</TableCell>

					<TableCell>{ }</TableCell>

					<TableCell>{o.quantity}</TableCell>

					<TableCell>{o.unitName}</TableCell>

					<TableCell className="table__price">{formatCurrency(o.unitPrice)}</TableCell>

					<TableCell className="table__price">{formatCurrency(o.lineItemTotalAmount || o.lineSubItemTotalAmount)}</TableCell>

					{!statusRestrict && (
						<TableCell>
							<button type="button" className="table__action" onClick={(event) => onToggleSubTableAnchor(event, tableMeta, i)}>
								<img src={moreIcon} alt="edit-icon" />
							</button>
						</TableCell>
					)}
				</TableRow>
			);
		});
	}, [formik.values.lineItems, statusRestrict, onHandleSubCellSelect, onToggleSubTableAnchor]);

	const emptyState = useMemo(() => {
		if (formik.values.lineItems.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={tableColumns.length} align="center">
						<p className="table__text">
							No Items Added.
							<span className="table__link" onClick={onHandleAddQuotationItem}>
								Add Items?
							</span>
						</p>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [formik.values.lineItems, tableColumns, onHandleAddQuotationItem]);

	const SelectedItemDetails = useCallback((obj) => {
		if (!obj.selectedRowItem) return null;

		const firstItem = obj.selectedRowItem.number === 1;
		const lastItem = obj.items.length === obj.selectedRowItem.number;

		let selected = obj.items.find((i) => i.id === obj.selectedRowItem.id);

		const onHandleNextItem = () => {
			setSelectedRowItem(obj.items[selected.number]);
		};

		const onHandlePrevItem = () => {
			setSelectedRowItem(obj.items[selected.number - 2]);
		};

		return (
			<div className="quotation-create-edit__item-table">
				<div className="item-table">
					<div className="item-table__content">
						<div className="item-table__item">
							<p className="item-table__label">Title</p>

							<p className="item-table__value">{obj.selectedRowItem.title}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Service ID</p>

							<p className="item-table__value">{obj.selectedRowItem?.referenceNo || obj.selectedRowItem?.configService?.referenceNo || obj.selectedItem?.configServiceRefNo}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Service Type</p>

							<p className="item-table__value">{obj.selectedRowItem.serviceType}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Description</p>

							<p className="item-table__value">{obj.selectedRowItem.description}</p>
						</div>
					</div>

					<div className="item-table__pagination">
						<button type="button" className="item-table__button item-table__button--prev" disabled={firstItem} onClick={onHandlePrevItem}>
							<img src={chevronIcon} alt="pagination-prev" />
						</button>

						<p className="item-table__page">item of {obj.items.length}</p>

						<button type="button" className="item-table__button item-table__button--next" disabled={lastItem} onClick={onHandleNextItem}>
							<img src={chevronIcon} alt="pagination-next" />
						</button>
					</div>
				</div>
			</div>
		);
	}, []);

	const getRegionLoadOptions = useCallback(() => getRegionListing({ state: formik.values.state }), [formik.values.state]);

	const getPostcodeLoadOptions = useCallback(() => getPostcodeListing(`${formik.values.state}/${formik.values.city}`), [formik.values.state, formik.values.city]);

	//prettier-ignore
	const onHandleValidateForm = useCallback(() => {
		formik.validateForm().then((errors) => {
			if (Object.keys(errors).length === 0) {
				onHandleConfirmCreateQuotation();
			} else {
				formik.setTouched({ ...formik.touched, ...Object.keys(errors).reduce((acc, field) => { acc[field] = true; return acc }, {}) });
			}
		});
	}, [formik, onHandleConfirmCreateQuotation]);

	//prettier-ignore
	const onHandleEditItem = useCallback((updatedItem, rowIndex, subItemIndex) => {
		const nextItems = [...formik.values.lineItems];

		if (subItemIndex !== null) {
			nextItems[rowIndex].lineSubItems[subItemIndex] = updatedItem;
		} else {
			nextItems[rowIndex] = { ...nextItems[rowIndex], ...updatedItem };
		}

		const { subtotalAmount, taxAmount, totalAmount } = calculateTotals(nextItems, formik.values.taxPercent, formik.values.discountAmount);

		formik.setValues({
			...formik.values,
			lineItems: nextItems,
			subtotalAmount,
			taxAmount,
			totalAmount
		});
	}, [formik, calculateTotals]);

	const onHandleTaxPercentChange = (event) => {
		const newTaxPercent = event.target.value;
		const { subtotalAmount, taxAmount, totalAmount } = calculateTotals(formik.values.lineItems, newTaxPercent, formik.values.discountAmount);

		formik.setValues({
			...formik.values,
			taxPercent: newTaxPercent,
			subtotalAmount,
			taxAmount,
			totalAmount
		});
	};

	const onHandleDiscountAmountChange = (event) => {
		const newDiscountAmount = event.target.value;
		const { subtotalAmount, taxAmount, totalAmount } = calculateTotals(formik.values.lineItems, formik.values.taxPercent, newDiscountAmount);

		formik.setValues({
			...formik.values,
			discountAmount: newDiscountAmount,
			subtotalAmount,
			taxAmount,
			totalAmount
		});
	};

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);
	const workInspectionIdKeyword = useRef(formik.values?.workInspectionId);

	//prettier-ignore
	const onHandleGetPaymentTerm = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.customer.customer(uniqueId);
		} catch (error) {
			promptLayoutAlertMessage(error);
		}

		if (response) {
			formik.setFieldValue("paymentTerm", response.paymentTerm);
		}
	}, [formik]);

	//prettier-ignore
	const onHandleGetQuotationItems = useCallback(async (uniqueId) => {
		let response = null;

		const params = sanitizeObject({ ...queryLineParams.current, "item-id": uniqueId });

		try {
			response = await api.get.quotation.quotationItem(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setLinesTableData(obj);

			return response?.content || [];
		}
	}, [queryLineParams]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		const payload = {
			"quotation-id": uniqueId
		};

		try {
			response = await api.get.quotation.quotation(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setStatusRestrict(response.status !== STATUS.DRAFT);

			switch (response.status) {
				case STATUS.OPEN:
					setVisibility([false, false, false, false, false]);
					break;
				case STATUS.LOST:
					setVisibility([false, false, true, true, true]);
					break;
				default:
					setVisibility([false, true, true, true, true]);
					break;
			}

			setReferenceNo(response.referenceNo);

			const lineItems = await onHandleGetQuotationItems(uniqueId);

			memoSetFormValues({
				addressLine1: response.addressLine1,
				addressLine2: response.addressLine2,
				city: response.city,
				customerId: response.customer.id,
				customerNote: response.customerNote,
				picContactId: response.customerPicContact.id,
				mobileNo: response.customerPicContact.mobileNo,
				email: response.customerPicContact.email,
				discountAmount: response.discountAmount.toFixed(2),
				discountFlag: response.discountFlag,
				internalNote: response.internalNote,
				mobilizationLeadTime: response.mobilizationLeadTime,
				partsLeadTime: response.partsLeadTime,
				paymentCurrency: response.paymentCurrency,
				paymentTerm: response.customer.paymentTerm,
				postcode: response.postcode,
				quotationTitle: response.quotationTitle,
				quotationId: response.referenceNo,
				state: response.state,
				status: response.status,
				subtotalAmount: response.subtotalAmount,
				taxAmount: response.taxAmount,
				taxFlag: response.taxFlag,
				taxPercent: response.taxPercent,
				totalAmount: response.totalAmount,
				validityInDays: response.validityInDays,
				warranty: response.warranty,
				workInspectionId: response.workInspection?.id,
				createFlag: false,
				lineItems: lineItems,
				lastModifiedBy: response.lastModifiedBy,
				lastModifiedDate: dayjs(response.lastModifiedDate).format(DATE_TIME.LAST_UPDATED_BY_DATE),
			});
		}
	}, [memoSetFormValues, onHandleGetQuotationItems]);

	//prettier-ignore
	const onHandleGetItemsDetails = useCallback(async (uniqueId) => {
		const params = sanitizeObject({ 
			...queryLineParams.current, 
			"item-id": uniqueId,
		});

		let response = null;

		try {
			response = await api.get.quotation.quotationItem(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

    		setLinesTableData(obj);

			memoSetFormValues(prevValues => ({ ...prevValues, lineItems: response.content }));
		}
	}, [memoSetFormValues]);

	//prettier-ignore
	const onHandleCreateSubItem = useCallback((values) => {
		const mainItemIndex = selectedItem.current.rowIndex;
		const nextItems = [...formik.values.lineItems];

		if (!nextItems[mainItemIndex].lineSubItems) {
			nextItems[mainItemIndex].lineSubItems = [];
		}

		const currentSubItemCount = nextItems[mainItemIndex].lineSubItems.length;

		nextItems[mainItemIndex].lineSubItems.push(values);

		const { subtotalAmount, taxAmount, totalAmount } = calculateTotals(nextItems, formik.values.taxPercent, formik.values.discountAmount);

		formik.setValues({
			...formik.values,
			lineItems: nextItems,
			subtotalAmount,
			taxAmount,
			totalAmount
		});

		if (!isCreate && nextItems[mainItemIndex].lineSubItems.length > currentSubItemCount) {
			onHandleGetDetails(id);
		}
	}, [formik, calculateTotals, id, onHandleGetDetails, isCreate]);

	//prettier-ignore
	const onHandleCreateItem = useCallback((values) => {
		const currentItemCount = formik.values.lineItems.length;
		const nextItems = [...formik.values.lineItems, values].map((o, i) => ({ number: i + 1, ...o }));
		const subtotalAmount = calculateSubtotal(nextItems);

		formik.setFieldValue("lineItems", nextItems);
		formik.setFieldValue("subtotalAmount", subtotalAmount.toFixed(2));

		if (!isCreate && nextItems.length > currentItemCount) {
			onHandleGetDetails(id);
		}
	}, [formik, calculateSubtotal, id, onHandleGetDetails, isCreate]);

	const onHandleCloseQuotation = useCallback(async () => {
		let response = null;

		let closePayload = {
			"close-id": id
		};

		try {
			response = await api.post.quotation.close(closePayload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			onHandleGetDetails(id);

			dispatch(promptLayoutAlertMessage({ message: "Quotation was updated successfully!" }));
		}
	}, [id, onHandleGetDetails, dispatch]);

	const onHandleConvertSalesOrder = useCallback(async () => {
		let response = null;

		let payload = {
			quotationId: id,
			convertSaleOrderStatus: "CONVERT_SALE_ORDER_NO_EXCEPTION"
		};

		try {
			response = await api.post.salesOrders.convert(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Sales Order was created successfully!" }));

			navigate(pathnames.sales.salesOrderCreateEdit + response.id);
		}
	}, [id, dispatch, navigate]);

	const onHandleConvertWithException = useCallback(async () => {
		let response = null;

		let payload = {
			quotationId: id,
			convertSaleOrderStatus: "CONVERT_SALE_ORDER_WITH_EXCEPTION"
		};

		try {
			response = await api.post.salesOrders.convert(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Sales Order was created successfully!" }));

			navigate(pathnames.sales.salesOrderCreateEdit + response.id);
		}
	}, [id, dispatch, navigate]);

	const onHandleGetCustomerId = (event) => {
		const customerId = event.target.value;

		onHandleGetPaymentTerm(customerId);

		formik.setValues({
			...formik.values,
			customerId: customerId
		});
	};

	const onHandleGetPicContactId = (event) => {
		const picContactId = event.target.value;

		onHandleGetPicContacts(formik.values.customerId, picContactId);

		formik.setValues({
			...formik.values,
			picContactId: picContactId
		});
	};

	//prettier-ignore
	const onHandleGetPicContacts = useCallback(async (customerId, picContactId) => {
		let response = null;

		try {
			const params = sanitizeObject({
				"customer-id": customerId,
				queryParams: { ...queryParams.current, size: 10 }
			});

			response = await api.get.customer.customerPic(params);
		} catch (error) {
			promptLayoutAlertMessage(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);
			const relatedPICDetail = obj.content.find((o) => o.id === picContactId);

			formik.setFieldValue("mobileNo", relatedPICDetail.mobileNo);
			formik.setFieldValue("email", relatedPICDetail.email);
		}
	}, [formik]);

	//prettier-ignore
	const onHandleTaxCheckbox = useCallback((boolean) => {
		formik.setFieldValue("taxFlag", boolean);

		if (!boolean) formik.setFieldValue("taxPercent", "");
	}, [formik]);

	//prettier-ignore
	const onHandleDiscountCheckbox = useCallback((boolean) => {
		formik.setFieldValue("discountFlag", boolean);

		if (!boolean) formik.setFieldValue("discountAmount", "");
	}, [formik]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		rowsExpanded: rowsExpanded,
		expandableRows: true,
		expandableRowsHeader: false,
		expandableRowsOnClick: false,
		count: linesTableData.totalElements,
		page: linesTableData.page,
		serverSide: true,
		renderExpandableRow: onHandleRenderExpandedRow,
		onCellClick: onHandleCellSelect,
		onTableChange: (action, tableState) => {
			if (action === "changePage") {
				queryLineParams.current.page = tableState.page;

				onHandleGetItemsDetails(id);
			}
		}
	}), [rowsExpanded, onHandleCellSelect, onHandleRenderExpandedRow, linesTableData.totalElements, linesTableData.page, onHandleGetItemsDetails, id]);

	const [actionAnchorEl, setActionAnchorEl] = useState(null);

	const onHandleActionClick = (event) => {
		setActionAnchorEl(event.currentTarget);
	};

	const onHandleActionClose = () => {
		setActionAnchorEl(null);
	};

	const onHandleActionSelect = (value) => {
		onHandleActionTaken(value);

		onHandleActionClose();
	};

	const actionOptions = useMemo(() => {
		const options = [
			{ label: "Clone Quotation", value: "CLONE", isHidden: visibility[0] },
			{ label: "Clone as A Version", value: "VERSION", isHidden: visibility[1] },
			{ label: "Convert to Sales Order", value: "CONVERT", isHidden: visibility[2] },
			{ label: "Convert With Exception", value: "EXCEPTION", isHidden: visibility[3] },
			{ label: "Set as Lost", value: "CLOSE", isHidden: visibility[4] }
		];

		return options;
	}, [visibility]);

	//prettier-ignore
	const onHandleActionTaken = useCallback(async (value) => {
		let response = null;

		try {
			let clonePayload = {
				id: id,
				versionFlag: value !== "CLONE"
			};

			switch (value) {
				case "CLONE":
					response = await api.post.quotation.clone(clonePayload);
					break;
				case "VERSION":
					response = await api.post.quotation.clone(clonePayload);
					break;
				case "CONVERT":
					quotationConvertToSalesOrderModalRef.current.onHandleShow(formik.values.quotationId);
					break;
				case "EXCEPTION":
					quotationConvertWithExceptionRef.current.onHandleShow(formik.values.quotationId);
					break;
				case "CLOSE":
					quotationClosedModalRef.current.onHandleShow(formik.values.quotationId);
					break;
				default:
					break;
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			switch (value) {
				case "CLONE":
				case "VERSION":
					dispatch(promptLayoutAlertMessage({ message: "Quotation was cloned successfully!" }));

					navigate(pathnames.sales.quotationCreateEdit + response.id);
					break;
				default:
					break;
			}
		}
	}, [id, navigate, dispatch, formik.values.quotationId]);

	const onHandleExportPDF = useCallback(async () => {
		let response = null;
		let fileName = "";

		try {
			const payload = { "quotation-id": id };

			const transformResponse = (data, headers) => {
				fileName = headers?.["content-disposition"]?.split("inline; filename=")?.[1]?.split(";")?.[0];

				if (fileName) return data;

				try {
					const jsonResponse = JSON.parse(new TextDecoder().decode(data));

					if (jsonResponse) return jsonResponse;
				} catch (error) {
					return data;
				}
			};

			response = await api.post.quotation.generateQuotationPdf(payload, { transformResponse });
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const a = document.createElement("a");
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(new Blob([response]), { type: "application/octet-stream" });
			a.href = url;
			a.download = fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			}, 1000);
		}
	}, [id]);

	useEffect(() => {
		if (!isCreate) {
			onHandleGetDetails(id);

			onHandleGetItemsDetails(id);
		}

		return () => {
			if (!isCreate) {
				memoCancelRequest(ENDPOINT_PATH.QUOTATION.QUOTATION);

				memoCancelRequest(ENDPOINT_PATH.QUOTATION.QUOTATION_ITEM);

				memoCancelRequest(ENDPOINT_PATH.CUSTOMER.CUSTOMER);
			}
		};
	}, [isCreate, id, onHandleGetDetails, onHandleGetItemsDetails, memoCancelRequest, queryLineParams.current.page]);

	return (
		<div className="page-quotation-create-edit">
			<div className="quotation-create-edit">
				<div className="quotation-create-edit__header">
					<h1 className="quotation-create-edit__title">{title}</h1>

					<div className="quotation-create-edit__header quotation-create-edit__header--column">
						{statusRestrict && (
							<div className="quotation-create-edit__header-buttons">
								<AppButton outline type="button" label="Export as PDF" icon={downloadIcon} onClick={onHandleExportPDF} />

								<AppButton outline className="quotation-create-edit__clone-button" type="button" label="Action" icon={chevronIcon} onClick={onHandleActionClick} />

								<Menu anchorEl={actionAnchorEl} open={Boolean(actionAnchorEl)} onClose={onHandleActionClose}>
									{/* prettier-ignore */ actionOptions.map((option) => !option.isHidden && (
									<MenuItem key={option.value} onClick={() => onHandleActionSelect(option.value)}>
										{option.label}
									</MenuItem>
								))}
								</Menu>
							</div>
						)}

						{!isCreate && (
							<p className="quotation-create-edit__last-update">
								<span>Last Updated By </span> {formik.values.lastModifiedBy}, {formik.values.lastModifiedDate}
							</p>
						)}
					</div>
				</div>

				<form className="quotation-create-edit__form" onSubmit={formik.handleSubmit}>
					<div className="quotation-create-edit__container">
						<div className="quotation-create-edit__wrapper quotation-create-edit__wrapper--divider">
							<p className="quotation-create-edit__label">General Details</p>

							<div className="quotation-create-edit__row quotation-create-edit__row--general-details">
								<AppInput disabled type="text" name="quotationId" label="Quotation ID" placeholder="Quotation ID" value={formik.values.quotationId} error={formik.errors.quotationId} touched={formik.touched.quotationId} onChange={formik.handleChange} />

								<AppInput disabled name="status" label="Status" placeholder="Status" value={capitalizeCharacter(formik.values.status)} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
							</div>

							<div className="quotation-create-edit__row quotation-create-edit__row--general-details">
								<AppSelectInput pagination disabled={statusRestrict} name="workInspectionId" label="Work Inspection ID" placeholder="Select..." defaultSearchValue={workInspectionIdKeyword.current} loadOptions={getWorkInspectionListing} value={formik.values.workInspectionId} error={formik.errors.workInspectionId} touched={formik.touched.workInspectionId} onChange={formik.handleChange} />
							</div>
						</div>

						<div className="quotation-create-edit__wrapper">
							<p className="quotation-create-edit__label">Customer Details</p>

							<div className="quotation-create-edit__row">
								<AppSelectInput pagination disabled={statusRestrict} required type="text" name="customerId" label="Registered Name" placeholder="Enter Registered Name" loadOptions={getRegisteredNameListing} value={formik.values.customerId} error={formik.errors.customerId} touched={formik.touched.customerId} onChange={onHandleGetCustomerId} />

								<AppSelectInput pagination disabled={!formik.values.customerId || statusRestrict} required name="picContactId" label="PIC Name" loadOptions={(payload) => getPICListing({ id: formik.values.customerId, keyword: payload?.keyword, page: payload?.page })} value={formik.values.picContactId} error={formik.errors.picContactId} touched={formik.touched.picContactId} onChange={onHandleGetPicContactId} />
							</div>

							<div className="quotation-create-edit__row">
								<AppMobileInput disabled type="number" name="mobileNo" label="Mobile No." value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

								<AppInput disabled type="text" name="email" label="Email" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />
							</div>

							<div className="quotation-create-edit__row">
								<AppInput disabled={statusRestrict} required type="text" name="addressLine1" label="Address Line 1" placeholder="Please input address line 1" value={formik.values.addressLine1} error={formik.errors.addressLine1} touched={formik.touched.addressLine1} onChange={formik.handleChange} />

								<AppInput disabled={statusRestrict} type="text" name="addressLine2" label="Address Line 2" placeholder="Please input address line 2" value={formik.values.addressLine2} error={formik.errors.addressLine2} touched={formik.touched.addressLine2} onChange={formik.handleChange} />
							</div>

							<div className="quotation-create-edit__row">
								<AppSelectInput disabled={statusRestrict} required name="state" label="State" placeholder="Select..." loadOptions={getStateListing} value={formik.values.state} error={formik.errors.state} touched={formik.touched.state} onChange={formik.handleChange} />

								<AppSelectInput required name="city" label="City" placeholder="Select..." disabled={!formik.values.state || statusRestrict} loadOptions={getRegionLoadOptions} value={formik.values.city} error={formik.errors.city} touched={formik.touched.city} onChange={formik.handleChange} />
							</div>

							<div className="quotation-create-edit__row">
								<AppSelectInput required name="postcode" label="Postcode" placeholder="Select..." disabled={!formik.values.city || statusRestrict} loadOptions={getPostcodeLoadOptions} value={formik.values.postcode} error={formik.errors.postcode} touched={formik.touched.postcode} onChange={formik.handleChange} />

								<AppInput disabled={statusRestrict} required type="number" name="validityInDays" label="Validity" placeholder="Enter a No. of days" value={formik.values.validityInDays} error={formik.errors.validityInDays} touched={formik.touched.validityInDays} onChange={formik.handleChange} />
							</div>
						</div>
					</div>

					<div className="quotation-create-edit__container">
						<div className="quotation-create-edit__wrapper quotation-create-edit__wrapper--divider">
							<AppInput disabled={statusRestrict} required type="text" maxLength={250} name="quotationTitle" label="Quotation Title" placeholder="Please input quotation title" value={formik.values.quotationTitle} error={formik.errors.quotationTitle} touched={formik.touched.quotationTitle} onChange={formik.handleChange} />

							<div className="quotation-create-edit__table-header">
								<p className="quotation-create-edit__label">Item Lines</p>

								{!statusRestrict && <AppButton outline type="button" label="Add" icon={addIcon} onClick={onHandleAddQuotationItem} />}
							</div>

							<AppTable data={processLineItems(formik.values.lineItems)} columns={tableColumns} options={tableOptions} components={emptyState} />

							<SelectedItemDetails selectedRowItem={selectedRowItem} items={processLineItems(formik.values.lineItems)} />

							<div className="quotation-create-edit__row">
								<AppInput disabled={statusRestrict} multiline maxLength={1000} type="textarea" name="internalNote" label="Internal Note" placeholder="Type Note here" value={formik.values.internalNote} error={formik.errors.internalNote} touched={formik.touched.internalNote} onChange={formik.handleChange} />

								<div className="quotation-create-edit__column quotation-create-edit__column--divider">
									<AppInput disabled type="text" name="subtotalAmount" label="Subtotal (MYR)" placeholder="For example: 100.00" value={formik.values.subtotalAmount} touched={formik.touched.subtotalAmount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

									<AppCheckbox disabled={statusRestrict} label="Tax (%)" onClick={onHandleTaxCheckbox} value={formik.values.taxFlag} onChange={formik.handleChange} />

									<div className="quotation-create-edit__gap">
										<AppInput disabled={!formik.values.taxFlag || statusRestrict} type="number" maxLength={1} name="taxPercent" placeholder="Enter Tax(%)" value={formik.values.taxPercent} error={formik.errors.taxPercent} touched={formik.touched.taxPercent} onChange={onHandleTaxPercentChange} />

										<AppInput disabled type="text" name="taxAmount" value={formik.values.taxAmount} touched={formik.touched.taxAmount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />
									</div>
								</div>
							</div>

							<div className="quotation-create-edit__row">
								<AppInput disabled={statusRestrict} multiline maxLength={1000} type="textarea" name="customerNote" label="Customer Note" placeholder="Type Note here" value={formik.values.customerNote} error={formik.errors.customerNote} touched={formik.touched.customerNote} onChange={formik.handleChange} />

								<div className="quotation-create-edit__column quotation-create-edit__column--divider">
									<AppCheckbox disabled={statusRestrict} label="Discount (MYR)" onClick={onHandleDiscountCheckbox} value={formik.values.discountFlag} onChange={formik.handleChange} />

									<AppInput disabled={!formik.values.discountFlag || statusRestrict} type="text" name="discountAmount" placeholder="Enter Discount" value={formik.values.discountAmount} error={formik.errors.discountAmount} touched={formik.touched.discountAmount} onChange={onHandleDiscountAmountChange} onFormat={formatCurrencyPattern} />

									<AppInput disabled type="text" name="totalAmount" label="Grand Total (MYR)" value={formik.values.totalAmount} error={formik.errors.totalAmount} touched={formik.touched.totalAmount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />
								</div>
							</div>
						</div>

						<div className="quotation-create-edit__wrapper">
							<p className="quotation-create-edit__label">Standard Terms and Conditions</p>

							<div className="quotation-create-edit__row quotation-create-edit__row--general-details">
								<AppInput disabled={statusRestrict} type="text" name="partsLeadTime" label="Parts Lead Time" placeholder="Enter Parts Lead Time" value={formik.values.partsLeadTime} error={formik.errors.partsLeadTime} touched={formik.touched.partsLeadTime} onChange={formik.handleChange} />

								<AppInput disabled name="paymentTerm" label="Approved Payment Term" value={formik.values.paymentTerm} touched={formik.touched.paymentTerm} onChange={formik.handleChange} />
							</div>

							<div className="quotation-create-edit__row">
								<AppInput disabled={statusRestrict} type="text" name="mobilizationLeadTime" label="Mobilization Lead Time" placeholder="Enter Mobilization Lead Time" value={formik.values.mobilizationLeadTime} error={formik.errors.mobilizationLeadTime} touched={formik.touched.mobilizationLeadTime} onChange={formik.handleChange} />

								<div className="quotation-create-edit__column">
									<AppInput disabled={statusRestrict} type="text" name="warranty" label="Warranty" placeholder="Enter Warranty" value={formik.values.warranty} error={formik.errors.warranty} touched={formik.touched.warranty} onChange={formik.handleChange} />

									<AppSelectInput disabled={statusRestrict} pagination name="paymentCurrency" label="Payment Currency" placeholder="Select" loadOptions={getPaymentCurrencyListing} value={formik.values.paymentCurrency} error={formik.errors.paymentCurrency} touched={formik.touched.paymentCurrency} onChange={formik.handleChange} />
								</div>
							</div>
						</div>
					</div>

					<div className="quotation-create-edit__button-container">
						{formik.values.status === STATUS.DRAFT && (
							<Fragment>
								<AppButton outline type="submit" label={saveLabel} />

								<AppButton type="button" label="Create Quote" disabled={formik.isSubmitting || !formik.dirty} onClick={onHandleValidateForm} />
							</Fragment>
						)}

						<AppButton outline className="quotation-create-edit__cancel-button" type="button" label="Cancel" onClick={onHandleBack} />
					</div>
				</form>
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "quotation-create-edit-table-menu" }} anchorEl={itemTableAnchor || subItemTableAnchor} open={!!itemTableAnchor || !!subItemTableAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleEditQuotationItem}><img src={editIcon} alt="inventory-edit" />Edit</MenuItem>

				{itemTableAnchor && <MenuItem onClick={onHandleAddSubQuotationItem}><img src={addIcon} alt="inventory-close" />Add Sub-Item</MenuItem>}

				<MenuItem onClick={onHandleConfirmRemoveQuotationItem}><img src={trashhIcon} alt="inventory-transfer" />Remove</MenuItem>
			</Menu>

			<AppQuotationAddItemModal ref={quotationAddItemModalRef} onConfirm={onHandleCreateItem} onConfirmSubItem={onHandleCreateSubItem} onEdit={onHandleEditItem} />

			<AppQuotationDeleteItemModal ref={quotationDeleteItemModalRef} onConfirm={onHandleRemoveQuotationItem} />

			<AppCreateQuotationModal ref={quotationCreateModalRef} onConfirm={onHandleCreateQuotation} />

			<AppCloseQuotationModal ref={quotationClosedModalRef} onConfirm={onHandleCloseQuotation} />

			<AppConvertToSalesOrderModal ref={quotationConvertToSalesOrderModalRef} onConfirm={onHandleConvertSalesOrder} />

			<AppConvertWithExceptionModal ref={quotationConvertWithExceptionRef} onConfirm={onHandleConvertWithException} />
		</div>
	);
};

export default PageQuotationCreateEdit;
