import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";
import STATUS from "constants/status";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppInputDragAndDropFiles from "components/app-input-drag-and-drop-files";

import closeIcon from "assets/images/close-icon.png";

const AppViewPendingLeaveModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const dispatch = useDispatch();
	const downloadingFile = useRef(false);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CLAIM_TYPE_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	const initialValues = useMemo(() => {
		const values = {
			id: "",
			employeeId: "",
			employeeName: "",
			days: "",
			status: "",
			endDate: "",
			timeOffType: "",
			startDate: "",
			leaveType: "",
			submitDate: "",
			description: "",
			balanceDays: "",
			file: []
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({
			id: obj?.id,
			employeeId: obj?.employeeId,
			employeeName: obj?.employeeName,
			days: obj?.days,
			status: obj?.status,
			timeOffType: obj?.timeOffType,
			leaveType: obj?.leaveType,
			startDate: dayjs(obj?.date),
			submitDate: dayjs(obj?.date),
			endDate: dayjs(obj?.endDate),
			description: obj?.description,
			supervisorId: obj?.supervisorId,
			reportingManagerId: obj?.reportingManagerId,
			balanceDays: obj?.balanceDays,
			file: obj.docs
		});

		setVisible(true);
	}, [formik]);

	const actionPermission = useMemo(() => (props.employeeId === formik.values.supervisorId && formik.values.status === STATUS.PENDING) || (props.employeeId === formik.values.reportingManagerId && formik.values.status === STATUS.APPROVED_BY_SUPERVISOR), [formik.values, props.employeeId]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	const onHandleDownloadFile = useCallback(async (fileObject) => {
		if (downloadingFile.current) return;

		downloadingFile.current = true;

		let response = null;

		try {
			const payload = { "doc-id": fileObject.id };

			response = await api.post.humanResource.downloadLeaveDoc(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const url = response.fileUrl;
			const a = document.createElement("a");
			document.body.appendChild(a);

			a.href = url;
			a.download = response.fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				downloadingFile.current = false;
			}, 0);
		}
	}, []);

	const onHandleReject = useCallback(() => {
		setVisible(false);

		props.onHandleRejectLeave(formik.values.id);
	}, [formik, props]);

	const onHandleApproval = useCallback(async () => {
		let response = null;

		const id = [formik.values.id];

		try {
			await api.post.humanResource.approveEmployeeLeave({ "leave-id": id });

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Leave was approved successfully" }));

			props.onHandleGetList();
		}
	}, [formik, dispatch, props]);

	const onHandleSubmit = useCallback(async () => {
		setVisible(false);

		onHandleApproval();
	}, [onHandleApproval]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	//prettier-ignore
	const FooterDetailsButton = useCallback((obj) => {
		return (
			<div className="view-pending-leave-modal__footer-button">
				{(obj.actionPermission || obj.isHR) && (
					<div className="view-pending-leave-modal__reject-button">
						<AppButton outline type="button" disabled={obj.restricted} label="Reject" onClick={obj.onHandleReject} />
					</div>
				)}

				{obj.actionPermission && (
					<div className="view-pending-leave-modal__approve-button">
						<AppButton type="submit" disabled={obj.restricted} label="Approve" />
					</div>
				)}
			</div>
		);
	}, []);

	return (
		<Modal classes={{ root: "app-view-pending-leave-modal" }} open={visible}>
			<div className="view-pending-leave-modal">
				<button type="button" className="view-pending-leave-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="view-pending-leave-modal__title">Leave Details</h1>

				<div className="view-pending-leave-modal__container">
					<form className="view-pending-leave-modal__form" onSubmit={formik.handleSubmit}>
						<div className="view-pending-leave-modal__row">
							<AppInput disabled type="text" name="employeeName" label="Name" value={formik.values.employeeName} error={formik.errors.employeeName} touched={formik.touched.employeeName} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="employeeId" label="Employee ID" value={formik.values.employeeId} error={formik.errors.employeeId} touched={formik.touched.employeeId} onChange={formik.handleChange} />
						</div>

						<div className="view-pending-leave-modal__row">
							<AppInput disabled type="text" name="status" label="Status" value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="leaveType" label="Leave Type" value={formik.values.leaveType} error={formik.errors.leaveType} touched={formik.touched.leaveType} onChange={formik.handleChange} />
						</div>

						<div className="view-pending-leave-modal__row">
							<AppInput disabled type="text" name="timeOffType" label="Time Off" value={formik.values.timeOffType} error={formik.errors.timeOffType} touched={formik.touched.timeOffType} onChange={formik.handleChange} />

							<AppInputDate disabled type="text" name="startDate" label="Start Date" value={formik.values.startDate} error={formik.errors.startDate} touched={formik.touched.startDate} onChange={formik.handleChange} />
						</div>

						<div className="view-pending-leave-modal__row">
							<AppInputDate disabled type="text" name="endDate" label="End Date" value={formik.values.endDate} error={formik.errors.endDate} touched={formik.touched.endDate} onChange={formik.handleChange} />

							<AppInputDate disabled type="text" name="submitDate" label="Submit Date" value={formik.values.submitDate} error={formik.errors.submitDate} touched={formik.touched.submitDate} onChange={formik.handleChange} />
						</div>

						<div className="view-pending-leave-modal__row">
							<AppInput disabled type="text" name="days" label="Day(s)" value={formik.values.days} error={formik.errors.days} touched={formik.touched.days} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="balanceDays" label="Balance Days" value={formik.values.balanceDays} error={formik.errors.balanceDays} touched={formik.touched.balanceDays} onChange={formik.handleChange} />
						</div>

						<AppInput disabled type="textarea" name="description" label="Description" multiline value={formik.values.description} touched={formik.touched.description} error={formik.errors.description} onChange={formik.handleChange} />

						<AppInputDragAndDropFiles disabled name="file" value={formik.values.file} onHandleDownloadFile={onHandleDownloadFile} onChange={formik.setFieldValue} />

						<FooterDetailsButton restricted={restricted} onHandleReject={onHandleReject} actionPermission={actionPermission} isHR={props.isHR} />
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppViewPendingLeaveModal));

AppViewPendingLeaveModal.propTypes = {
	isHR: PropTypes.bool.isRequired,
	employeeId: PropTypes.string.isRequired,
	onHandleGetList: PropTypes.func.isRequired,
	onHandleRejectLeave: PropTypes.func.isRequired
};
