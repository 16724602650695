import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";
import getContractTypeListing from "services/get-contract-type-listing";
import getContractStatusListing from "services/get-contract-status-listing";
import getContractTemplateListing from "services/get-contract-template-listing";

import useBreadcrumb from "hooks/use-breadcrumb";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import validateFileSize from "common/validate-file-size";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";
import MAX_FILE_SIZES from "constants/max-file-size";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDropFiles from "components/app-input-drag-and-drop-files";
import AppCustomerAddContractPicTable from "components/pages/customer/app-customer-add-contract-pic-table";
import AppCustomerAddContractSiteTable from "components/pages/customer/app-customer-add-contract-site-table";

const PageCustomerCreateEditContract = (props) => {
	const profile = useSelector((state) => state.profile);
	const { customerId, id } = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const localPicContactOptions = useMemo(() => location.state?.picContacts, [location.state?.picContacts]);
	const [isClone, setIsClone] = useState(false);
	const picContactsCopy = useRef([]);
	const submitLabel = useMemo(() => (isCreate || isClone ? "Add" : "Update"), [isClone, isCreate]);
	const title = useMemo(() => {
		if (isCreate) {
			return "Add Contract";
		} else if (isClone) {
			return "Clone Contract";
		} else {
			return "Edit Contract";
		}
	}, [isClone, isCreate]);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_CONTRACT], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const downloadingFile = useRef(false);

	const initialValues = useMemo(() => {
		const values = {
			contractReference: "",
			contractId: id,
			customerId: customerId,
			status: "",
			name: "",
			type: "",
			startDate: "",
			endDate: "",
			configContractId: "",
			remark: "",
			picContacts: [],
			picContactIds: [],
			file: [],
			lastModifiedBy: "",
			lastModifiedDate: ""
		};

		return values;
	}, [customerId, id]);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			status: yup.string().required(ERRORS.REQUIRED),
			name: yup.string().required(ERRORS.REQUIRED),
			type: yup.string().required(ERRORS.REQUIRED),
			startDate: yup.date().required(ERRORS.REQUIRED),
			endDate: yup.date().required(ERRORS.REQUIRED),
			configContractId: yup.string().required(ERRORS.REQUIRED),
			file: yup.array().of(yup.mixed().test("fileSize", ERRORS.FILE_SIZE.replace("{size}", "10"), (value) => validateFileSize(value, MAX_FILE_SIZES.MB_10)))
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Customer Listing", path: pathnames.customer.customers },
			{ label: "Edit Customer", path: pathnames.customer.customerCreateEdit + formik.values.customerId }
		];

		if (isCreate) {
			data.push({ label: "Add Contract", path: pathnames.customer.customerCreateEditContract + `${formik.values.customerId}/` + PAGE.CREATE });
		} else if (isClone) {
			data.push({ label: "Clone Contract", path: pathnames.customer.customerCreateEditContract + `${formik.values.customerId}/` + PAGE.CLONE });
		} else {
			data.push({ label: "Edit Contract " + formik.values.contractReference, path: pathnames.customer.customerCreateEditContract + id });
		}

		return data;
	}, [formik.values.customerId, formik.values.contractReference, isCreate, isClone, id]);

	useBreadcrumb({ breadCrumb });

	const localFiles = useMemo(() => [...formik.values.file].filter((o) => !o.id), [formik.values.file]);

	// prettier-ignore
	const onHandleUpdatePicContacts = useCallback((values) => {
		formik.setFieldValue("picContacts", values);
	}, [formik]);

	// prettier-ignore
	const onHandleUpdatePicContactIds = useCallback((values) => {
		formik.setFieldValue("picContactIds", values);
	}, [formik]);

	// prettier-ignore
	const onHandleRemovePicContact = useCallback((id) => {
		let nextPicContactIds = structuredClone(formik.values.picContactIds);
		let nextPicContacts = structuredClone(formik.values.picContacts);

		nextPicContactIds = nextPicContactIds.filter((o) => id !== o);
		nextPicContacts = nextPicContacts.filter((o) => id !== o.id);

		formik.setFieldValue("picContactIds", nextPicContactIds);
		formik.setFieldValue("picContacts", nextPicContacts);
	}, [formik]);

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);
	const memoSetFieldValue = useMemo(() => formik.setFieldValue, [formik.setFieldValue]);

	// prettier-ignore
	const onHandleGetFiles = useCallback(async (id, currentLocalFiles) => {
		let response = null;

		try {
			const params = { "contract-id": id };

			response = await api.get.customer.docs(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			if (currentLocalFiles?.length) {
				memoSetFieldValue("file", [...response, ...currentLocalFiles]);
			} else {
				memoSetFieldValue("file", response);
			}
		}
	}, [memoSetFieldValue]);

	// prettier-ignore
	const onHandleGetDetails = useCallback(async (contractId) => {
		let response = null;

		try {
			response = await api.get.customer.contract(contractId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				customerId: response.customerId,
				contractId: response.id,
				contractReference: response.referenceNo,
				status: response.status,
				name: response.name,
				type: response.type,
				startDate: dayjs(response.startDate),
				endDate: dayjs(response.endDate),
				configContractId: response.configContractId,
				remark: response.remark,
				picContacts: [],
				picContactIds: [],
				file: [],
				lastModifiedBy: response.lastModifiedBy,
				lastModifiedDate: dayjs(response.lastModifiedDate).format(DATE_TIME.LAST_UPDATED_BY_DATE)
			});

			onHandleGetFiles(id);
		}

	}, [id, memoSetFormValues, onHandleGetFiles]);

	const onHandleFormatFiles = useCallback((fileList) => {
		const formData = new FormData();

		fileList.forEach((o) => {
			formData.append("files", o);
		});

		return formData;
	}, []);

	// prettier-ignore
	const onHandleChangeFile = useCallback(async (name, files) => {
		const nextFiles = files.map((o) => {
			if (!o.id) {
				o.localFileText = "Ready to Upload";
			}

			return o;
		});

		formik.setFieldValue(name, nextFiles);
	}, [formik]);

	// prettier-ignore
	const onHandleUploadFiles = useCallback(async (customerId) => {
		let response = null;
		let filesToUpload = [...localFiles];

		if (!filesToUpload.length) return;

		try {
			const payload = {
				queryParam: { "contract-id": customerId }
			};

			payload.files = onHandleFormatFiles(filesToUpload);

			await api.post.customer.uploadDoc(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Documents were uploaded successfully!" }));

			onHandleGetFiles(customerId);
		}
	}, [dispatch, localFiles, onHandleFormatFiles, onHandleGetFiles]);

	// prettier-ignore
	const onHandleDownloadFile = useCallback(async (fileObject) => {
		if (downloadingFile.current) return;

		downloadingFile.current = true;

		if (isCreate) {
			const url = URL.createObjectURL(fileObject);
			const a = document.createElement("a");
			document.body.appendChild(a);

			a.href = url;
			a.download = fileObject.name;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				downloadingFile.current = false;
			}, 0);
		} else {
			let response = null;

			try {
				const payload = { "doc-id": fileObject.id };

				response = await api.post.customer.downloadDoc(payload);
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				const url = response.fileUrl;
				const a = document.createElement("a");
				document.body.appendChild(a);

				a.href = url;
				a.download = response.fileName;
				a.click();

				setTimeout(() => {
					window.URL.revokeObjectURL(url);
					document.body.removeChild(a);
					downloadingFile.current = false;
				}, 0);
			}
		}
	}, [isCreate]);

	// prettier-ignore
	const onHandleRemoveFile = useCallback(async (fileIndex) => {
		const onHandleRemoveLocalFile = () => {
			const nextValues = formik.values.file.filter((_, i) => i !== fileIndex);

			formik.setFieldValue("file", nextValues);
		};

		if (isCreate) {
			return onHandleRemoveLocalFile();
		} else {
			let response = null;
			let docId = formik.values.file[fileIndex].id;

			if (!docId) {
				return onHandleRemoveLocalFile();
			}

			try {
				const payload = { "doc-id": formik.values.file[fileIndex].id };

				await api.post.customer.deleteDoc(payload);

				response = true;
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				dispatch(promptLayoutAlertMessage({ message: "Document was removed succesfully!" }));

				const currentLocalFiles = [...localFiles];

				onHandleGetFiles(id, currentLocalFiles);
			}
		}
	}, [isCreate, formik, dispatch, localFiles, onHandleGetFiles, id]);

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				customerId: values.customerId,
				status: values.status,
				name: values.name,
				type: values.type,
				startDate: values.startDate,
				endDate: values.endDate,
				configContractId: values.configContractId,
				remark: values.remark,
				picContactIds: values.picContactIds
			};

			if (isCreate) {
				response = await api.post.customer.createContract(payload);
			} else if (isClone) {
				response = await api.post.customer.createContract(payload);
			} else {
				payload.id = id;

				response = await api.post.customer.updateContract(payload);
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			let message = "";

			if (isCreate || isClone) {
				message = "Contract was added successfully!";
			} else {
				message = "Contract was updated successfully!";
			}

			dispatch(promptLayoutAlertMessage({ message }));

			navigate(pathnames.customer.customerCreateEditContract + response.id);

			onHandleUploadFiles(response.id);

			if (isClone) setIsClone(false);
		}
	}, [isCreate, isClone, id, formik, dispatch, navigate, onHandleUploadFiles]);

	const onHandleGetPicContacts = useCallback((value) => {
		picContactsCopy.current = value;
	}, []);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.customer.customerCreateEdit + formik.values.customerId);
	}, [formik.values.customerId, navigate]);

	useEffect(() => {
		if (!isCreate) {
			onHandleGetDetails(id);
		}

		return () => {
			if (!isCreate) {
				memoCancelRequest(ENDPOINT_PATH.CUSTOMER.CONTRACT);

				memoCancelRequest(ENDPOINT_PATH.CUSTOMER.DOCS);
			}
		};
	}, [isCreate, id, memoCancelRequest, onHandleGetDetails, onHandleGetFiles]);

	return (
		<div className="page-customer-create-edit-contract">
			<div className="customer-create-edit-contract">
				<div className="customer-create-edit-contract__header">
					<h1 className="customer-create-edit-contract__title">{title}</h1>

					{!isCreate && (
						<div className="customer-create-edit-contract__clone">
							<p className="customer-create-edit-contract__last-update">
								<span>Last Updated By</span> {formik.values.lastModifiedBy}, {formik.values.lastModifiedDate}
							</p>
						</div>
					)}
				</div>

				<form className="customer-create-edit-contract__form" onSubmit={formik.handleSubmit}>
					<div className="customer-create-edit-contract__container">
						<p className="customer-create-edit-contract__label">Details</p>

						<div className="customer-create-edit-contract__row customer-create-edit-contract__row--divider">
							{/* prettier-ignore */ !isCreate && !isClone && (<AppInput disabled type="text" name="contractReference" label="Contract ID" placeholder="Contract ID" value={formik.values.contractReference} error={formik.errors.contractReference} touched={formik.touched.contractReference} onChange={formik.handleChange} />)}

							<AppSelectInput required name="status" disabled={restricted} label="Contract Status" placeholder="Select..." loadOptions={getContractStatusListing} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />

							<AppInput required type="text" name="name" disabled={restricted} label="Contract Name" placeholder="Contract Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

							<AppSelectInput required name="type" disabled={restricted} label="Contract Type" placeholder="Select..." loadOptions={getContractTypeListing} value={formik.values.type} error={formik.errors.type} touched={formik.touched.type} onChange={formik.handleChange} />

							<AppInputDate required name="startDate" disabled={restricted} label="Contract Start Date" placeholder="Select start date" value={formik.values.startDate} onChange={formik.setFieldValue} />

							<AppInputDate required name="endDate" disabled={restricted} label="Contract End Date" placeholder="Select end date" value={formik.values.endDate} onChange={formik.setFieldValue} />

							<AppSelectInput required name="configContractId" disabled={restricted} pagination label="Contract Template" placeholder="Select..." loadOptions={getContractTemplateListing} value={formik.values.configContractId} error={formik.errors.configContractId} touched={formik.touched.configContractId} onChange={formik.handleChange} />
						</div>

						<p className="customer-create-edit-contract__label">Contract PICs</p>

						<div className="customer-create-edit-contract__table">
							<AppCustomerAddContractPicTable isClone={isClone} customerId={formik.values.customerId} picContacts={formik.values.picContacts} picContactIds={formik.values.picContactIds} localPicContactOptions={localPicContactOptions} onHandleGetPicContacts={onHandleGetPicContacts} onHandleUpdatePicContacts={onHandleUpdatePicContacts} onHandleUpdatePicContactIds={onHandleUpdatePicContactIds} onHandleRemovePicContact={onHandleRemovePicContact} />
						</div>
					</div>

					<div className="customer-create-edit-contract__container">
						<p className="customer-create-edit-contract__label">Additional Documents</p>

						<div className="customer-create-edit-contract__documents">
							<p className="customer-create-edit-contract__instructions">Accepted format: .pdf (no more than 10MB each)</p>

							<AppInputDragAndDropFiles name="file" accept=".pdf" length={5} onChange={onHandleChangeFile} onHandleDownloadFile={onHandleDownloadFile} onHandleRemoveFile={onHandleRemoveFile} disabled={restricted} value={formik.values.file || []} error={formik.errors.file} touched={formik.touched.file} />
						</div>
					</div>

					<div className="customer-create-edit-contract__container">
						<p className="customer-create-edit-contract__label">Remarks</p>

						<div className="customer-create-edit-contract__row">
							<AppInput multiline type="textarea" maxLength={255} disabled={restricted} name="remark" placeholder="Enter Remarks" value={formik.values.remark || ""} error={formik.errors.remark} touched={formik.touched.remark} onChange={formik.handleChange} />
						</div>
					</div>

					{!isCreate && !isClone && (
						<div className="customer-create-edit-contract__container">
							<p className="customer-create-edit-contract__label">Sites</p>

							<div className="customer-create-edit-contract__table">
								<AppCustomerAddContractSiteTable />
							</div>
						</div>
					)}

					<div className="customer-create-edit-contract__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

						<AppButton type="submit" label={submitLabel} disabled={formik.isSubmitting || restricted} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageCustomerCreateEditContract;
