import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import validateFileSize from "common/validate-file-size";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ERRORS from "constants/errors";
import SIGNATURE_TYPE from "constants/signature-type";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";

export const AppConfirmChangeWorkOrderStatusModal = (props, ref) => {
	const { id } = useParams();
	const profile = useSelector((state) => state.profile);
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const onHandleGetDetails = useMemo(() => props.onHandleGetDetails, [props.onHandleGetDetails]);

	const initialValues = useMemo(() => {
		const values = {
			referenceNo: "",
			signatureType: "",
			actionBy: "",
			reason: "",
			signature: ""
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			actionBy: yup.string().required(ERRORS.REQUIRED),
			reason: yup.string().required(ERRORS.REQUIRED),
			//prettier-ignore
			signature: yup.mixed().test("fileSize", ERRORS.FILE_SIZE.replace("{size}", "5"), (value) => validateFileSize([value])).required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);
	const isReschedule = useMemo(() => formik.values.signatureType === SIGNATURE_TYPE.RESCHEDULED_BY, [formik.values.signatureType]);
	const actionByLabel = useMemo(() => (isReschedule ? "Reschedule By" : "Cancel By"), [isReschedule]);
	const reasonLabel = useMemo(() => (isReschedule ? "Reason for Reschedule" : "Reason for Cancellation"), [isReschedule]);

	//prettier-ignore
	const onHandleShow = useCallback((signatureType) => {
		memoSetFormValues((prevValues) => ({ ...prevValues, signatureType: signatureType, actionBy: profile.fullName }));

		setVisible(true);
	}, [memoSetFormValues, profile.fullName]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	//prettier-ignore
	const onHandleSetSignature = useCallback((name, value) => {
		let image = "";

		if (value) {
			image = URL.createObjectURL(value);
		}

		formik.setFieldValue(name, image);
		formik.setFieldValue("file", value);
	}, [formik]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const formData = new FormData();

			formData.append("file", values.file);
			formData.append("woId", id);
			formData.append("signatureType", values.signatureType);
			formData.append("reasonDescription", values.reason);

			await api.post.workOrder.signatureUpload(formData);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: isReschedule ? "Work Order Report was Rescheduled" : "Work Order Report was Cancelled" }));

			onHandleGetDetails(id);

			onHandleDismiss();
		}
	}, [id, formik, dispatch, isReschedule, onHandleGetDetails, onHandleDismiss]);

	const Texts = useCallback(() => {
		if (isReschedule) {
			return (
				<p className="confirm-change-work-order-status-modal__description">
					Please provide a signature to reschedule this Work Order Report. <br /> <br /> After clicking confirm, the status for Work Order Report {formik.values.referenceNo} will be set to Rescheduled and you will be redirected to a new Order Report
				</p>
			);
		}

		return (
			<p className="confirm-change-work-order-status-modal__description">
				Please provide a signature to cancel this Work Order Report. <br /> <br /> After clicking confirm, the status for Work Order {formik.values.referenceNo} will be set to Cancelled.
			</p>
		);
	}, [formik.values.referenceNo, isReschedule]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-confirm-change-work-order-status-modal" }} open={visible}>
			<div className="confirm-change-work-order-status-modal">
				<h1 className="confirm-change-work-order-status-modal__title">Are You Sure?</h1>

				<form className="confirm-change-work-order-status-modal__form" onSubmit={formik.handleSubmit}>
					<Texts />

					<AppInput disabled required name="actionBy" label={actionByLabel} placeholder="Enter Name" value={formik.values.actionBy} error={formik.errors.actionBy} touched={formik.touched.actionBy} onChange={formik.handleChange} />

					<AppInput multiline required type="textarea" label={reasonLabel} maxLength={255} name="reason" placeholder="Enter Reason" value={formik.values.reason} error={formik.errors.reason} touched={formik.touched.reason} onChange={formik.handleChange} />

					<p className="confirm-change-work-order-status-modal__label">
						Signature Attachment <span className="confirm-change-work-order-status-modal__required">*</span>
					</p>

					<AppInputDragAndDrop name="signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.signature} error={formik.errors.signature} touched={formik.touched.signature} onChange={(name, value) => onHandleSetSignature(name, value)} />

					<div className="confirm-change-work-order-status-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Confirm" />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppConfirmChangeWorkOrderStatusModal));

AppConfirmChangeWorkOrderStatusModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
