import { memo, useCallback, useEffect, useMemo } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";
import getContractTypeListing from "services/get-contract-type-listing";

import useBreadcrumb from "hooks/use-breadcrumb";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

const PageContractCreateEdit = (props) => {
	const profile = useSelector((state) => state.profile);
	const dispatch = useDispatch();
	const { id } = useParams();
	const navigate = useNavigate();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const title = useMemo(() => (isCreate ? "Add Contract" : "Edit Contract"), [isCreate]);
	const submitLabel = useMemo(() => (!isCreate ? "Update" : "Add"), [isCreate]);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CONTRACT_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	// prettier-ignore
	const initialValues = useMemo(() => ({ contractId: "", contractName: "", contractType: "", description: "", remark: "", createdDate: "", lastModifiedByName: "", lastModifiedDate: "" }), []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			contractName: yup.string().required(ERRORS.REQUIRED),
			contractType: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Operations", path: pathnames.operations.generalConfiguration + "?tab=CONTRACTS" },
			{ label: "General Configuration", path: pathnames.operations.generalConfiguration + "?tab=CONTRACTS" }
		];

		if (isCreate) {
			data.push({ label: "Add Contract", path: pathnames.operations.contractCreateEdit + PAGE.CREATE });
		}

		if (!isCreate) {
			data.push({ label: "Edit Contract " + formik.values.contractId, path: pathnames.operations.contractCreateEdit + id });
		}

		return data;
	}, [isCreate, formik, id]);

	useBreadcrumb({ breadCrumb });

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = { 
				contractName: values.contractName, 
				contractType: values.contractType, 
				description: values.description, 
				remark: values.remark, 
			};

			if (isCreate) {
				await api.post.contract.createContract(payload);
			}

			if(!isCreate){
				payload.id = id;

				await api.post.contract.updateContract(payload);
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if(isCreate){
				dispatch(promptLayoutAlertMessage({ message: "Contract was added successfully!" }));
			}

			if(!isCreate){
				dispatch(promptLayoutAlertMessage({ message: "Contract was updated successfully!" }));
			}

			navigate(pathnames.operations.generalConfiguration + "?tab=CONTRACTS");
		}
	}, [navigate, dispatch, formik, id, isCreate]);

	// prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.contract.contract(uniqueId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				contractId: response.referenceNo,
				contractName: response.contractName,
				contractType: response.contractType,
				description: response.description,
				remark: response.remark,
				createdDate: response.createdDate,
				lastModifiedByName: response.lastModifiedByName,
				lastModifiedDate: dayjs(response.lastModifiedDate).format(DATE_TIME.LAST_UPDATED_BY_DATE),
			});
		}
	}, [memoSetFormValues]);

	// Revert to previous data
	const onHandleCancel = useCallback(() => {
		navigate(pathnames.operations.generalConfiguration + "?tab=CONTRACTS");
	}, [navigate]);

	useEffect(() => {
		if (!isCreate) onHandleGetDetails(id);

		return () => {
			if (id) memoCancelRequest(ENDPOINT_PATH.CONTRACT.CONTRACT);
		};
	}, [isCreate, id, memoCancelRequest, onHandleGetDetails]);

	return (
		<div className="page-contract-create-edit">
			<div className="contract-create-edit">
				<div className="contract-create-edit__header">
					<h1 className="contract-create-edit__title">{title}</h1>

					{!isCreate && (
						<p className="contract-create-edit__last-update">
							<span>Last Updated By</span> {formik.values.lastModifiedByName}, {formik.values.lastModifiedDate}
						</p>
					)}
				</div>

				<form className="contract-create-edit__form" onSubmit={formik.handleSubmit}>
					<div className="contract-create-edit__container">
						<div className="contract-create-edit__label">Details</div>

						<div className="contract-create-edit__row">
							<div className="contract-create-edit__column">
								{!isCreate && /* prettier-ignore */ <AppInput name="contractId" label="Contract ID" type="text" disabled value={formik.values.contractId} error={formik.errors.contractId} touched={formik.touched.contractId} onChange={formik.handleChange} />}

								{/* prettier-ignore */}
								<AppInput required disabled={restricted} name="contractName" label="Contract Name" placeholder="Enter Contract Name" type="text" value={formik.values.contractName} error={formik.errors.contractName} touched={formik.touched.contractName} onChange={formik.handleChange} />
							</div>

							{/* prettier-ignore */}
							<AppInput disabled={restricted} name="description" label="Description" placeholder="Enter Contract Description" type="textarea" multiline maxLength={255} value={formik.values.description} touched={formik.touched.description} error={formik.errors.description} onChange={formik.handleChange}/>
						</div>

						<div className="contract-create-edit__row">
							{/* prettier-ignore */}
							<AppSelectInput searchable={false} required disabled={restricted} type="text" name="contractType" label="Contract Type" placeholder="Select..." loadOptions={getContractTypeListing} value={formik.values.contractType} error={formik.errors.contractType} touched={formik.touched.contractType} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppInput disabled={restricted} name="remark" label="Remarks" placeholder="Enter Remarks" type="textarea" multiline maxLength={255} value={formik.values.remark} touched={formik.touched.remark} error={formik.errors.remark} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="contract-create-edit__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleCancel} />

						<AppButton type="submit" disabled={formik.isSubmitting || restricted} label={submitLabel} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default memo(PageContractCreateEdit);
