import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import { TableCell } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppIcon from "components/app-icon";
import AppStatus from "components/app-status";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppCorrectiveAttendanceViewModal from "components/pages/human-resources/app-corrective-attendance-view-modal";

import eyeIcon from "assets/images/eye-open-icon.png";
import chevronIcon from "assets/images/chevron-right-icon.png";

const AppCorrectiveAttendanceHistoryTable = () => {
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const [data, setData] = useState(convertPaginationTableData());
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", tab: "CORRECTIVE_ATTENDANCE_HISTORY" });
	const correctiveAttendanceRef = useRef();

	const formik = useFormik({
		initialValues: { submitDate: "" },
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.humanResource.correctiveAttendanceHistoryGeneral(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		if (values.submitDate) queryParams.current["attendanceRecordDate"] = dayjs(values.submitDate).format(DATE_TIME.YYYY_MM_DD);
		
		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current["attendanceRecordDate"] = "";

		onHandleGetList();
	}, [formik, onHandleGetList]);

	const onHandleSetLastFilter = useCallback(() => {
		if (queryParams.current["attendanceRecordDate"]) formik.setFieldValue("submitDate", dayjs(queryParams.current["attendanceRecordDate"]));
	}, [formik]);

	//prettier-ignore
	const onHandleViewCorrectiveAttendance = useCallback((obj) => {
		correctiveAttendanceRef.current.onHandleShow(data.content[obj.rowIndex]);
	}, [data]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "attendanceRecordSignature.submittedBy",
			label: "Employee ID",
			options: {
				sort: false
			}
		},
		{
			name: "attendanceRecordSignature.submittedByName",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "attendanceRecordSignature.correctiveDate",
			label: "Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			}
		},
		{
			name: "clockIn",
			label: "Previous Clock In",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.H_MM_A)
			}
		},
		{
			name: "symbol",
			options: {
				sort: false,
				customHeadRender: () => {
					return (
						<TableCell key="table-key-clock-in" className="app-table__cell app-table__cell--header">
							<AppIcon src={chevronIcon} />
						</TableCell>
					);
				},
				customBodyRender: () => <AppIcon src={chevronIcon} />
			}
		},
		{
			name: "clockInCorrective",
			label: "Corrected Clock In",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.H_MM_A)
			}
		},
		{
			name: "clockOut",
			label: "Previous Clock Out",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.H_MM_A)
			}
		},
		{
			name: "symbol",
			options: {
				sort: false,
				customHeadRender: () => {
					return (
						<TableCell key="table-key-clock-out" className="app-table__cell app-table__cell--header">
							<AppIcon src={chevronIcon} />
						</TableCell>
					);
				},
				customBodyRender: () => <AppIcon src={chevronIcon} />
			}
		},
		{
			name: "clockOutCorrective",
			label: "Corrected Clock Out",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.H_MM_A)
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: false,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__view" onClick={() => onHandleViewCorrectiveAttendance(tableMeta)}>
							<img src={eyeIcon} alt="view-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleViewCorrectiveAttendance]);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		},
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.CORRECTIVE_ATTENDANCE_HISTORY_GENERAL);
		};
	}, [cancelRequest]);

	return (
		<div className="app-corrective-attendance-history-table">
			<div className="corrective-attendance-history-table">
				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Name or Employee ID" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="corrective-attendance-history-table-filter">
						<AppInputDate name="submitDate" label="Date" placeholder="DD/MM/YYYY" value={formik.values.submitDate} onChange={formik.setFieldValue} />
					</div>
				</AppTableFilterHeader>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>

			<AppCorrectiveAttendanceViewModal ref={correctiveAttendanceRef} />
		</div>
	);
};

export default AppCorrectiveAttendanceHistoryTable;
