import React, { useCallback, useContext, useEffect, useMemo } from "react";

import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import useBreadcrumb from "hooks/use-breadcrumb";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ENDPOINT_PATH from "constants/end-point-path";

import AppTabs from "components/app-tabs";
import AppStatus from "components/app-status";
import AppAttendanceSummaryCalendar from "components/pages/human-resources/app-attendance-summary-calendar";
import AppScheduledAttendanceEmployeeCalendar from "components/pages/human-resources/app-scheduled-attendance-employee-calendar";
import AppCorrectiveAttendanceHistoryEmployeeTable from "components/pages/human-resources/app-corrective-attendance-history-employee-table";
import AppPendingCorrectiveAttendanceEmployeeTable from "components/pages/human-resources/app-pending-corrective-attendance-employee-table";

import clockIcon from "assets/images/pages/human-resources/clock-icon.svg";
import leaveIcon from "assets/images/pages/human-resources/leave-icon.svg";
import hourglassIcon from "assets/images/pages/human-resources/hourglass-icon.svg";
import clockActiveIcon from "assets/images/pages/human-resources/clock-active-icon.svg";
import leaveActiveIcon from "assets/images/pages/human-resources/leave-active-icon.svg";
import hourglassActiveIcon from "assets/images/pages/human-resources/hourglass-active-icon.svg";
import pencilSignatureIcon from "assets/images/pages/human-resources/pencil-signature-icon.svg";
import pencilSignatureActiveIcon from "assets/images/pages/human-resources/pencil-signature-active-icon.svg";

const PageViewAttendance = () => {
	const { id } = useParams();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;

	const formik = useFormik({
		initialValues: {
			fullName: "",
			employeeId: "",
			todayStatus: ""
		}
	});

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Attendance Management", path: pathnames.humanResources.attendanceManagement },
			{ label: `${formik.values.employeeId} ${formik.values.fullName}  Attendance`, path: pathnames.humanResources.viewAttendance }
		];

		return data;
	}, [formik.values]);

	useBreadcrumb({ breadCrumb });

	//prettier-ignore
	const attendanceTabs = useMemo(() => [
		{ label: "Attendance Summary", icon: leaveIcon, activeIcon: leaveActiveIcon, name: "ATTENDANCE_SUMMARY", component: <AppAttendanceSummaryCalendar />, accessible: true },
		{ label: "Scheduled Attendance", icon: pencilSignatureIcon, activeIcon: pencilSignatureActiveIcon, name: "SCHEDULED_ATTENDANCE", component: <AppScheduledAttendanceEmployeeCalendar />, accessible: true },
		{ label: "Corrective Attendance History", icon: clockIcon, activeIcon: clockActiveIcon, name: "CORRECTIVE_ATTENDANCE_HISTORY", component: <AppCorrectiveAttendanceHistoryEmployeeTable />, accessible: true },
		{ label: "Pending Corrective Attendance", icon: hourglassIcon, activeIcon: hourglassActiveIcon, name: "PENDING_CORRECTIVE_ATTENDANCE", component: <AppPendingCorrectiveAttendanceEmployeeTable />, accessible: true }
	], []);

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		const params = sanitizeObject({ "employee-id": uniqueId });

		try {
			response = await api.get.humanResource.attendanceEmployeeDetails(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				fullName: response.fullName,
				employeeId: response.employeeId,
				todayStatus: response.todayStatus
			});
		}
	}, [memoSetFormValues]);

	useEffect(() => {
		onHandleGetDetails(id);
	}, [id, onHandleGetDetails]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.ATTENDANCE_EMPLOYEE_DETAILS);
		};
	}, [cancelRequest]);

	return (
		<div className="page-view-attendance">
			<div className="view-attendance">
				<div className="view-attendance__header">
					<h1 className="view-attendance__title">{formik.values.fullName}</h1>

					<AppStatus status={formik.values?.todayStatus} />
				</div>

				<div className="view-attendance__body">
					<p className="view-attendance__id">{formik.values.employeeId}</p>

					<AppTabs tabs={attendanceTabs} />
				</div>
			</div>
		</div>
	);
};

export default PageViewAttendance;
