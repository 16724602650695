import api from "services/api";

import serveRequestErrors from "common/serve-request-errors";

const getInventoryListing = async (payload) => {
	let response = null;
	let errorMessage = null;

	const params = { page: payload?.page || 0, size: 10, keyword: payload?.keyword || "" };

	try {
		const res = await api.get.inventory.inventories(params);

		const content = res.content.map((item) => ({ value: item.id, label: item.sparePart.name }));

		response = { ...res, content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getInventoryListing;
