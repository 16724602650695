import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import STATUS from "constants/status";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";

const PageDeliveryOrders = () => {
	const navigate = useNavigate();
	const [data, setData] = useState(convertPaginationTableData());
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const defaultStatus = useMemo(() => [STATUS.DRAFT, STATUS.COMPLETED, STATUS.IN_PROGRESS, STATUS.CANCELLED, STATUS.DRAFT].join(","), []);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", status: searchParams.get("status") || defaultStatus, createdBy: searchParams.get("createdBy") || "" });

	const formik = useFormik({ initialValues: { search: "", createdBy: "", status: [STATUS.DRAFT, STATUS.COMPLETED, STATUS.IN_PROGRESS, STATUS.CANCELLED, STATUS.DRAFT] }, onSubmit: (values) => onHandleSubmit(values) });

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.deliveryOrders.deliveryOrders(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");
		queryParams.current.createdBy = values.createdBy;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
		formik.setFieldValue("createdBy", queryParams.current.createdBy);
	}, [formik]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleEditViewDeliveryOrders = useCallback((obj) => {
		navigate(pathnames.sales.deliveryOrdersViewEdit + data.content[obj.rowIndex].id, { state: data.content[obj.rowIndex].status });
	}, [data, navigate]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Draft", name: STATUS.DRAFT },
			{ label: "In-Progress", name: STATUS.IN_PROGRESS },
			{ label: "Completed", name: STATUS.COMPLETED },
			{ label: "Cancelled", name: STATUS.CANCELLED }
		];

		return data;
	}, []);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.status = defaultStatus;

		onHandleGetList();
	}, [formik, defaultStatus, onHandleGetList]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "referenceNo",
			label: "Delivery Order ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					const referenceNo = data.content[tableMeta.rowIndex].deliveryOrderReferenceNo

					return referenceNo;
				}
			}
		},
		{
			name: "customerName",
			label: "Customer Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "saleOrderReferenceNo",
			label: "Sales Order",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "su.employeeDetail.personalInfo.fullName",
			label: "Created By",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					const createdBy = data.content[tableMeta.rowIndex]?.name

					return createdBy;
				}
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "action",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleEditViewDeliveryOrders(tableMeta)}>
							<img src={chevronIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [data.content, onHandleEditViewDeliveryOrders]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.DELIVERY_ORDERS.DELIVERY_ORDERS);
		};
	}, [cancelRequest]);

	return (
		<div className="page-delivery-orders">
			<div className="delivery-orders">
				<h1 className="delivery-orders__title">Delivery Orders</h1>

				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder={"Search by Delivery Order ID or Customer Name"} searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="delivery-orders-filter">
						<div className="delivery-orders-filter__container">
							<div className="delivery-orders-filter__row">
								<AppInput type="text" label="Created by" name="createdBy" placeholder="User ID or Name" value={formik.values.createdBy} onChange={formik.handleChange} />
							</div>

							<div className="delivery-orders-filter__checkboxes">
								<p className="delivery-orders-filter__label">Status</p>

								<div className="delivery-orders-filter__checkbox-status">
									{menuFilterStatus.map((o) => {
										const isActiveStatus = formik.values.status.findIndex((i) => i === o.name) > -1;

										return (
											<div className="delivery-orders-filter__checkbox" key={o.label}>
												<AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActiveStatus} />
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</AppTableFilterHeader>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>
		</div>
	);
};

export default PageDeliveryOrders;
