import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import AppButton from "components/app-button";

export const AppWorkCompletionReportCancelConfirmationModal = (props, ref) => {
	const [visible, setVisible] = useState(false);

	const onHandleConfirm = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-work-completion-report-cancel-confirmation-modal" }} open={visible}>
			<div className="work-completion-report-cancel-confirmation-modal">
				<h1 className="work-completion-report-cancel-confirmation-modal__title">Cancel Work Inspection Report?</h1>

				{/* prettier-ignore */}
				<p className="work-completion-report-cancel-confirmation-modal__description">
					After clicking confirm, <br/> the Work Completion Report will be <span className="work-completion-report-cancel-confirmation-modal__description work-completion-report-cancel-confirmation-modal__description--red">Cancelled</span>.
				</p>

				<div className="work-completion-report-cancel-confirmation-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="submit" label="Confirm" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppWorkCompletionReportCancelConfirmationModal));

AppWorkCompletionReportCancelConfirmationModal.propTypes = {
	ref: PropTypes.object
};
