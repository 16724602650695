import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { AxiosContext } from "contexts/with-interceptor-provider";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ENDPOINT_PATH from "constants/end-point-path";

import AppStatus from "components/app-status";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";

const AppSalesOrderInvoices = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [data, setData] = useState(convertPaginationTableData());
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "" });

	const onHandleAddInvoices = useCallback(async () => {
		let response = null;

		try {
			response = await api.post.invoice.createInvoice(id);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			navigate(pathnames.sales.invoiceCreateEdit + response.id);
		}
	}, [id, navigate]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10, "sales-order-id": id });

			memoSearchParams.current(params);

			response = await api.get.invoice.salesOrderSearch(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, [id]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.name = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleEditInvoice = useCallback((tableMeta) => {
		const id = data.content[tableMeta.rowIndex]?.id;

		navigate(pathnames.sales.invoiceCreateEdit + id);
	}, [navigate, data]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "referenceNo",
			label: "Invoice ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "title",
			label: "title",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "totalAmount",
			label: "Amount",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => value.toFixed(2)
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />
			},
		},
		{
			name: "name",
			label: "Created By",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "action",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleEditInvoice(tableMeta)}>
							<img src={chevronIcon} alt="action-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleEditInvoice]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	const emptyState = useMemo(() => {
		if (data?.content?.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={tableColumns.length} align="center">
						<p className="table__text">
							No Invoice Created.
							<span className="table__link" onClick={onHandleAddInvoices}>
								Create Invoice?
							</span>
						</p>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [data.content.length, onHandleAddInvoices, tableColumns.length]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.INVOICE.SALES_ORDER_SEARCH);
		};
	}, [cancelRequest]);

	return (
		<div className="app-sales-order-invoice">
			<div className="sales-order-invoice">
				<AppTableFilterHeader searchPlaceholder="Search by Invoice ID or Title" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} onHandleAdd={onHandleAddInvoices} />

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} components={emptyState} />
			</div>
		</div>
	);
};

export default AppSalesOrderInvoices;
