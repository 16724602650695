import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import useBeforeUnload from "hooks/use-before-unload";

import serveRequestErrors, { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";
import SIGNATURE_TYPE from "constants/signature-type";

import AppTabs from "components/app-tabs";
import AppButton from "components/app-button";
import AppWorkCompletionWorkDetails from "components/pages/work-completion-report/app-work-completion-work-details";
import AppWorkCompletionReportDetails from "components/pages/work-completion-report/app-work-completion-report-details";
import AppWorkCompletionReportCancelConfirmationModal from "components/pages/work-completion-report/app-work-completion-report-cancel-confirmation-modal";

import exportIcon from "assets/images/export-icon.png";
import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";
import workDetailsIcon from "assets/images/pages/work-completion-report/work-details-icon.svg";
import reportDetailsIcon from "assets/images/pages/work-completion-report/report-details-icon.svg";
import workDetailsActiveIcon from "assets/images/pages/work-completion-report/work-details-active-icon.svg";
import reportDetailsActiveIcon from "assets/images/pages/work-completion-report/report-details-active-icon.svg";

const TABS = {
	REPORT_DETAILS: "REPORT_DETAILS",
	WORK_DETAILS: "WORK_DETAILS"
};

const PageWorkCompletionReportCreateEdit = () => {
	useBeforeUnload();
	const { id } = useParams();
	const [wcrInfo, setWcrInfo] = useState({});
	const [menuAnchor, setMenuAnchor] = useState(null);
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const title = useMemo(() => (isCreate ? "Create Work Completion Report" : "Edit Work Completion Report"), [isCreate]);
	const tabRef = useRef();
	const nextTab = useRef();
	const workDetailsRef = useRef();
	const cancelConfirmationModal = useRef();
	const initialValues = useMemo(() => ({ lastModifiedBy: "", lastModifiedDate: "" }), []);

	const formik = useFormik({ initialValues: initialValues });

	const onHandleLeaveWorkDetails = useCallback(() => {
		tabRef.current.onHandleSelectTab(nextTab.current);

		nextTab.current = null;
	}, []);

	const onHandleSetWcrInfo = useCallback((data) => {
		setWcrInfo(data);
	}, []);

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (workOrderReportId) => {
		let response = null;

		try {
			response = await api.get.workCompletionReport.report(workOrderReportId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			onHandleSetWcrInfo({ ...response });
			
			memoSetFormValues({
				lastModifiedBy: response.lastModifiedBy,
				lastModifiedDate: dayjs(response.lastModifiedDate).format(DATE_TIME.LAST_UPDATED_BY_DATE),
			});
		}
	}, [memoSetFormValues, onHandleSetWcrInfo]);

	const onHandleTabChange = useCallback((obj, currentTab) => {
		const isFindingDetailsTab = currentTab === TABS.WORK_DETAILS;
		const onHandleCheckFormikDirty = workDetailsRef.current?.onHandleCheckFormikDirty();

		nextTab.current = obj.name;

		if (isFindingDetailsTab && onHandleCheckFormikDirty) {
			return workDetailsRef.current?.onHandleLeavePageModal();
		}

		tabRef.current.onHandleSelectTab(nextTab.current);
	}, []);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);
	}, []);

	const onHandleSetCancelled = () => {
		setMenuAnchor(false);

		wcrInfo.signatureType = SIGNATURE_TYPE.CANCELLED_BY;

		cancelConfirmationModal.current.onHandleShow(wcrInfo);
	};

	// TODO: When send for signature feature is ready
	const onHandleSendForSignature = () => {
		setMenuAnchor(false);
	};

	const onHandleExportPDF = useCallback(async () => {
		let response = null;
		let fileName = "";

		try {
			const payload = { "work-completion-id": id };

			const transformResponse = (data, headers) => {
				fileName = headers?.["content-disposition"]?.split("inline; filename=")?.[1]?.split(";")?.[0];

				if (fileName) return data;

				try {
					const jsonResponse = JSON.parse(new TextDecoder().decode(data));

					if (jsonResponse) return jsonResponse;
				} catch (error) {
					return data;
				}
			};

			response = await api.post.workCompletionReport.generateWorkCompletionReportPdf(payload, { transformResponse });
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const a = document.createElement("a");
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(new Blob([response]), { type: "application/octet-stream" });
			a.href = url;
			a.download = fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			}, 1000);
		}
	}, [id]);

	const HeaderLinkButton = useCallback((obj) => {
		if (!obj.isCreate)
			return (
				<div className="work-completion-report-create-edit__header-button">
					<AppButton className="work-completion-report-create-edit__action-button" type="button" label="Actions" icon={chevronIcon} onClick={obj.onToggleFilterMenu} />

					<AppButton type="button" label="Export as PDF" icon={exportIcon} onClick={obj.onHandleExportPDF} />
				</div>
			);
	}, []);

	const tabs = useMemo(() => {
		let data = [{ label: "Report Details", icon: reportDetailsIcon, activeIcon: reportDetailsActiveIcon, name: "REPORT_DETAILS", component: <AppWorkCompletionReportDetails wcrInfo={wcrInfo} onHandleSetWcrInfo={onHandleSetWcrInfo} />, accessible: true }];

		if (!isCreate) {
			data.push({ label: "Work Details", icon: workDetailsIcon, activeIcon: workDetailsActiveIcon, name: "WORK_DETAILS", component: <AppWorkCompletionWorkDetails ref={workDetailsRef} woAsset={wcrInfo?.woAsset} onHandleLeaveWorkDetails={onHandleLeaveWorkDetails} />, accessible: true });
		}

		return data;
	}, [isCreate, onHandleLeaveWorkDetails, onHandleSetWcrInfo, wcrInfo]);

	useEffect(() => {
		if (!isCreate) onHandleGetDetails(id);
	}, [id, isCreate, onHandleGetDetails]);

	useEffect(() => {
		return () => {
			if (!isCreate) cancelRequest(ENDPOINT_PATH.WORK_COMPLETION_REPORT.REPORT);
		};
	}, [cancelRequest, isCreate]);

	return (
		<div className="page-work-completion-report-create-edit">
			<div className="work-completion-report-create-edit">
				<div className="work-completion-report-create-edit__header">
					<h1 className="work-completion-report-create-edit__title">{title}</h1>

					<HeaderLinkButton isCreate={isCreate} onToggleFilterMenu={onToggleFilterMenu} onHandleExportPDF={onHandleExportPDF} />
				</div>

				<div className="work-completion-report-create-edit__body">
					{!isCreate && (
						<p className="work-completion-report-create-edit__last-update">
							<span>Last Updated By </span> {formik.values.lastModifiedBy}, {formik.values.lastModifiedDate}
						</p>
					)}

					<AppTabs ref={tabRef} tabs={tabs} onChange={onHandleTabChange} />
				</div>
			</div>

			<Menu classes={{ root: "work-completion-report-create-edit-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleSetCancelled}>Set As Cancelled</MenuItem>

				<MenuItem onClick={onHandleSendForSignature}>Send for Signature</MenuItem>
			</Menu>

			<AppWorkCompletionReportCancelConfirmationModal ref={cancelConfirmationModal} />
		</div>
	);
};

export default PageWorkCompletionReportCreateEdit;
