import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import Menu from "@mui/material/Menu";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";
import getInventoryLocationListing from "services/get-inventory-location-listing";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import STATUS from "constants/status";
import ENDPOINT_PATH from "constants/end-point-path";

import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppInputMultiSelect from "components/app-input-select-multiple";
import AppInventoryRetireModal from "components/pages/inventory/app-inventory-retire-modal";
import AppInventoryTransferModal from "components/pages/inventory/app-inventory-transfer-modal";

import editIcon from "assets/images/edit-icon.png";
import closeIcon from "assets/images/add-blue-icon.png";
import moreIcon from "assets/images/vertical-breadcrumbs-icon.png";
import transferIcon from "assets/images/pages/inventory/transfer-icon.png";

const PageInventories = () => {
	const navigate = useNavigate();
	const selectedItem = useRef();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const inventoryRetireModalRef = useRef();
	const inventoryTransferModalRef = useRef();
	const [data, setData] = useState(convertPaginationTableData());
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const [inventoryTableAnchor, setInventoryTableAnchor] = useState(null);
	const defaultLocation = useMemo(() => [], []);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ROLE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const defaultStatus = useMemo(() => [STATUS.IN_USE, STATUS.RETIRED].join(","), []);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", status: searchParams.get("status") || defaultStatus, locationIds: searchParams.get("locationIds") || defaultLocation });

	const formik = useFormik({ initialValues: { search: "", locationIds: [], status: [STATUS.IN_USE, STATUS.RETIRED] }, onSubmit: (values) => onHandleSubmit(values) });

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.inventory.inventories(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {		
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");
		queryParams.current.locationIds = values.locationIds;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
		formik.setFieldValue("locationIds", queryParams.current.locationIds);
	}, [formik]);

	const onHandleCloseInventoryTableMenu = useCallback(() => {
		setInventoryTableAnchor(null);
	}, []);

	//prettier-ignore
	const onToggleTableAction = useCallback((event, tableMeta) => {
		selectedItem.current = { ...data.content[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		setInventoryTableAnchor(event.currentTarget);
	}, [data]);

	const onHandleCreateInventory = useCallback(() => {
		navigate(pathnames.inventory.inventoryCreateEdit + PAGE.CREATE);
	}, [navigate]);

	const onHandleEditInventory = useCallback(() => {
		navigate(pathnames.inventory.inventoryCreateEdit + selectedItem.current.id);
	}, [navigate]);

	const onHandleInventoryTransfer = () => {
		inventoryTransferModalRef.current.onHandleShow(selectedItem.current);
	};

	const onHandleInventoryRetire = () => {
		setInventoryTableAnchor(null);

		inventoryRetireModalRef.current.onHandleShow(selectedItem.current.id, selectedItem.current.referenceNo);
	};

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.status = defaultStatus;
		queryParams.current.locationIds = defaultLocation;

		onHandleGetList();
	}, [formik, defaultStatus, defaultLocation, onHandleGetList]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "In-Use", name: STATUS.IN_USE },
			{ label: "Retired", name: STATUS.RETIRED }
		];

		return data;
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "referenceNo",
			label: "Inventory ID",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "sparePart.name",
			label: "Inventory Item",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "inventoryLocation.name",
			label: "Location",
			options: {
				sort: false
			}
		},
		{
			name: "quantity",
			label: "Quantity",
			options: {
				sort: true,
				sortThirdClickReset: true			
			}
		},
		{
			name: "available",
			label: "Available",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "costPrice",
			label: "Cost Price (MYR)",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "retailPrice",
			label: "Selling Price (MYR)",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "edit",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					if (restricted) return null;

					return (
						<button type="button" className="table__action" onClick={(event) => onToggleTableAction(event, tableMeta)}>
							<img src={moreIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [onToggleTableAction, restricted]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess:".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		},
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.INVENTORY.INVENTORIES);
		};
	}, [cancelRequest]);

	return (
		<div className="page-inventory">
			<div className="inventory">
				<h1 className="inventory__title">Inventory Listing</h1>

				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Inventory ID or Inventory Item" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} onHandleAdd={onHandleCreateInventory} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="inventory-filter">
						<AppInputMultiSelect menuPropsRootClasses={["inventory-filter-multi-select-popup"]} label="Location" name="locationIds" placeholder="Select..." loadOptions={getInventoryLocationListing} value={formik.values.locationIds} error={formik.errors.locationIds} touched={formik.touched.locationIds} onChange={formik.handleChange} />

						<div className="inventory-filter__container">
							<p className="inventory-filter__label">Status</p>

							{menuFilterStatus.map((o) => {
								const isActive = formik.values.status.findIndex((i) => i === o.name) > -1;

								return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
							})}
						</div>
					</div>
				</AppTableFilterHeader>

				<div className="inventory__body">
					<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
				</div>
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "inventory-table-menu" }} anchorEl={inventoryTableAnchor} open={!!inventoryTableAnchor} onClose={onHandleCloseInventoryTableMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleEditInventory}><img src={editIcon} alt="inventory-edit"/>Edit</MenuItem>

				<MenuItem onClick={onHandleInventoryRetire}><img className="inventory-table-menu__close-icon" src={closeIcon} alt="inventory-close"/>Retire</MenuItem>
				
				<MenuItem onClick={onHandleInventoryTransfer}><img src={transferIcon} alt="inventory-transfer"/>Transfer</MenuItem>
			</Menu>

			<AppInventoryTransferModal ref={inventoryTransferModalRef} onHandleGetList={onHandleGetList} />

			<AppInventoryRetireModal ref={inventoryRetireModalRef} onHandleGetList={onHandleGetList} />
		</div>
	);
};

export default PageInventories;
