import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ENDPOINT_PATH from "constants/end-point-path";

import AppCalendarSchedule from "components/app-calendar-schedule/app-calendar-schedule";

import faqIcon from "assets/images/pages/human-resources/faq-icon.png";

const AppLeaveCalendar = () => {
	const [data, setData] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const [selectedObservance, setSelectedObservance] = useState("");
	const [selectedDescription, setSelectedDescription] = useState("");
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const queryParams = useRef({ year: searchParams.get("year") || new Date().getFullYear(), month: searchParams.get("month") || new Date().getMonth() + 1 });

	//prettier-ignore
	const handleMenuOpen = useCallback((event, observance) => {
		const observanceData = data.find((item) => item.name === observance || (Array.isArray(item.observances) && item.observances.some((o) => o.name === observance)));

		setAnchorEl(event.currentTarget);
		setSelectedObservance(observance);
		setSelectedDescription(observanceData?.description || "");
	}, [data]);

	const handleMenuClose = () => {
		setAnchorEl(null);
		setSelectedObservance("");
		setSelectedDescription("");
	};

	const onHandleGetList = useCallback(async () => {
		try {
			const params = sanitizeObject({ ...queryParams.current });

			memoSearchParams.current(params);

			const [holidayResponse, leaveResponse] = await Promise.all([api.get.humanResource.holidayCalendar(params), api.get.humanResource.subordinateLeaveCalendar(params)]);

			if (holidayResponse && leaveResponse) {
				const holidayData = holidayResponse.observances.map((observance) => ({
					date: observance.date,
					name: observance.name,
					description: observance.description,
					employeeLeaveObeservances: []
				}));

				const leaveData = leaveResponse.observances.map((observance) => ({
					date: observance.startDate,
					employeeLeaveObeservances: [
						{
							name: `${observance.employeeName} - ${observance.leaveType}`,
							leaveType: observance.leaveType,
							timeOffType: observance.timeOffType,
							employeeId: observance.employeeId
						}
					]
				}));

				const dateMap = new Map();

				holidayData.forEach((holiday) => {
					dateMap.set(holiday.date, holiday);
				});

				leaveData.forEach((leave) => {
					if (dateMap.has(leave.date)) {
						const existingEntry = dateMap.get(leave.date);

						existingEntry.employeeLeaveObeservances = [...existingEntry.employeeLeaveObeservances, ...leave.employeeLeaveObeservances];
					} else {
						dateMap.set(leave.date, leave);
					}
				});

				const mergedData = Array.from(dateMap.values()).sort((a, b) => new Date(a.date) - new Date(b.date));

				setData(mergedData);
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		}
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			label: "Observances",
			name: "name",
			options: {
				sort: false,
				customBodyRender: (value) => {
					if (Array.isArray(value)) {
						return (
							<div className="leave-calendar__list">
								{value.map((observances, index) => (
									<div key={index} className="leave-calendar__item">
										<img src={observances.icon} alt={observances.name} className="leave-calendar__image" />
										
										{observances.name}
									</div>
								))}
							</div>
						);
					} else if (typeof value === "string" && value) {
						return (
							<div className="leave-calendar__holiday">
								{value}
							
								<img src={faqIcon} alt="chevron icon" className="leave-calendar__faq-icon" onClick={(event) => handleMenuOpen(event, value)} />
							</div>
						);
					}

					return value;
				}
			}
		},
		{
			label: "Employees on Leave Observances",
			name: "employeeLeaveObeservances",
			options: {
				sort: false,
				customBodyRender: (value) => {
					if (Array.isArray(value)) {
						return (
							<div className="leave-calendar__employee-leave-obeservances">
								{value.map((employeeLeaveObeservances, index) => (
									<div key={index} className="leave-calendar__employee-leave-obeservances">
										{employeeLeaveObeservances.name}
									</div>
								))}
							</div>
						);
					}

					return value;
				}
			}
		}
	], [handleMenuOpen]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		customFooter: () => null
	}), []);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.HOLIDAY_CALENDAR);

			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.SUBORDINATE_LEAVE_CALENDAR);
		};
	}, [cancelRequest]);

	return (
		<div className="app-leave-calendar">
			<div className="leave-calendar">
				<AppCalendarSchedule data={data} columns={tableColumns} options={tableOptions} />
			</div>

			<Menu classes={{ root: "leave-calendar-table-menu" }} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
				<MenuItem onClick={handleMenuClose}>{selectedObservance}</MenuItem>

				{selectedDescription && <MenuItem onClick={handleMenuClose}>{selectedDescription}</MenuItem>}
			</Menu>
		</div>
	);
};

export default AppLeaveCalendar;
