import sideNavList from "common/side-nav-list";

const findFirstAccessiblePath = (permissions) => {
	const visibleNavItems = sideNavList
		.filter((item) => !item.hide)
		.map((item, index) => ({ ...item, originalIndex: index }))
		.sort((a, b) => a.originalIndex - b.originalIndex);

	for (const navItem of visibleNavItems) {
		if (!navItem.childrens.length) {
			if (!navItem.permission.length || navItem.permission.some((perm) => permissions[perm]?.view)) {
				return navItem.path;
			}
			
			continue;
		}

		for (const child of navItem.childrens) {
			if (!child.permissions.length || child.permissions.some((perm) => permissions[perm]?.view)) {
				return child.path;
			}
		}
	}

	return null;
};

export default findFirstAccessiblePath;
