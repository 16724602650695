import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { Modal } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import api from "services/api";
import getInventoryListing from "services/get-inventory-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

const AppWorkOrderCreateEditInventoryModal = (props, ref) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.POSITION_LEVEL_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const onHandleGetList = useMemo(() => props.onHandleGetList, [props.onHandleGetList]);

	const initialValues = useMemo(() => {
		const values = {
			id: "",
			inventoryId: "",
			location: "",
			availableQuantity: "",
			quantityNeeded: ""
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			inventoryId: yup.string().required(ERRORS.REQUIRED),
			quantityNeeded: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const isCreate = useMemo(() => !formik.values.id, [formik.values.id]);
	const title = useMemo(() => (isCreate ? "Add Inventory" : "Edit Inventory"), [isCreate]);
	const submitLabel = useMemo(() => (isCreate ? "Add" : "Update"), [isCreate]);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({ id: obj?.id || "", inventoryId: obj?.inventoryId || "", location: obj?.inventoryLocationName || "", availableQuantity: obj?.availableQuantity || "", quantityNeeded: obj?.quantity || "" });

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	//prettier-ignore
	const onHandleChangeInventory = useCallback(async (event) => {
		const value = event.target.value;

		let response = null;

		try {
			response = await api.get.inventory.inventory({ "inventory-id": value });
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			let nextValues = structuredClone(formik.values);

			formik.setValues({ ...nextValues, inventoryId: response?.id, location: response.inventoryLocation?.name, availableQuantity: response.available });
		}
	}, [formik]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				inventoryId: values.inventoryId,
				quantity: values.quantityNeeded
			};

			if (isCreate) {
				payload.workOrderId = id;

				response = await api.post.workOrder.createInventory(payload);
			}

			if (!isCreate) {
				payload.id = values.id;

				response = await api.post.workOrder.updateInventory(payload);
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Inventory was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Inventory was updated successfully!" }));
			}

			onHandleGetList();

			onHandleDismiss();
		}
		
	}, [dispatch, formik, id, isCreate, onHandleDismiss, onHandleGetList]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-work-order-create-edit-inventory-modal" }} open={visible}>
			<div className="work-order-create-edit-inventory-modal">
				<h1 className="work-order-create-edit-inventory-modal__title">{title}</h1>

				<form className="work-order-create-edit-inventory-modal__form" onSubmit={formik.handleSubmit}>
					<AppSelectInput required searchable={false} disabled={restricted} name="inventoryId" label="Inventory Item" placeholder="Search for Inventory Item" loadOptions={getInventoryListing} value={formik.values.inventoryId} error={formik.errors.inventoryId} touched={formik.touched.inventoryId} onChange={onHandleChangeInventory} />

					<AppInput disabled type="text" name="location" label="Location" placeholder="" value={formik.values.location} touched={formik.touched.location} onChange={formik.handleChange} />

					<AppInput disabled type="number" name="availableQuantity" label="Available Quantity" placeholder="" value={formik.values.availableQuantity} touched={formik.touched.availableQuantity} onChange={formik.handleChange} />

					<AppInput required type="number" disabled={restricted} name="quantityNeeded" label="Quantity Needed" placeholder="Enter Quantity Needed" value={formik.values.quantityNeeded} error={formik.errors.quantityNeeded} touched={formik.touched.quantityNeeded} onChange={formik.handleChange} />

					<div className="work-order-create-edit-inventory-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label={submitLabel} disabled={formik.isSubmitting || restricted} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppWorkOrderCreateEditInventoryModal));
