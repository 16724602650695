import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import classNames from "common/class-names";
import getTotalDays from "common/get-total-days";
import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ROLES from "constants/roles";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppClaimHistoryModal from "components/pages/human-resources/app-claim-history-modal";
import AppPendingClaimPaidModal from "components/pages/human-resources/app-pending-claim-paid-modal";

import eyeIcon from "assets/images/eye-open-icon.png";

const AppClaimHistoryTable = (props) => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const pendingClaimPaidModal = useRef();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const [data, setData] = useState(convertPaginationTableData());
	const claimHistoryRef = useRef();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CLAIM_MANAGEMENT], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const isHR = useMemo(() => props.isHR && !restricted, [props.isHR, restricted]);
	const defaultStatus = useMemo(() => [STATUS.APPROVED, STATUS.PAID, STATUS.REJECTED, STATUS.CANCELLED].join(","), []);
	const status = useMemo(() => {
		const currentStatuses = searchParams.get("status")?.split(",");
		const relevantStatus = currentStatuses?.every((e) => defaultStatus.split(",").includes(e));

		if (!relevantStatus) {
			return defaultStatus;
		} else {
			return searchParams.get("status");
		}
	}, [defaultStatus, searchParams]);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", "start-date": searchParams.get("start-date") || "", "end-date": searchParams.get("end-date") || "", status: status, tab: "CLAIM_HISTORY" });

	const onHandleGetList = useCallback(async (id) => {
		let response = null;

		const params = sanitizeObject({ ...queryParams.current, "employee-id": id, size: 10 });

		try {
			memoSearchParams.current(params);

			response = await api.get.humanResource.employeeClaimHistory(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	const validationSchema = yup.object({
		endDate: yup.date().test(function (value, { parent }) {
			if (!value) return true;

			const totalDays = getTotalDays(parent.startDate, value);

			return totalDays >= 0 && totalDays <= 90;
		})
	});

	const formik = useFormik({
		initialValues: { submitDate: "", startDate: "", endDate: "", claimType: "", receiptAmount: "", status: [STATUS.APPROVED, STATUS.PAID, STATUS.REJECTED, STATUS.CANCELLED] },
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const intructionClassName = useMemo(() => classNames({
		"filter-menu__instruction": true,
		"filter-menu__instruction--error": formik.errors.endDate
	}), [formik.errors.endDate]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Approved", name: STATUS.APPROVED },
			{ label: "Paid", name: STATUS.PAID },
			{ label: "Rejected", name: STATUS.REJECTED },
			{ label: "Cancelled", name: STATUS.CANCELLED }
		];

		return data;
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current["start-date"] = "";
		queryParams.current["end-date"] = "";
	}, [formik]);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");
		
		if (values.startDate && dayjs(values.startDate).isValid()) {
			queryParams.current["start-date"] = dayjs(values.startDate).format(DATE_TIME.YYYY_MM_DD);
		}
		if (values.endDate && dayjs(values.endDate).isValid()) {
			queryParams.current["end-date"] = dayjs(values.endDate).format(DATE_TIME.YYYY_MM_DD);
		}
		
		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
		formik.setFieldValue("start-date", queryParams.current["start-date"]);
		formik.setFieldValue("end-date", queryParams.current["end-date"]);
	}, [formik]);

	//prettier-ignore
	const onHandleViewClaimDetails = useCallback((obj) => {
		claimHistoryRef.current.onHandleShow(data.content[obj.rowIndex]);
	}, [data]);

	//prettier-ignore
	const onHandlePaid = useCallback((id) => {
		const backData = data.content.filter((item) => id.includes(item.id));
		
		pendingClaimPaidModal.current.onHandleShow(backData);
	}, [data]);

	//prettier-ignore
	const onHandleClaimPaid = useCallback(async (ids) => {
		let response = null;
		
		try {
			await api.post.humanResource.paidEmployeeClaim({ "claim-id": ids });

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Claim was paid successfully" }));

			onHandleGetList(id);
		}
	}, [dispatch, onHandleGetList, id]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "claimType",
			label: "Claim Type",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "createdDate",
			label: "Submit Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const submitDateData = data.content[rowIndex].submitDate;

					return dayjs(submitDateData).format(DATE_TIME.DD_MM_YYYY);
				}
			}
		},
		{
			name: "receiptAmount",
			label: "Amount (MYR)",
			options: {
				sort: false
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: false,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" disabled={restricted} className="table__view" onClick={() => onHandleViewClaimDetails(tableMeta)}>
							<img src={eyeIcon} alt="expand-icon" />
						</button>
					);
				}
			}
		}
	], [data.content, onHandleViewClaimDetails, restricted]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList(id);
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList(id);
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList, id]);

	useEffect(() => {
		onHandleGetList(id);
	}, [id, onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_CLAIM_HISTORY);
		};
	}, [cancelRequest]);

	return (
		<div className="app-claim-history-table">
			<div className="claim-history-table">
				<div className="claim-history-table__header">
					<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Claim Type" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
						<div className="claim-history-table-filter">
							<div className="claim-history-table-filter__container">
								<div className="claim-history-table-filter__inputs">
									<AppInputDate name="startDate" label="Submit Date" placeholder="Start Date" value={formik.values.startDate} onChange={formik.setFieldValue} />

									<AppInputDate name="endDate" placeholder="End Date" value={formik.values.endDate} onChange={formik.setFieldValue} />
								</div>

								<p className={intructionClassName}>Please select a date or a date range of up to 90 days</p>
							</div>

							<div className="claim-history-table-filter__container">
								<p className="claim-history-table-filter__label">Status</p>

								<div className="claim-history-table-filter__checkbox-status">
									{menuFilterStatus.map((o) => {
										const isActiveStatus = formik.values.status.findIndex((i) => i === o.name) > -1;

										return (
											<div className="claim-history-table-filter__checkbox" key={o.label}>
												<AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActiveStatus} />
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</AppTableFilterHeader>
				</div>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>

			<AppPendingClaimPaidModal ref={pendingClaimPaidModal} onHandleClaimPaid={onHandleClaimPaid} />

			<AppClaimHistoryModal ref={claimHistoryRef} onHandlePaid={onHandlePaid} isHR={isHR} />
		</div>
	);
};

export default AppClaimHistoryTable;
