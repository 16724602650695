import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ROLES from "constants/roles";
import ENDPOINT_PATH from "constants/end-point-path";

import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppWorkSpaceTypesCreateEditModal from "components/pages/customer/app-work-space-types-create-edit-modal";

import editIcon from "assets/images/edit-icon.png";

const AppCustomerConfigurationWorkSpaces = () => {
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const customerConfigurationModalRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_USER], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "", tab: "WORK_SPACES" });
	const [data, setData] = useState(convertPaginationTableData());

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.customerConfiguration.workspaces(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
        queryParams.current.name = event.target.value;

        onHandleGetList();
	}, [onHandleGetList]);

	const onHandleCreateCustomerConfiguration = useCallback(() => {
		customerConfigurationModalRef.current.onHandleShow();
	}, []);

	//prettier-ignore
	const onHandleEditCustomerConfiguration = useCallback((obj) => {
		customerConfigurationModalRef.current.onHandleShow({ ...data.content[obj.rowIndex] });
	}, [data.content]);

	// prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false,
			},
		},
		{
			name: "name",
			label: "Work Space Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "description",
			label: "Description",
			options: {
				sort: false,
			},
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleEditCustomerConfiguration(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [onHandleEditCustomerConfiguration]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
        count: data.totalElements,
        page: data.page,
        serverSide: true,
        onTableChange: (action, tableState) => {
            switch(action) {
                case "changePage":
                    queryParams.current.page = tableState.page;
                    
                    onHandleGetList();
                    break;
                case "sort":
                    queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) } 

                    onHandleGetList();
                    break;
                default:
                    break;    
            }
        }
    }), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.WORK_SPACE);
		};
	}, [cancelRequest]);

	return (
		<div className="app-work-spaces-table">
			<div className="work-spaces-table">
				{/* prettier-ignore */}
				<AppTableFilterHeader searchPlaceholder="Search by Work Space Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} restricted={restricted} onHandleAdd={onHandleCreateCustomerConfiguration} />

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>

			<AppWorkSpaceTypesCreateEditModal ref={customerConfigurationModalRef} onHandleGetList={onHandleGetList} />
		</div>
	);
};

export default AppCustomerConfigurationWorkSpaces;
