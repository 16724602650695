import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { formatCurrency } from "common/format-currency-pattern";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ROLES from "constants/roles";
import STATUS from "constants/status";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";

const PageInvoices = () => {
	const navigate = useNavigate();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ROLE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const queryParams = useRef({ page: 0, value: searchParams.get("value") || "" });
	const [data, setData] = useState(convertPaginationTableData());
	const defaultStatus = useMemo(() => [STATUS.PAID, STATUS.UNPAID, STATUS.VOID, STATUS.DRAFT].join(","), []);

	const formik = useFormik({
		initialValues: { keyword: "", createdBy: "", status: defaultStatus.split(",") },
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.invoice.invoices(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");
		queryParams.current.createdBy = values.createdBy;
		queryParams.current.keyword = values.keyword;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;
		
		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status ? queryParams.current.status.split(",") : []);
	}, [formik]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.createdBy = "";
		queryParams.current.status = defaultStatus;

		onHandleGetList();
	}, [formik, defaultStatus, onHandleGetList]);

	//prettier-ignore
	const onHandleEditInvoice = useCallback((obj) => {
		navigate(pathnames.sales.invoiceCreateEdit + data.content[obj.rowIndex].id, { state: data.content[obj.rowIndex].status });
	}, [data.content, navigate]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Paid", name: STATUS.PAID },
			{ label: "Void", name: STATUS.VOID },
			{ label: "Draft", name: STATUS.DRAFT },
			{ label: "Unpaid", name: STATUS.UNPAID }
		];

		return data;
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "referenceNo",
			label: "Invoice ID",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "customerName",
			label: "Customer Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "subtotalAmount",
			label: "Total (MYR)",
			options: {
				sort: true,
				sortThirdClickReset: true,
				setCellHeaderProps: () => ({ className: "table__price" }),
				setCellProps: () => ({ className: "table__price" }),
				customBodyRender: (value, tableMeta) => {
					return formatCurrency(value);
				}
			}
		},
		{
			name: "su.employeeDetail.personalInfo.fullName",
			label: "Created By",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					const createdBy = data.content[tableMeta.rowIndex]?.name

					return createdBy;
				}
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "action",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleEditInvoice(tableMeta)}>
							<img src={chevronIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [data.content, onHandleEditInvoice]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.INVOICE.INVOICES);
		};
	}, [cancelRequest]);

	return (
		<div className="page-invoices">
			<div className="invoices">
				<h1 className="invoices__title">Invoices</h1>

				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Quotation ID or Customer Name" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} restricted={restricted} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="invoices-filter-menu">
						<div className="invoices-filter-menu__container">
							<div className="invoices-filter-menu__row">
								<AppInput type="text" label="Created by" name="createdBy" placeholder="Enter Name" value={formik.values.createdBy} onChange={formik.handleChange} />
							</div>

							<p className="invoices-filter-menu__label">Status</p>

							<div className="invoices-filter-menu__grip">
								{menuFilterStatus.map((o) => {
									const isActive = (formik.values.status || []).findIndex((i) => i === o.name) > -1;

									return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
								})}
							</div>
						</div>
					</div>
				</AppTableFilterHeader>

				<div className="invoices__body">
					<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
				</div>
			</div>
		</div>
	);
};

export default PageInvoices;
