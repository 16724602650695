import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import useBreadcrumb from "hooks/use-breadcrumb";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import CUSTOMER_TYPE from "constants/customer-type";
import IDENTITY_TYPE from "constants/identity-type";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppTable from "components/app-table/app-table";
import AppRadioInput from "components/app-radio-input";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppCustomerAddPicModal from "components/pages/customer/app-customer-add-pic-modal";
import AppCustomerDeletePicModal from "components/pages/customer/app-customer-delete-pic-modal";
import AppCustomerCreateEditContractTable from "components/pages/customer/app-customer-create-edit-contract-table";
import AppCustomerConfirmMobileAccountModal from "components/pages/customer/app-customer-confirm-mobile-account-modal";

import editIcon from "assets/images/edit-icon.png";
import trashIcon from "assets/images/trash-icon.png";
import verticalBreadcrumbsIcon from "assets/images/vertical-breadcrumbs-icon.png";

const PageCustomerListCreateEdit = (props) => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const navigate = useNavigate();
	const profile = useSelector((state) => state.profile);
	const selectedItem = useRef();
	const customerAddItemModalRef = useRef();
	const customerDeleteItemModalRef = useRef();
	const confirmMobileAccountModalRef = useRef();
	const queryParams = useRef({ page: 0, keyword: "", sort: "" });
	const [searchPic, setSearchPic] = useState("");
	const [itemTableAnchor, setItemTableAnchor] = useState(null);
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const title = useMemo(() => (isCreate ? "Add Customer" : "Edit Customer"), [isCreate]);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_MAINTENANCE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const submitLabel = useMemo(() => (isCreate ? "Add" : "Update"), [isCreate]);
	const tableNumber = useMemo(() => (isCreate ? "number" : "paginationNumbers"), [isCreate]);
	const defaultKeyword = useMemo(() => (isCreate ? searchPic : queryParams.current.keyword), [isCreate, searchPic]);
	const [data, setData] = useState(convertPaginationTableData());

	//prettier-ignore
	const customerTypeOptions = useMemo(() => [
		{ label: "Corporate", value: CUSTOMER_TYPE.CORPORATE },
		{ label: "Individual", value: CUSTOMER_TYPE.INDIVIDUAL }
	], []);

	const initialValues = useMemo(() => {
		const values = {
			customerId: "",
			customerRefNo: "",
			type: CUSTOMER_TYPE.CORPORATE,
			customerAlias: "",
			companyNumber: "",
			registeredName: "",
			paymentTerm: "",
			createdDate: "",
			createdByName: "",
			lastModifiedByName: "",
			lastModifiedDate: "",
			picContacts: [],
			remark: ""
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			type: yup.string().required(ERRORS.REQUIRED),
			paymentTerm: yup.string().required(ERRORS.REQUIRED),
			registeredName: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const isCorporate = useMemo(() => formik.values.type === CUSTOMER_TYPE.CORPORATE, [formik.values.type]);

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	const localPicTableData = useMemo(() => {
		const sanitizedSearch = searchPic.trim().toLowerCase();

		const filtered = formik.values.picContacts.filter((o) => {
			const sanitizedPicName = o.picName.trim().toLowerCase();
			const sanitizedEmail = o.email.trim().toLowerCase();

			return sanitizedPicName.includes(sanitizedSearch) || sanitizedEmail.includes(sanitizedSearch);
		});

		return filtered;
	}, [formik.values.picContacts, searchPic]);

	const picTableData = useMemo(() => {
		if (isCreate) {
			return localPicTableData;
		} else {
			return data.content;
		}
	}, [data.content, isCreate, localPicTableData]);

	const breadCrumb = useMemo(() => {
		const data = [{ label: "Customer Listing", path: pathnames.customer.customers }];

		if (isCreate) {
			data.push({ label: "Add Customer", path: pathnames.customer.customerCreateEdit + PAGE.CREATE });
		}

		if (!isCreate) {
			data.push({ label: "Edit Customer " + formik.values.customerRefNo, path: pathnames.customer.customerCreateEdit + id });
		}

		return data;
	}, [isCreate, formik.values.customerRefNo, id]);

	useBreadcrumb({ breadCrumb });

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				customerType: values.type,
				customerAlias: values.customerAlias,
				companyNumber: values.companyNumber,
				registeredName: values.registeredName,
				paymentTerm: values.paymentTerm,
				remark: values.remark,
				picContacts: values.picContacts
			};

			if (isCreate) {
				await api.post.customer.createCustomer(payload);
			}

			if (!isCreate) {
				payload.id = id;
				const { picContacts, ...values } = payload;

				await api.post.customer.updateCustomer(values);
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Customer was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Customer was updated successfully!" }));
			}

			navigate(pathnames.customer.customers);
		}
	}, [dispatch, navigate, id, isCreate]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.customer.customer(uniqueId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				customerId: response.customerId,
				customerRefNo: response.referenceNo,
				type: response.customerType,
				customerAlias: response.customerAlias,
				companyNumber: response.companyNumber,
				registeredName: response.registeredName,
				paymentTerm: response.paymentTerm,
				createdDate: dayjs(response.createdDate).format(DATE_TIME.CREATED_BY_DATE),
				createdByName: response.createdByName,
				lastModifiedDate: dayjs(response.lastModifiedDate).format(DATE_TIME.DD_MM_YYYY_HH_MM_A),
				lastModifiedByName: response.lastModifiedByName,
				picContacts: response.picContacts.map((o, i) => ({ ...o, paginationNumbers: i + 1 })),
				remark: response.remark
			});
		}
	}, [memoSetFormValues]);

	const onHandleGetPicContacts = useCallback(async (uniqueId) => {
		let response = null;

		try {
			const params = sanitizeObject({
				"customer-id": uniqueId,
				queryParams: { ...queryParams.current, size: 10 }
			});

			response = await api.get.customer.customerPic(params);
		} catch (error) {
			promptLayoutAlertMessage(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	const onHandleShowConfirmAccountModal = useCallback((values) => {
		confirmMobileAccountModalRef.current.onHandleShow(values);
	}, []);

	//prettier-ignore
	const onHandleAddUpdatePic = useCallback(async (values) => {
		const isEditPic = values.id;
		let response = null;

		try {
			let payload = {
				customerId: id,
				createAppUser: values.createAppUser || false,
				createAppUserConfirmation: values.createAppUser || false,
				picName: values.picName,
				email: values.email,
				mobileNo: values.mobileNo,
				mobileNoPrefix: values.mobileNoPrefix,
				departmentEmail: values.departmentEmail,
				officeNo: values.officeNo,
				officeNoPrefix: values.officeNoPrefix,
				identificationNumber: values.identificationNumber,
				passport: values.passport,
				nationality: values.nationality
			};

			if (values.identificationType) {
				payload = { ...payload, identificationType: values.identificationType };
			}

			if (values.gender) {
				payload = { ...payload, gender: values.gender };
			}

			if (!isEditPic) {
				response = await api.post.customer.createCustomerPic(payload);
			}

			if (isEditPic) {
				payload = { ...payload, id: values.id, status: values.status };

				response = await api.post.customer.updateCustomerPic(payload);
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (response.requireConfirmation) {
				return onHandleShowConfirmAccountModal(values);
			}

			if (isEditPic) {
				dispatch(promptLayoutAlertMessage({ message: "PIC was edited successfully!" }));
			}

			if (!isEditPic) {
				dispatch(promptLayoutAlertMessage({ message: "PIC was added successfully!" }));
			}

			onHandleGetDetails(id);

			onHandleGetPicContacts(id);
		}
	}, [dispatch, formik, id, onHandleGetDetails, onHandleGetPicContacts, onHandleShowConfirmAccountModal]);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.customer.customers);
	}, [navigate]);

	//prettier-ignore
	const onHandleSearchPic = useCallback((event) => {
		const value = event.target.value;

		if (isCreate) {
			setSearchPic(value);
		} else {
			queryParams.current.keyword = value;

			onHandleGetPicContacts(id);
		}
	}, [id, isCreate, onHandleGetPicContacts]);

	//prettier-ignore
	const onHandleEditContact = useCallback((event, tableMeta) => {
		selectedItem.current = { ...data.content[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		setItemTableAnchor(event.currentTarget);
	}, [data.content]);

	const onHandleCloseMenu = useCallback(() => {
		setItemTableAnchor(false);
	}, []);

	const onHandleAddCustomerItem = useCallback(() => {
		setItemTableAnchor(false);

		customerAddItemModalRef.current.onHandleShow();
	}, []);

	const onHandleEditCustomerItem = useCallback(() => {
		setItemTableAnchor(false);

		customerAddItemModalRef.current.onHandleShow(selectedItem.current);
	}, []);

	const onHandleAddEditCustomerItem = useCallback((values) => {
		customerAddItemModalRef.current.onHandleShow(values);
	}, []);

	const onHandleConfirmRemoveCustomerItem = useCallback(() => {
		setItemTableAnchor(false);

		customerDeleteItemModalRef.current.onHandleShow(selectedItem.current);
	}, []);

	const onHandleRemoveCustomerItem = useCallback(async () => {
		if (isCreate) {
			let nextItems = [...formik.values.picContacts];

			nextItems = nextItems.filter((_, i) => i !== selectedItem.current.rowIndex);

			formik.setFieldValue("picContacts", nextItems);
		} else {
			let response = null;

			if (formik.values.picContacts.length === 1) {
				return dispatch(promptLayoutAlertMessage({ message: "The customer should have at least one PIC", error: true }));
			}

			try {
				const payload = { ...selectedItem.current, customerId: id, status: STATUS.DELETED };

				response = await api.post.customer.updateCustomerPic(payload);
			} catch (error) {
				serveLayoutRequestErrors(error);
			} finally {
				formik.setSubmitting(false);
			}

			if (response) {
				dispatch(promptLayoutAlertMessage({ message: "PIC was removed successfully!" }));

				onHandleGetDetails(id);
			}

			onHandleCloseMenu();
		}
	}, [isCreate, onHandleCloseMenu, id, formik, dispatch, onHandleGetDetails]);

	//prettier-ignore
	const onHandleCreateItem = useCallback((values) => {
		const nextItems = [...formik.values.picContacts, values].map((o, i) => ({ number: i + 1, ...o }));

		formik.setFieldValue("picContacts", nextItems);
	}, [formik]);

	//prettier-ignore
	const onHandleEditItem = useCallback((values) => {
		const nextItems = [...formik.values.picContacts];
		nextItems[values.number - 1] = values;

		formik.setFieldValue("picContacts", nextItems);
	}, [formik]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: tableNumber,
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "picName",
			label: "PIC Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "mobileNo",
			label: "Mobile Number",
			options: {
				sort: false,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					if (value) {
						return formik.values.picContacts[tableMeta.rowIndex]?.mobileNoPrefix + value;
					}

					return "-";
				}
			}
		},
		{
			name: "email",
			label: "Email Address",
			options: {
				sort: false,
				sortThirdClickReset: true
			}
		},
		{
			name: "officeNo",
			label: "Office Number",
			options: {
				sort: false,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					if (value) {
						return formik.values.picContacts[tableMeta.rowIndex]?.officeNoPrefix + value;
					}

					return "-";
				}
			}
		},
		{
			name: "departmentEmail",
			label: "Department Email",
			options: {
				sort: false,
				sortThirdClickReset: true,
				customBodyRender: (value) => {
					if (value) {
						return value;
					}

					return "-";
				}
			}
		},
		{
			name: "identificationNumber",
			label: "ID No.",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					const row = formik.values.picContacts[tableMeta.rowIndex];

					if (row) {
						const identificationType = row.identificationType;
						const nric = identificationType === IDENTITY_TYPE.NRIC;
						const passport = identificationType === IDENTITY_TYPE.PASSPORT;

						if (nric) return row.identificationNumber;
						else if (passport) return row.passport;
						else return "-";
					}

					return "-";
				}
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "action",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					if (formik.values.picContacts[tableMeta.rowIndex]?.status === STATUS.INACTIVE) return;

					return (
						<button type="button" className="table__action" onClick={(event) => onHandleEditContact(event, tableMeta)}>
							<img src={verticalBreadcrumbsIcon} alt="vertical-breadcrumbs-icon" />
						</button>
					);
				}
			}
		}
	], [formik.values, onHandleEditContact, tableNumber]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetPicContacts(id);
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetPicContacts(id);
					break;
				default:
					break;
			}
		}
	}), [id, data, onHandleGetPicContacts]);

	useEffect(() => {
		if (!isCreate) {
			onHandleGetDetails(id);

			onHandleGetPicContacts(id);
		}

		return () => {
			if (id) {
				memoCancelRequest(ENDPOINT_PATH.CUSTOMER.CUSTOMER);

				memoCancelRequest(ENDPOINT_PATH.CUSTOMER.CUSTOMER_PIC);
			}
		};
	}, [isCreate, id, memoCancelRequest, onHandleGetDetails, onHandleGetPicContacts]);

	const emptyState = useMemo(() => {
		if (formik.values.picContacts.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={tableColumns.length} align="center">
						<p className="table__text">
							No Items Added.{" "}
							<span className="table__link" onClick={onHandleAddCustomerItem}>
								Add Items?
							</span>
						</p>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [formik.values.picContacts.length, onHandleAddCustomerItem, tableColumns.length]);

	return (
		<div className="page-customer-create-edit">
			<div className="customer-create-edit">
				<div className="customer-create-edit__header">
					<h1 className="customer-create-edit__title">{title}</h1>

					{!isCreate && (
						<p className="customer-create-edit__last-update">
							<span>Last Updated By</span> {formik.values.lastModifiedByName}, {formik.values.lastModifiedDate}
						</p>
					)}
				</div>

				<form className="customer-create-edit__form" onSubmit={formik.handleSubmit}>
					<div className="customer-create-edit__container">
						<p className="customer-create-edit__label">General Details</p>

						<div className="customer-create-edit__row customer-create-edit__row--divider">
							{!isCreate && <AppInput disabled type="text" name="customerRefNo" label="Customer ID" placeholder="Customer ID" value={formik.values.customerRefNo} error={formik.errors.customerRefNo} touched={formik.touched.customerRefNo} onChange={formik.handleChange} />}

							<AppRadioInput required disabled={restricted} label="Customer Type" options={customerTypeOptions} value={formik.values.type} error={formik.errors.type} touched={formik.touched.type} onChange={(v) => formik.setFieldValue("type", v)} />

							<AppInput required disabled={restricted} type="text" name="registeredName" label="Registered Name" placeholder="Registered Name" value={formik.values.registeredName} error={formik.errors.registeredName} touched={formik.touched.registeredName} onChange={formik.handleChange} />

							{isCorporate && <AppInput disabled={restricted} type="text" name="companyNumber" label="Company Number" placeholder="Company Number" value={formik.values.companyNumber} error={formik.errors.companyNumber} touched={formik.touched.companyNumber} onChange={formik.handleChange} />}

							<AppInput disabled={restricted} type="text" name="customerAlias" label="Customer Name" placeholder="Customer Name" value={formik.values.customerAlias} error={formik.errors.customerAlias} touched={formik.touched.customerAlias} onChange={formik.handleChange} />

							<AppInput required disabled={restricted} type="text" name="paymentTerm" label="Approved Payment Term" placeholder="Enter Approved Payment Term" value={formik.values.paymentTerm} error={formik.errors.paymentTerm} touched={formik.touched.paymentTerm} onChange={formik.handleChange} />

							{!isCreate && <AppInput disabled type="text" name="created" label="Created" placeholder="Created" value={`${formik.values.createdDate} by ${formik.values.createdByName}`} onChange={formik.handleChange} />}
						</div>

						<p className="customer-create-edit__label">Contacts</p>

						<div className="customer-create-edit__table">
							<AppTableFilterHeader searchPlaceholder="Search by PIC Name or Email Address" searchDefaultValue={defaultKeyword} restricted={restricted} onHandleSearch={onHandleSearchPic} onHandleAdd={onHandleAddCustomerItem} />

							<AppTable data={picTableData} columns={tableColumns} options={tableOptions} components={emptyState} />
						</div>
					</div>

					<div className="customer-create-edit__container">
						<p className="customer-create-edit__label">Remarks</p>

						<div className="customer-create-edit__row">
							<AppInput multiline disabled={restricted} type="textarea" maxLength={255} name="remark" placeholder="Enter Remarks" value={formik.values.remark} error={formik.errors.remark} touched={formik.touched.remark} onChange={formik.handleChange} />
						</div>
					</div>

					{!isCreate && (
						<div className="customer-create-edit__container">
							<p className="customer-create-edit__label">Contracts</p>

							<div className="customer-create-edit__table">
								<AppCustomerCreateEditContractTable customerRefNo={formik.values.customerRefNo} picContacts={formik.values.picContacts} />
							</div>
						</div>
					)}

					<div className="customer-create-edit__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

						<AppButton disabled={restricted} type="submit" label={submitLabel} />
					</div>
				</form>
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "customer-create-edit-table-menu" }} anchorEl={itemTableAnchor} open={!!itemTableAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleEditCustomerItem}><img src={editIcon} alt="customer-edit" />Edit</MenuItem>

				<MenuItem onClick={onHandleConfirmRemoveCustomerItem}><img src={trashIcon} alt="customer-transfer" />Remove</MenuItem>
			</Menu>

			<AppCustomerAddPicModal ref={customerAddItemModalRef} isCreateCustomer={isCreate} isCorporate={isCorporate} onConfirm={onHandleCreateItem} onHandleEditItem={onHandleEditItem} onHandleGetDetails={onHandleGetDetails} onHandleAddUpdatePic={onHandleAddUpdatePic} onHandleShowConfirmAccountModal={onHandleShowConfirmAccountModal} />

			<AppCustomerDeletePicModal ref={customerDeleteItemModalRef} onConfirm={onHandleRemoveCustomerItem} />

			<AppCustomerConfirmMobileAccountModal ref={confirmMobileAccountModalRef} onConfirm={onHandleAddUpdatePic} onHandleAddEditCustomerItem={onHandleAddEditCustomerItem} />
		</div>
	);
};

export default PageCustomerListCreateEdit;
